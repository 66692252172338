<template>
  <div class="home_view c">
    <!-- banner -->
    <div class="banner">
      <div class="swiper">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div
            class="swiper-slide"
            v-for="(item, index) in bannerList"
            :key="index"
          >
            <img :src="item.url" alt="" />
          </div>
        </div>
        <!-- If we need pagination -->
        <!-- <div class="swiper-pagination"></div> -->

        <!-- If we need navigation buttons -->
        <div class="swiper-button-prev" v-show="swiperIsHover">
          <img src="@/assets/imgs/left.png" alt="" />
        </div>
        <div class="swiper-button-next" v-show="swiperIsHover">
          <img src="@/assets/imgs/right.png" alt="" />
        </div>

        <!-- If we need scrollbar -->
        <div class="swiper-pagination" v-show="swiperIsHover"></div>
      </div>
    </div>
    <!-- 学研云课堂 -->
    <div class="expert_comments">
      <div class="title_block">
        <span class="blue_line"></span><span class="title_text">学研云课堂</span
        ><span class="blue_line"></span>
      </div>
      <div class="content">
        <div class="content_item" v-for="item in lectureList" :key="item.id">
          <div class="top" @click="showVideo(item)">
            <img :src="item.cover" alt="" />
            <div class="play">
              <img src="@/assets/imgs/play.png" alt="" />
            </div>
          </div>
          <div class="bottom">
            <div class="info">
              <div class="face">
                <xl-img :src="item.dep.face" alt="" />
              </div>
              <div class="text">
                <div class="name">{{ item.dep.name }}</div>
                <div class="theme">主题：{{ item.name }}</div>
              </div>
            </div>
            <div class="dep">
              <p v-for="(i, ix) in item.dep.depList" :key="ix">
                <span class="icon"></span>
                <span class="text">{{ i.text }}</span>
              </p>
            </div>
          </div>
          <!-- <div class="video">
            <video
              :src="item.url"
              controls="controls"
              controlsList="nodownload"
              :poster="item.cover"
              :ref="`video${index}`"
              @play="videoPlay($event, `video${index}`)"
            ></video>
          </div> -->
          <!-- <div class="content_text">
            <div class="lecture_name single_omission">
              {{ item.name }}
            </div>
            <el-popover
              placement="top-start"
              trigger="hover"
              width="300"
              :content="item.dep"
            >
              <div slot="reference" class="lecture_introduce multi_omission">
                {{ item.dep }}
              </div>
            </el-popover>
          </div> -->
        </div>
      </div>
      <div class="blue_text">
        <span
          style="cursor: pointer"
          v-if="!viewMore && resLectureList.length > 4"
          @click="viewMore = true"
          >点击查看更多</span
        >
      </div>
    </div>
    <!-- 热门测试 -->
    <div class="test_audition">
      <div class="title_block">
        <span class="blue_line"></span><span class="title_text">热门测试</span
        ><span class="blue_line"></span>
      </div>
      <div class="audition_content">
        <div
          class="audition_item"
          v-for="(item, index) in auditionList"
          :key="index"
          @click="toView(`/details/${item.id}`)"
        >
          <!-- <div class="tag_box">
            <div class="tag_item blue_tag">每周特价</div>
          </div> -->
          <div class="text">
            已预约<span class="orange_text">{{ item.buyCount }}</span
            >次<span class="line"></span>满意度<span class="orange_text"
              >{{ item.grade }}%</span
            >
          </div>
          <div class="img">
            <!-- <xl-img :src="" alt=""> -->
            <xl-img
              v-if="item.cartoonImg && item.cartoonImg != '[]'"
              :src="item.cartoonImg"
            ></xl-img>
            <xl-img v-else :src="item.original"></xl-img>
          </div>
          <div class="content">
            <div class="goods_name single_omission">{{ item.name }}</div>
            <div class="introductory single_omission">
              {{ item.introductory ? item.introductory : "暂无简介" }}
            </div>
            <div
              class="submit_btn"
              @click="toView(`/placeOrderView/${item.id}`)"
            >
              立即预约
            </div>
          </div>
        </div>
        <!-- <div
          class="audition_item last_item"
          @click="$router.push('/testClass/allTestClass')"
        >
          更多测试
        </div> -->
      </div>
    </div>
    <!-- 活动专区 -->
    <div class="activity_area" v-if="false">
      <div class="title_block">
        <span class="blue_line"></span><span class="title_text">活动专区</span
        ><span class="blue_line"></span>
      </div>
      <div class="icon_box">
        <div class="icon_item">
          <div class="img">
            <img src="@/assets/imgs/homeIcon/1.png" alt="" />
          </div>
          <div class="text red_text">每周特价</div>
        </div>
        <div class="icon_item">
          <div class="img">
            <img src="@/assets/imgs/homeIcon/2.png" alt="" />
          </div>
          <div class="text purple_text">活动套餐</div>
        </div>
        <div class="icon_item">
          <div class="img">
            <img src="@/assets/imgs/homeIcon/3.png" alt="" />
          </div>
          <div class="text orange_text">拼团秒杀</div>
        </div>
      </div>
      <div class="activity_content">
        <!-- 每周特价板块 -->
        <div class="activity_item activity_item_height">
          <div class="img"></div>
          <div class="content">
            <div class="goods_name">同步辐射X射线吸收谱（XAFS）</div>
            <div class="goods_introduce">
              XANES可以得到吸收原子的电子结构，包括价态 xxxxxxxxxxxx...
            </div>
            <div class="price_tag">特卖价</div>
            <div class="original_price">原价：888</div>
            <div class="activity_price">￥800.00</div>
            <div class="frequency">
              已抢购<span class="orange_text">800</span>次
            </div>
            <div class="submit_btn">立即预约</div>
          </div>
        </div>
        <!-- 活动套餐板块 -->
        <div class="activity_item activity_item_width">
          <div class="img"></div>
          <div class="content">
            <div class="goods_name">xxxx经典套餐</div>
            <div class="goods_introduce">
              套餐内容：同步辐射标准测试+科学计算1次+光谱测试1次
            </div>
            <div class="price_tag">组合价</div>
            <div class="original_price">原价：2300.00</div>
            <div class="activity_price">￥1600.00</div>
            <div class="frequency">
              已售<span class="orange_text">500</span>件
            </div>
            <div class="submit_btn">立即预约</div>
          </div>
        </div>
        <div class="activity_item activity_item_width">
          <div class="img"></div>
          <div class="content">
            <div class="goods_name">xxxx经典套餐</div>
            <div class="goods_introduce">
              套餐内容：同步辐射标准测试+科学计算1次+光谱测试1次
            </div>
            <div class="price_tag">组合价</div>
            <div class="original_price">原价：2300.00</div>
            <div class="activity_price">￥1600.00</div>
            <div class="frequency">
              已售<span class="orange_text">500</span>件
            </div>
            <div class="submit_btn">立即预约</div>
          </div>
        </div>
        <!-- 更多套餐按钮 -->
        <div class="more_btn" @click="$router.push('/morePackages')">
          更多套餐
        </div>
        <!-- 拼团秒杀板块 -->
        <div class="activity_item activity_item_height">
          <div class="img">
            <div class="count_down">
              <div class="left">
                <div class="text">拼团预计到手价：</div>
                <span>￥</span>
                <span class="price">1600.00</span>
              </div>
              <div class="right">
                <div class="text">距离拼团秒杀结束：</div>
                <div class="time_box">
                  <div class="item">{{ timeData.h }}</div>
                  <span>:</span>
                  <div class="item">{{ timeData.m }}</div>
                  <span>:</span>
                  <div class="item">{{ timeData.s }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="content">
            <div class="goods_name">同步辐射X射线吸收谱（XAFS）</div>
            <div class="goods_introduce">
              XANES可以得到吸收原子的电子结构，包括价态 xxxxxxxxxxxx...
            </div>
            <div class="price_tag">限时拼团价</div>
            <div class="original_price">原价：888</div>
            <div class="activity_price">￥800.00</div>
            <div class="frequency">
              已拼团<span class="orange_text">5</span>次
            </div>
            <div class="submit_btn">立即拼团</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 纳米材料 -->
    <div class="nanometer_material" v-if="false">
      <div class="title_block">
        <span class="blue_line"></span><span class="title_text">纳米材料</span
        ><span class="blue_line"></span>
      </div>

      <div class="content">
        <div
          class="content_item"
          v-for="item in nmMaterialList"
          :key="item.id"
          @click="toView(`/nmMaterial`)"
        >
          <div class="mask"></div>
          <!-- <div class="tag_box">
            <div class="tag_item red_tag">已售出500件</div>
          </div> -->
          <div class="img"><img :src="item.imgUrl" alt="" /></div>
          <div class="material_name">
            {{ item.name }}
          </div>
          <div class="submit_btn" @click="toView(`/nmMaterial`)">立即购买</div>
        </div>
      </div>
    </div>
    <!-- 计算模拟 -->
    <div class="cpt_simulation" v-if="false">
      <div class="title_block">
        <span class="blue_line"></span><span class="title_text">计算模拟</span
        ><span class="blue_line"></span>
      </div>
      <div class="content">
        <div
          class="content_item"
          v-for="item in cptSimulationList"
          :key="item.id"
          @click="$router.push('/cptSimulation')"
        >
          <div class="img"><img :src="item.imgUrl" alt="" /></div>
          <div class="content">
            <div class="name single_omission">{{ item.name }}</div>
            <div class="tips">{{ item.tips }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下单流程 -->
    <div class="ordering_process" v-if="false">
      <div class="title_block">
        <span class="blue_line"></span><span class="title_text">下单流程</span
        ><span class="blue_line"></span>
      </div>
      <div class="content_box">
        <div class="item">
          <div class="blue_line top"></div>
          <div class="iconfont icon-zu171"></div>
          <div class="text">在线下单</div>
        </div>
        <div class="item">
          <div class="blue_line bottom"></div>
          <div class="iconfont icon-zu165"></div>
          <div class="text">自主寄样</div>
        </div>
        <div class="item">
          <div class="blue_line top"></div>
          <div class="iconfont icon-a-Group6251"></div>
          <div class="text">结果交付</div>
        </div>
        <div class="item">
          <div class="blue_line bottom"></div>
          <div class="iconfont icon-shouhouwuyou"></div>
          <div class="text">售后无忧</div>
        </div>
      </div>
    </div>
    <!-- 引导开通会员 -->
    <div class="guide">
      <div class="title_text">首单0元起 先测后付费</div>
      <p>加入会员 更多优惠</p>

      <!-- 是会员  马上测试 -->
      <div
        class="submit_btn"
        v-if="userInfo.role && userInfo.role.includes('VIP')"
        @click="$router.push('/category')"
      >
        马上测试
      </div>
      <!-- 登录了 但并不是会员 -->
      <div
        class="submit_btn"
        v-else-if="checkLogin()"
        @click="$router.push('/applyVip')"
      >
        立即开通会员
      </div>
      <!-- 未登录 -->
      <div class="submit_btn" v-else @click="$router.push('/Login')">
        开始注册
      </div>
    </div>
    <video-dialog
      :visible="videoDialogVisible"
      :activeVideo="activeVideo"
      @closeDialog="closeDialog"
    ></video-dialog>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css"; // 所有 Swiper 样式，包括所有模块样式（如导航、分页等）
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from "swiper"; // 导入您需要的模块
import { getBanner, getTestSelect, getVideoList } from "@/api/home";
import { checkLogin } from "@/tool";
import { mapState } from "vuex";
import XlImg from "@/components/xyhComps/Xl-img.vue";
import VideoDialog from "@/components/dialogComponents/videoDialog.vue";
export default {
  components: { XlImg, VideoDialog },
  data() {
    return {
      viewMore: false,
      date: 16677600000,
      swiperIsHover: false, //是否显示轮播图的左右按钮和小圆点
      activeVideo: {},
      timeData: {
        h: "",
        m: "",
        s: "",
      },
      videoDialogVisible: false,
      // 特色商品数组
      featuredList: [
        { name: "测试商品" },
        { name: "测试商品" },
        { name: "测试商品" },
        { name: "测试商品" },
        { name: "测试商品" },
      ],
      //   为你严选数组
      activityList: [
        { name: "测试商品" },
        { name: "测试商品" },
        { name: "测试商品" },
        { name: "测试商品" },
      ],
      // 热门测试数组
      auditionList: [],
      // 纳米材料数组
      // nmMaterialList: [
      //   { title: "Ag nanocubes 银纳米立方体", id: 1 },
      //   { title: "Mesoporous silica 介孔二氧化硅", id: 2 },
      //   { title: "Ag nanocubes 银纳米立方体", id: 3 },
      //   { title: "Mesoporous silica 介孔二氧化硅", id: 4 },
      //   { title: "Mesoporous silica 介孔二氧化硅", id: 5 },
      //   { title: "Mesoporous silica 介孔二氧化硅", id: 6 },
      //   { title: "Mesoporous silica 介孔二氧化硅", id: 7 },
      //   { title: "Mesoporous silica 介孔二氧化硅", id: 8 },
      // ],
      // nmMaterialList: [
      //   {
      //     name: "银纳米材料",
      //     imgUrl: require("@/assets/imgs/nmImgs/1/11.png"),
      //   },
      //   {
      //     name: "金纳米材料",
      //     imgUrl: require("@/assets/imgs/nmImgs/2/11.png"),
      //   },
      //   {
      //     name: "磁性纳米材料",
      //     imgUrl: require("@/assets/imgs/nmImgs/3/11.png"),
      //   },
      //   {
      //     name: "硅纳米材料",
      //     imgUrl: require("@/assets/imgs/nmImgs/4/11.png"),
      //   },
      //   {
      //     name: "钙钛矿量子点",
      //     imgUrl: require("@/assets/imgs/nmImgs/5/11.png"),
      //   },
      //   {
      //     name: "核壳结构材料",
      //     imgUrl: require("@/assets/imgs/nmImgs/6/11.png"),
      //   },
      //   {
      //     name: "生物膜材料",
      //     imgUrl: require("@/assets/imgs/nmImgs/7/11.png"),
      //   },
      //   {
      //     name: "SERS纳米试剂",
      //     imgUrl: require("@/assets/imgs/nmImgs/8/11.png"),
      //   },
      // ],
      // 计算模拟数组
      // cptSimulationList: [
      //   {
      //     name: "催化领域计算模拟服务",
      //     tips: "刚翻过了一座山，又来了一座山~",
      //     imgUrl: require("@/assets/imgs/cptSimulationImg/list1.png"),
      //   },
      //   {
      //     name: "能源领域计算模拟服务",
      //     tips: "理电池、锌电池、钢电池、钙钵矿电..",
      //     imgUrl: require("@/assets/imgs/cptSimulationImg/list2.png"),
      //   },
      //   {
      //     name: "二维材料领域计算模拟服务",
      //     tips: "半导体，怎么也得懂一点~",
      //     imgUrl: require("@/assets/imgs/cptSimulationImg/list3.png"),
      //   },
      //   {
      //     name: "多孔材料领域计算模拟服务",
      //     tips: "无孔不入~",
      //     imgUrl: require("@/assets/imgs/cptSimulationImg/list4.png"),
      //   },
      //   {
      //     name: "第一性原理计算服务",
      //     tips: "有光，就有世界~~",
      //     imgUrl: require("@/assets/imgs/cptSimulationImg/list5.png"),
      //   },

      //   {
      //     name: "分子动力学模拟服务",
      //     tips: "有电，就有wifi~~",
      //     imgUrl: require("@/assets/imgs/cptSimulationImg/list6.png"),
      //   },
      //   {
      //     name: "量子化学计算服务",
      //     tips: "我是大力士，不信你试试~~",
      //     imgUrl: require("@/assets/imgs/cptSimulationImg/list7.png"),
      //   },
      //   {
      //     name: "有限元模拟服务",
      //     tips: "天气这么热，我哪里通通不想去~",
      //     imgUrl: require("@/assets/imgs/cptSimulationImg/list8.png"),
      //   },
      // ],
      // 院士讲座数组
      // lectureList: [],
      resLectureList: [],
      bannerList: [],
      checkLogin,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // 启动拼团倒计时
    this.Time();
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    // 名家讲坛
    lectureList() {
      let arr = [];
      if (this.viewMore) {
        arr = this.resLectureList;
      } else {
        arr = this.resLectureList.slice(0, 4);
      }
      return arr;
    },
  },
  methods: {
    // 初始化获取数据
    init() {
      this.getBanner();
      this.getTestSelect();
      this.getVideoList();
    },
    jump({ realIndex }) {
      let item = this.bannerList[realIndex];
      if (item && item.redirectUrl) {
        location.href = item.redirectUrl;
      }
    },
    countDown() {
      // let d = parseInt(date / (24 * 60 * 60));
      // d = d < 10 ? "0" + d : d;
      let h = parseInt(this.date / (60 * 60));
      h = h < 10 ? "0" + h : h;
      let m = parseInt((this.date / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(this.date % 60);
      s = s < 10 ? "0" + s : s;
      this.timeData = { h, m, s };
    },
    Time() {
      setInterval(() => {
        this.date -= 1;
        this.countDown();
      }, 1000);
    },
    closeDialog() {
      this.videoDialogVisible = false;
    },
    getVideoList() {
      getVideoList({ type: "PRO_FORUM" }).then((res) => {
        this.resLectureList = res.map((i) => {
          i.dep = JSON.parse(i.dep);
          return i;
        });
      });
    },
    // 一个视频播放时  暂停其他视频
    // videoPlay(e, ref) {
    //   this.lectureList.forEach((i, ix) => {
    //     let str = "video" + ix;
    //     if (str != ref) {
    //       let dom = this.$refs[`video${ix}`][0];
    //       dom.pause();
    //       // dom.currentTime = 0;
    //       dom.load();
    //     }
    //   });
    // },
    // 打开视频播放弹窗
    showVideo(item) {
      this.activeVideo = item;
      this.videoDialogVisible = true;
    },
    // 跳转页面
    toView(path) {
      this.$router.push(path);
    },
    // 获取banner
    async getBanner() {
      let data = await getBanner();
      this.bannerList = data;
      this.$nextTick(() => {
        let that = this;
        // 轮播图
        let swiper = new Swiper(".swiper", {
          speed: 400,
          spaceBetween: 100,
          observer: true,
          observeParents: true,
          loop: true,
          autoplay: {
            delay: 3000,
          },
          // loop: true,
          modules: [Navigation, Pagination, Scrollbar, Autoplay],
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          on: {
            click: this.jump,
          },
        });
        //鼠标覆盖停止自动切换
        swiper.el.onmouseover = function () {
          swiper.autoplay.stop();
          that.swiperIsHover = true;
        };

        //鼠标离开开始自动切换
        swiper.el.onmouseout = function () {
          swiper.autoplay.start();
          that.swiperIsHover = false;
        };
      });
    },
    // 获取热门测试
    async getTestSelect() {
      let data = await getTestSelect();
      this.auditionList = data;
      this.auditionList.forEach((i) => {
        i.original = JSON.parse(i.original);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home_view {
  background-color: #f8f8f8;
  .banner {
    width: 100%;
    height: 300px;

    .swiper {
      height: 100%;
      .swiper-slide {
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }
  .tag_box {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    .tag_item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 11px;
      height: 24px;
      font-size: 12px;
      border-radius: 35px;
    }
    .blue_tag {
      background: rgba(5, 109, 232, 0.08);
      color: @main-color;
    }
    .red_tag {
      color: #f23d4f;
      background-color: #ffe3db;
    }
  }
  .featured {
    width: 100%;
    .featured_content {
      width: 100%;

      display: flex;
      .featured_item {
        width: 280px;
        height: 440px;
        background-color: #e4e4e4;
        margin-right: 30px;
        border-radius: 4px;
        .item_top {
          width: 280px;
          height: 280px;

          background-color: #a3a3a3;
        }
        .item_bottom {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .trade_name {
            margin: 10% 0 10% 0;
          }
          .order_btn {
            width: 160px;
            color: #fff;
            font-size: 16px;
            height: 44px;
            background: #979797;
            border-radius: 22px 22px 22px 22px;
            text-align: center;
            line-height: 44px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .activity_area {
    width: 100%;
    color: #fff;
    .icon_box {
      display: flex;
      padding: 0 51px;
      margin-bottom: 12px;
      .icon_item {
        display: flex;
        align-items: center;
        .img {
          margin-right: 12px;
        }
        .text {
          width: 184px;
          height: 56px;
          border-radius: 40px;
          font-size: 32px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .red_text {
          background-color: #ffe3db;
          color: #f23d4f;
          margin-right: 264px;
        }
        .purple_text {
          background-color: #e9ecff;
          color: #6c6cea;
          margin-right: 305px;
        }
        .orange_text {
          color: #ff8e12;
          background-color: #fff1dc;
        }
      }
    }
    .activity_content {
      display: flex;
      justify-content: space-between;
      position: relative;
      .activity_item {
        background: rgba(5, 109, 232, 0.08);
        border-radius: 4px;
        text-align: left;
        padding: 20px;
        position: relative;
        .content {
          .goods_name {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 6px;
            color: #2f2f2f;
            width: 100%;
            padding: 0 10px;
          }
          .goods_introduce {
            font-size: 16px;
            color: #696969;
            margin-bottom: 8px;
          }
          .price_tag {
            display: inline-block;
            height: 24px;
            padding: 0 10px;
            background: #ff0000;
            border-radius: 58px 58px 58px 58px;
            margin-bottom: 10px;
            text-align: center;
            line-height: 24px;
          }
          .original_price {
            color: #acacac;
            font-size: 16px;
            margin-bottom: 10px;
            text-decoration: line-through;
          }
          .activity_price {
            color: #c40c24;
            font-weight: 700;
            font-size: 24px;
          }
          .submit_btn {
            width: 160px;
            height: 44px;
            position: absolute;
            bottom: 30px;
            right: 20px;
          }
          .frequency {
            position: absolute;
            right: 20px;
            bottom: 84px;
            color: #acacac;
            font-size: 14px;
            .orange_text {
              color: #ff8e12;
            }
          }
        }
      }
      .activity_item_height {
        width: 410px;
        height: 630px;
        .img {
          width: 370px;
          height: 370px;
          margin-bottom: 20px;
          background-color: #fff;
          position: relative;
          img {
            // width: 100%;
            // height: 100%;
            max-width: 100%;
          }
          .count_down {
            width: 100%;
            height: 68px;
            background-image: url(@/assets/imgs/homeIcon/bgc.png);
            background-size: cover;
            position: absolute;
            bottom: 0;
            display: flex;
            font-size: 16px;
            .left {
              padding: 8px 0 0 17px;
              margin-right: 12px;
              .price {
                font-size: 30px;
              }
            }
            .right {
              color: #ff5732;
              display: flex;
              align-items: center;
              height: 32px;
              position: absolute;
              bottom: 0;
              right: 2px;
              .text {
                font-size: 12px;
                font-weight: 700;
              }
              span {
                margin: 0 7px;
              }
              .time_box {
                display: flex;
                align-items: center;

                .item {
                  width: 20px;
                  height: 20px;
                  background-color: #ff5732;
                  border-radius: 3px;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .more_btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 120px;
        height: 30px;
        background: rgba(5, 109, 232, 0.08);
        color: @main-color;
        text-align: center;
        line-height: 30px;
        border-radius: 40px;
        font-size: 20px;
        cursor: pointer;
      }
      .activity_item_width {
        width: 640px;
        height: 275px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        .img {
          width: 235px;
          height: 235px;
          background-color: #fff;
          margin-right: 20px;
        }
        .content {
          .goods_introduce {
            margin-bottom: 56px;
          }
        }
      }
      .activity_item_width:nth-child(1) {
        top: 0;
      }
      .activity_item_width:nth-child(2) {
        bottom: 50px;
      }
    }
  }
  .test_audition {
    .audition_content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .audition_item {
      width: 23.5%;
      height: 276px;
      position: relative;
      // margin: 0 25px 25px 0;
      margin-bottom: 25px;
      border: 1px solid #d4d4d4;
      position: relative;
      border-radius: 4px;
      background-color: #fff;
      padding: 12px;
      cursor: pointer;
      .img {
        width: 100%;
        height: 180px;
        text-align: center;
        img {
          max-width: 180px;
        }
      }
      .text {
        font-size: 13px;
        color: #acacac;
        display: flex;
        align-items: center;
        justify-content: start;
        .line {
          display: inline-block;
          height: 16px;
          width: 1px;
          background-color: #acacac;
          margin: 0 12px;
        }
        .orange_text {
          color: #ffa745;
        }
      }
      .content {
        width: 100%;
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        .goods_name {
          font-size: 20px;
          font-weight: bold;
          color: #2f2f2f;
          margin-bottom: 2px;
          width: 100%;
          padding: 0 10px;
        }
        .introductory {
          color: @main-color;
          margin-top: 2px;
          padding: 0 12px;
          width: 100%;
        }
        .submit_btn {
          width: 126px;
          height: 35px;
          line-height: 35px;
          display: none;
          margin-left: 0;
          font-size: 20px;
          margin-top: 4px;
        }
      }
    }
    .audition_item:hover {
      border: 1px solid @main-color;

      box-shadow: 0px 0px 14px 7px rgba(5, 109, 232, 0.15);

      .content {
        bottom: 10px;
        .goods_name {
          color: @main-color;
        }
        .introductory {
          display: none;
        }
        .submit_btn {
          display: block;
        }
        .text {
          display: none;
        }
      }
    }
    .audition_item:nth-child(4n) {
      margin-right: 0;
    }
    .last_item {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: @main-color;
      font-size: 32px;
      font-weight: 700;
      color: @main-color;
      background: rgba(5, 109, 232, 0.08);
    }
  }
  .nanometer_material,
  .cpt_simulation,
  .ordering_process {
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .content_item {
        width: 23.5%;
        height: 270px;
        font-size: 16px;
        color: #fff;
        margin: 0 0 24px 0;
        position: relative;
        .mask {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.3);
        }
        .img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            // height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }
        .content {
          position: absolute;
          bottom: 20px;
          width: 100%;
          text-align: center;
          .name {
            font-size: 20px;
            font-weight: 700;
            color: #2f2f2f;
            margin-bottom: 12px;
          }
          .tips {
            font-size: 12px;
            color: #acacac;
            padding: 0 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        .material_name {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: 700;
          font-size: 20px;
        }
        .submit_btn {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 52px;
          margin-left: 0;
          width: 166px;
          height: 44px;
          line-height: 44px;
          display: none;
        }
        .test_name {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 40px;
          width: 100%;
        }
      }
      .content_item:hover {
        .submit_btn {
          display: block;
        }
        .text {
          display: none;
        }
        .test_name {
          color: @main-color;
        }
      }
      .content_item:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
  .nanometer_material {
  }
  .cpt_simulation {
    .content {
      .content_item {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img {
          width: 100%;
          height: 184px;
          img {
            max-width: 100%;
          }
        }
        .name {
          width: 100%;
          text-align: center;
        }
        .submit_btn {
          bottom: 0px;
        }
      }
    }
  }
  .ordering_process {
    .content_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 60px;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        color: @main-color;
        background-color: #fff;
        width: 276px;
        height: 276px;
        position: relative;
        flex-direction: column;
        font-size: 24px;

        .iconfont {
          margin-bottom: 4px;
          font-size: 40px;
        }
        .blue_line {
          background-color: @main-color;
          width: 100%;
          height: 20px;
          position: absolute;
        }
        .top {
          top: 0;
        }
        .bottom {
          bottom: 0;
        }
      }
    }
  }
  .guide {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    .title_text {
      font-size: 32px;
      color: @main-color;
      margin-bottom: 12px;
      font-weight: 700;
    }
    p {
      color: rgba(5, 109, 232, 0.7);
      font-size: 20px;
      margin-bottom: 30px;
    }
    .submit_btn {
      width: 168px;
      height: 50px;
      font-size: 20px;
    }
  }
  .expert_comments {
    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .content_item {
        width: 23.5%;
        height: 390px;
        background-color: #fff;
        border-radius: 4px 4px 0 0;
        margin-bottom: 24px;
        overflow: hidden;
        box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.25);
        .top {
          width: 100%;
          height: 154px;
          position: relative;
          cursor: pointer;
          img {
            width: 282px;
            height: 157px;
            object-fit: fill;
          }
          .play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
              width: 45px;
              height: 45px;
            }
          }
        }
        .bottom {
          width: 100%;
          height: 236px;
          background-image: url(@/assets/imgs/bg1.png);
          background-size: cover;
          padding: 20px;
          padding-right: 4px;
          overflow: hidden;
          .info {
            display: flex;
            align-items: center;
            height: 85px;
            .face {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              overflow: hidden;
              flex-shrink: 0;
              margin-right: 12px;
              .xl-img {
                max-width: 100%;
              }
            }
            .text {
              text-align: left;
              color: @main-color;
              .name {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 4px;
              }
              .theme {
                font-size: 14px;
              }
            }
          }
          .dep {
            color: #474747;

            p {
              font-size: 12px;
              text-align: left;
              margin-bottom: 6px;
              position: relative;
              padding-left: 14px;
              padding-right: 14px;
              .icon {
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #474747;
                left: 0;
                top: 4px;
              }
              .text {
                line-height: 14px;
                color: #474747;
              }
            }
          }
        }
      }
    }
  }
}
/deep/.swiper-pagination-bullet {
  background: #a6a6a6;
  opacity: 1;
}
/deep/.swiper-pagination-bullet-active {
  background-color: #fff;
}

.swiper-button-prev {
  left: 40px;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "";
}
.swiper-button-next {
  right: 40px;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "";
}
.swiper-pagination {
}
</style>