
import router from "@/router"
import store from "@/store"

// 根据不同的消息 跳转不同的页面
let messageRouter =
    [
        // 测试订单
        {
            type: "TEST_ORDER",
            path: "/testOrderDetail",
            params: function (params) {
                return { orderSn: params.sn }
            }
        },
        // 测试售后单
        {
            type: "TEST_SERVICE_ORDER",
            path: "/myCenter/afterSaleList",
            params: function () {
                return {}
            }
        },
        // 预存申请单
        {
            type: "MEMBER_PREPAID_APPLY_BILL",
            path: "/preStoExamine",
            params: function (params) {
                return { sn: params.sn }
            }
        },
        // 成员管理
        {
            type: "TEAM_MEMBER_MANAGE",
            path: "/myCenter/teamMemberAdmin?tab=member_apply",
            params: function (params) {
                return { uid: params.uid }
            }
        },
        // 团队
        {
            type: "TEAM",
            path: "/myCenter/myTeam",
            params: function () {
                return {}
            }
        },
        // 团队订单管理
        {
            type: "TEAM_ORDER_MANAGER",
            path: "/myCenter/teamOrderAdmin?tab=new_order",
            params: function (params) {
                return { uid: params.uid }
            }
        },
        // 不跳转
        {
            type: "NONE",
        },
    ]
export function jump(type, params) {
    let checkList = ['/teamInvoiceAdmin', '/teamMemberAdmin', '/teamOrderAdmin']

    for (let i = 0; i < messageRouter.length; i++) {
        let signRoute = messageRouter[i]
        if (signRoute.type === type) {
            // 是否需要校验角色
            let find = checkList.find(i => location.hash.includes(i))
            if (find) {
                let { myInTeamInfo: { role } } = store.state.user.teamInfo
                // 需校验在团队的角色是否为组长和管理员
                if (role == 'MEMBER') {
                    // 是普通成员  无事发生
                    return
                }
            }
            router.push({ path: signRoute.path, query: signRoute.params(params) })
            return
        }
    }
}


