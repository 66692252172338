/**
 * 获取查询参数 searchParam=[column:'pluginId',value:1,query:Condition.EQ,sort,asc}]
 */
export const getQueryParam = (form,SearchParamList,SortParamList) => {
    if (!form){
        return form;
    }
    let queryParam = {};
    const operatorArray = [];
    const sortArray = [];
    if (SortParamList){
        SortParamList.forEach(item => {
            if (item.column) {
                sortArray.push(`${item.sort}-${item.column}`)
            }
        })
    }
    if (SearchParamList){
        SearchParamList.forEach(item=>{
            if (form[item.getColumn()]||form[item.getColumn()]===false){
                // console.log(form[item.getColumn()])
                const query = item.getCondition() || condition.EQ
                operatorArray.push(`${item.getColumn()}-${query}`)
            }
        })
    }else {
        for(const key in form){
            if(key==='currentPage'
            ||key==='pageSize'){
                continue
            }
            operatorArray.push(`${key}-${condition.EQ}`)
        }
    }

    if (operatorArray.length>0) {
        queryParam['operators'] = operatorArray.join(",")+",";
    }
    if (sortArray.length>0){
        queryParam['sorter'] = sortArray.join(",")+","
    }
    return Object.assign({},queryParam,form);
}
export class SortParam {
    constructor(column, sort) {
        this.column = column;
        this.sort = sort;
    }

}
export class SearchParam {
    constructor(column,condition) {

        this.column = column;
        this.condition = condition;

    }


    getColumn() {
        return this.column;
    }


    getCondition() {
        return this.condition;
    }
}
export const sort ={
    /**
     * 条件枚举
     */
    ASC : "asc",
    DESC : "desc"
}
export const condition ={
    //精准查询
    EQ : "eq",
    //不等于
    NE:"ne",
    //模糊
    LIKE : "like",
    LEFT_LIKE : "left_like",
    RIGHT_LIKE :"right_like",
    NOT_LIKE : "not_like",
    GT : "gt",
    GE : "ge",
    LT : "lt",
    LE : "le",
    IN : "in",
    NOT_IN : "not_in",
    IS_NULL : "is_null",
    IS_NOT_NULL : "is_not_null"
}

export function getQuery(requestParam,condition,sort) {
    if (!requestParam){
        return requestParam;
    }
    let queryParam = {};
    const operatorArray = [];
    const sortArray = [];
    if (sort){
        for(const key in sort){
            sortArray.push(`${key}-${sort[key]}`)
        }
    }
    if (condition){
        for(const key in condition){
            if (requestParam[key]) {
                operatorArray.push(`${key}-${condition[key]}`)
            }
        }
    }else {
        for(const key in requestParam){
            if(key==='currentPage'
                ||key==='pageSize'){
                continue
            }
            operatorArray.push(`${key}-${condition.EQ}`)
        }
    }
    if (operatorArray.length>0) {
        queryParam['operators'] = operatorArray.join(",")+",";
    }
    if (sortArray.length>0){
        queryParam['sorter'] = sortArray.join(",")+","
    }

    return Object.assign({},queryParam,requestParam);
}

export function Query(query,sort) {
    return function log(target, key, descriptor) {
        const originalMethod = descriptor.value;
        descriptor.value = function (...args) {
            args[0] = getQuery(args[0],query,sort)
            return originalMethod.apply(this, args);
        };
        return descriptor;
    }
}

export function Loadding() {
    return function log(target, key, descriptor) {
        console.log(target,key,descriptor)
        const originalMethod = descriptor.value;
        descriptor.value = function (...args) {
            return originalMethod.apply(this, args);
        };
        return descriptor;
    }
}