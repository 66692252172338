import { getCategory } from '@/api/goods'
import { getAreaOption } from '@/api/sys'
export default {
    state: {
        categoryData: [],
        areaOptions: []
    },
    mutations: {
        editCategoryData(state, value) {
            state.categoryData = value
        },
        editAreaOptions(state, value) {
            state.areaOptions = value
        }

    },
    actions: {
        getCategory({ commit }) {
            getCategory().then(res => {
                commit('editCategoryData', res)
            })
        },
        getAreaOptions({ commit }) {
            getAreaOption(0).then(res => {
                commit('editAreaOptions', res)
            })
        }
    },
    namespaced: true
};