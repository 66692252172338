<!-- 播放视频弹窗-->
<template>
  <el-dialog
    class="popup_dialog"
    :visible.sync="dialogVisible"
    @close="closeDialog"
    @opened="openDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="1000px"
  >
    <video
      ref="video"
      v-if="activeVideo.url"
      :src="activeVideo.url"
      controls="controls"
      controlsList="nodownload"
      :poster="activeVideo.cover"
    ></video>
    <div class="close_dialog" @click="closeDialog">
      <i class="iconfont icon-a-Group6768999"></i>
    </div>
  </el-dialog>
</template>
        
  <script>
export default {
  props: ["visible", "activeVideo"],
  data() {
    return {
      dialogVisible: false,
      password: "",
      loading: false,
    };
  },

  methods: {
    openDialog() {
      this.$nextTick(() => {
        this.$refs.video.play();
      });
    },
    closeDialog() {
      this.$refs.video.pause();
      this.$emit("closeDialog");
    },
  },
  watch: {
    visible(newValue) {
      this.dialogVisible = newValue;
    },
    immediate: true,
  },
};
</script>
        
        <style lang="less" scoped>
.header {
  width: 100%;
  text-align: center;
  color: @main-color;
  font-weight: 700;
  font-size: 22px;
}
video {
  margin: 0 auto;
  width: 1000px;
  height: 560px;
}
.popup_dialog {
  .el-dialog__header {
    display: none;
  }

  .el-dialog {
    background: rgba(0, 0, 0, 0) !important;
    position: relative;
    box-shadow: none;
  }
  .close_dialog {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -100px;
    font-size: 22px;
    top: 0;
    background-color: rgb(70, 70, 70);

    color: #fff;
    cursor: pointer;
    .iconfont{
      font-size: 20px;
    }
  }
  .el-dialog__body {
    padding: 0;
    background: rgba(0, 0, 0, 0) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 900px;
    .popup_img {
      max-width: 100%;

      cursor: pointer;
    }
  }
}
</style>