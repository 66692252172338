<!-- 顶部导航栏 -->
<template>
  <div class="Topbar">
    <div class="c">
      <ul>
        <li @click="toView('/myCenter/myOrders')">我的订单</li>
        <li @click="toView('/drafts')">草稿箱</li>
        <li @click="toView('/guideView')">新手引导</li>
        <li @click="toView('/myCenter/myCollection')">我的收藏</li>

        <!-- <el-popover
        placement="bottom"
        width="200"
        trigger="hover"
        popper-class="browse_records"
      >
        <div class="content">
          <div class="goods_list" v-if="recordsInfo.length">
            <div
              class="list_item"
              v-for="(item, index) in recordsInfo"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="right_line"></div>
              <div class="img">
                <img :src="JSON.parse(item.url)[0]" />
              </div>
              <div class="text">
                <div class="name single_omission">{{ item.objName }}</div>
                <div class="price">￥{{ item.objPrice }}元</div>
              </div>
            </div>
          </div>
          <div class="none" v-else>暂无浏览记录</div>
        </div>
        <li slot="reference">浏览记录</li>
      </el-popover> -->
        <li
          class="vip_center"
          v-if="userInfo.role == 'BUYER' || !userInfo.role"
        >
          <div class="title_text">学研汇VIP</div>
          <div class="list">
            <p
              @click="$router.push('/applyVip')"
              v-if="userInfo.role == 'BUYER' || !userInfo.role"
            >
              立即开通
            </p>
            <p
              v-if="userInfo.role == 'BUYER' || !userInfo.role"
              @click="$router.push('/understandMember')"
            >
              了解VIP
            </p>
            <!-- <p v-else @click="$router.push('/memberCenter')">VIP中心</p> -->
          </div>
        </li>
        <li class="vip_center" @click="$router.push('/memberCenter')" v-else>
          VIP中心
        </li>
        <li>
          <el-popover
            placement="bottom"
            width="200"
            trigger="hover"
            popper-class="official_account"
          >
            <div class="content">
              <div class="img">
                <img
                  :src="`${baseUrl}/static/defaults/gongzhonghao.png`"
                  alt=""
                />
              </div>
              <div class="text">
                <p>学研汇公众号</p>
                <span>扫码关注可了解更多详情</span>
              </div>
            </div>
            <span slot="reference">关注公众号</span>
          </el-popover>
        </li>
        <!-- <li @click="$router.push('/IFqueryView')">IF查询</li> -->
        <!-- <li @click="$router.push('/partitionQueryView')">分区查询</li> -->
        <!-- <li @click="$router.push('/literatureHelp')">文献求助</li> -->
        <!-- <li @click="$router.push('/aboutUs')">关于我们</li> -->
         <li @click="$router.push('/paperThank')">论文致谢</li> 
        <!-- 登录或注册 -->
        <li>
          <el-dropdown
            placement="bottom"
            v-if="userInfo && JSON.stringify(userInfo) != '{}'"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ userInfo.nickname
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="myCenter">个人中心</el-dropdown-item>
              <el-dropdown-item command="myInvoice">我的发票</el-dropdown-item>
              <!-- <el-dropdown-item command="myTeam">我的团队</el-dropdown-item> -->
              <el-dropdown-item command="memberCenter"
                >会员中心</el-dropdown-item
              >
              <!-- <el-dropdown-item command="logout">我的积分</el-dropdown-item> -->
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="login_box" v-else>
            <div class="login_btn" @click="toLogin()">登录</div>
            <div class="vertical">|</div>
            <div class="register_btn" @click="toRegister()">注册</div>
          </div>
        </li>
      </ul>
    </div>
    <complainDialog
      :visible="showComplainDialog"
      @closeDialog="closeDialog"
    ></complainDialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { getRecords } from "@/api/member/member";
// import { getUserInfo } from "@/api/user";
import { checkLogin, userCount } from "@/tool";
import complainDialog from "./dialogComponents/complainDialog.vue";

export default {
  components: { complainDialog },

  computed: {
    ...mapState("user", ["userInfo", "recordsInfo"]),
  },
  data() {
    return {
      showComplainDialog: false,
    };
  },
  created() {
    if (checkLogin()) {
      // 获取浏览记录
      getRecords().then((res) => {
        this.setRecordsInfo(res.records);
      });
      // 获取用户信息
      // this.getUserInfo();
    }
  },
  methods: {
    ...mapActions("user", ["logout"]),
    ...mapMutations("user", ["setRecordsInfo", "setUserInfo", "clearUserInfo"]),
    showDialog() {
      this.showComplainDialog = true;
      this.showCustomer = false;
      this.showGZH = false;
      userCount("投诉建议");
    },
    toDetail(item) {
      switch (item.type) {
        case "TEST_INSTRUMENT":
          this.$router.push(`/details/${item.objId}`);
          break;
      }
    },
    closeDialog() {
      this.showComplainDialog = false;
    },
    // 点击下拉列表
    handleCommand(command) {
      // 退出登录
      if (command == "logout") {
        this.$confirm("确定要退出登录吗？", "提示").then(() => {
          this.logout().then(() => {
            this.$message({ type: "success", message: "退出登录成功" });
            this.clearUserInfo({}); // 更新vuex
            this.$router.push("/Login");
          });
        });
      } else if (command == "myCenter") {
        this.$router.push("/myCenter/centerHome");
      } else if (command == "myInvoice") {
        this.$router.push("/myCenter/invoiceInfoAdmin");
      } else if (command == "memberCenter") {
        // 判断是不是会员
        console.log(this.userInfo.role);
        const { role } = this.userInfo;
        if (role && role.includes("BUYER_VIP")) {
          // 是会员 去会员中心页面
          this.$router.push("/memberCenter");
        } else {
          // 不是会员  去了解会员页面
          this.$router.push("/understandMember");
        }
      }
    },
    // 跳转登录页面
    toLogin() {
      this.$router.push("/Login");
    },
    // 跳转注册页面
    toRegister() {
      this.$router.push("/Register?type=0");
    },
    toView(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.browse_records {
  width: auto !important;
  max-width: 780px;

  .content {
    background-color: #fff;
    .goods_list {
      display: flex;
      height: 170px;
      .list_item {
        width: 195px;
        position: relative;
        cursor: pointer;
        .img {
          padding: 0 42px;
          margin-bottom: 8px;
          height: 112px;
          img {
            width: 112px;
            height: 112px;
          }
        }
        .text {
          display: flex;
          padding: 0 30px;
          justify-content: space-between;
          font-weight: 700;
          align-items: center;
          .name {
            color: #616161;
            font-size: 14px;
          }
          .price {
            flex-shrink: 0;
            color: @main-color;
            font-size: 12px;
          }
        }
        .right_line {
          width: 1px;
          height: 80px;
          background-color: #acacac;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .list_item:last-of-type {
        .right_line {
          display: none;
        }
      }
    }
    .none {
      text-align: center;
    }
  }
}
.Topbar {
  min-width: 1200px;
  min-height: 40px;
  background-color: #444444;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  padding: 0 360px;
  line-height: 40px;
  .c {
    position: relative;
  }
  ul {
    display: flex;
    align-items: center;
    text-align: right;
    position: absolute;
    right: 0;
    li {
      margin-right: 40px;
      list-style: none;
      cursor: pointer;
    }
    li:last-child {
      margin-right: 0;
    }
  }
}
.vip_center {
  height: 100%;
  line-height: 40px;
  position: relative;
  &:hover {
    .list {
      display: block;
    }
  }
  .list {
    display: none;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #616161;
    width: 160px;
    border: 1px solid #cdcdcd;
    z-index: 77;
    // border-radius: 4px;
    p {
      height: 33%;
      line-height: 30px;
      text-align: center;
      border-bottom: 1px solid #cdcdcd;
    }
    p:last-child {
      border-bottom: 0;
    }
  }
}
.official_account {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      width: 112px;
      height: 112px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      text-align: center;
      p {
        margin-top: 4px;
        font-size: 14px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}
.el-dropdown-link {
  color: #fff;
}
.login_box {
  display: flex;
  .vertical {
    margin: 0 10px;
  }
  .login_btn,
  .register_btn {
    cursor: pointer;
  }
}
</style>