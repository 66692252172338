<template>
  <div class="massage_box_cpt">
    <el-popover placement="bottom" trigger="click">
      <div class="mail_box" slot="reference">
        <div class="icon">
          <img src="@/assets/imgs/messageIcon.png" alt="" />
          <div class="dot" v-if="isShowDot"></div>
        </div>

        消息
      </div>
      <div class="message_box" slot="default">
        <div class="header">
          <div class="text">消息</div>
          <i
            class="iconfont icon-Frame1"
            @click="toView('/messageHistory')"
          ></i>
          <div class="blue_text" @click="readAll">一键已读</div>
        </div>
        <div class="line"></div>
        <div class="tabs">
          <div
            class="item"
            :class="{ active_item: pageData.type == 'all' }"
            @click="changeData('all')"
          >
            全部消息
          </div>
          <div
            class="item"
            :class="{ active_item: pageData.type == 'time' }"
            @click="changeData('time')"
          >
            最新消息
          </div>
        </div>
        <div class="filter">
          <el-select v-model="pageData.isRead" @change="changeFilter">
            <el-option label="全部" value="all"></el-option>
            <el-option label="已读" :value="true"></el-option>
            <el-option label="未读" :value="false"></el-option>
          </el-select>
        </div>
        <div class="message_list">
          <div class="list" v-scroll="getData">
            <div
              class="list_item"
              v-for="item in messageList"
              :key="item.id"
              @click="clickItem(item)"
            >
              <div class="icon">
                <div class="dot" v-if="!item.isRead"></div>

                <img
                  v-if="item.type == 'ACTIVITY'"
                  src="@/assets/imgs/homeIcon/message2.png"
                  alt=""
                />
                <img
                  v-else-if="item.type == 'TRADE'"
                  src="@/assets/imgs/homeIcon/message3.png"
                  alt=""
                />
                <img v-else src="@/assets/imgs/homeIcon/message1.png" alt="" />
              </div>
              <div class="content">
                <div class="text multi_omission">
                  <template>{{ item.content }} </template>
                  <!-- <template >
                    <el-tooltip placement="top-start">
                      <div slot="content">{{ item.content }}</div>
                      <span>{{ item.content }}</span>
                    </el-tooltip>
                  </template> -->
                </div>
                <div class="time">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
          <!-- <div v-if="isLoading" class="loading">加载中...</div> -->
          <div v-if="isEnd" class="end">没有更多啦~</div>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { getUserMessage, readAllMessage, readMessage } from "@/api/user";
import { getQueryParam, SearchParam, condition } from "@/tool/requestUtils";
import { jump } from "@/mixins/messageRouter";
import { dateTimeToString } from "@/tool";
import { mapState } from "vuex";

export default {
  data() {
    return {
      pageData: {
        type: "all",
        isRead: "all",
        currentPage: 1,
        pageSize: 6,
      },
      searchList: [
        new SearchParam("startTime", condition.GT),
        new SearchParam("endTime", condition.LT),
        new SearchParam("isRead", condition.EQ),
      ],
      messageList: [],
      isLoading: false, // 是否正在加载
      isEnd: false, // 是否加载完所有数据
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    isShowDot() {
      let find = this.messageList.find((i) => !i.isRead);
      return find;
    },
  },
  methods: {
    readAll() {
      readAllMessage().then(() => {
        this.$message({ type: "success", message: "读取所有消息成功！" });
        this.messageList = this.messageList.map((i) => {
          i.isRead = true;
          return { ...i };
        });
      });
    },
    toView(path) {
      this.$router.push(path);
    },
    clickItem(item) {
      readMessage(item.id).then(() => {
        item.isRead = true;
      });
      const { routeType, routeParam } = item;
      if (routeType != "NONE") {
        jump(routeType, JSON.parse(routeParam));
      }
    },
    changeData(str) {
      this.isEnd = false;
      this.pageData.type = str;
      this.messageList = [];
      this.pageData.currentPage = 1;
      this.pageData.isRead = "all";
      this.getData();
    },
    changeFilter() {
      this.isEnd = false;
      this.messageList = [];
      this.pageData.currentPage = 1;
      this.getData();
    },
    getData() {
      if (this.isLoading || this.isEnd || !this.userInfo.id) return;
      this.isLoading = true;
      const { type, isRead, currentPage, pageSize } = this.pageData;
      let time = dateTimeToString(new Date());
      const [one] = time.split(" ");
      let pageData = {
        type: type == "all" ? undefined : type,
        isRead: isRead == "all" ? undefined : isRead,
        currentPage,
        pageSize,
        startTime: type == "all" ? undefined : `${one} 00:00:00`,
        endTime: type == "all" ? undefined : `${one} 23:59:59`,
      };
      pageData = getQueryParam(pageData, this.searchList);
      getUserMessage(pageData).then((res) => {
        let newData = res.records;
        if (newData.length) {
          this.messageList = this.messageList.concat(newData);
        }
        this.pageData.currentPage++;
        this.isLoading = false;
        if (!newData.length && newData.length < this.pageData.pageSize) {
          this.isEnd = true;
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
@import url(@/assets/css/message.less);
</style>