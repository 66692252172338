<!-- 学研汇首页 -->
<template>
  <div class="home">
    <Topbar></Topbar>
    <div class="navbar">
      <div class="c">
        <!-- logo图片 -->
        <div class="logo" @click="$router.push('/')">
          <img :src="`${baseUrl}/static/defaults/logo.png`" alt="" />
        </div>
        <!-- 搜索框 -->
        <div class="search_box">
          <el-autocomplete
            v-model="searchText"
            :popper-append-to-body="false"
            :fetch-suggestions="getSearchList"
            placeholder="请输入仪器名称或型号"
            @select="clickSearchItem"
            value-key="name"
            value="id"
            :trigger-on-focus="false"
          >
            <img slot="prefix" src="../assets/imgs/search.png" alt="" />
            <el-button class="search_btn" slot="append" @click="toSearchResult"
              >搜索</el-button
            >
          </el-autocomplete>
        </div>
        <!-- 预存按钮 -->
        <div class="demand_btn" @click="$router.push('/preStorageStatic')">
          <img src="@/assets/imgs/homeIcon/yucun.png" alt="" />
        </div>
        <!-- 站内信图标 -->
        <message-box></message-box>
      </div>
    </div>
    <div class="tabBar">
      <div class="tabBar_header">
        <div class="c">
          <div
            class="tabBar_item"
            :class="{
              activeItem:
                $route.path.includes(item.path) ||
                $route.path.includes(item.path2),
              show_drawer: item.compsName == 'category',
            }"
            v-for="(item, index) in viewList"
            :key="index"
            @click="switchTab(item)"
            @mouseover="tabMouseover(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- 抽屉 -->
        <div
          class="drawer"
          :class="{ show_drawer: showDrawer }"
          @mouseover="drawerMouseover"
          @mouseout="mouseout"
        >
          <div class="content c">
            <div class="list">
              <div class="list_item" v-for="item in drawerData" :key="item.id">
                <div class="left">
                  <div class="parent_node">
                    <i
                      :class="[
                        'iconfont',
                        item.icon && item.icon.replace('#', ''),
                      ]"
                    ></i>
                    {{ item.name }}
                  </div>
                </div>
                <div class="right">
                  <div
                    class="tag_item"
                    v-for="(i, ix) in item.children"
                    :key="ix"
                    @click="toPath(i)"
                  >
                    {{ i.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- tabs的内容 -->
      <router-view></router-view>
    </div>
    <!-- 底部 -->
    <Footer></Footer>
    <!-- 右边悬浮栏 -->
    <RightBar></RightBar>
    <div class="mask" v-if="showDrawer" @click="showDrawer = false"></div>

    <elementsPeriodicTable
      v-if="false"
      :multiple="true"
      @choiceEle="choiceEle"
    ></elementsPeriodicTable>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import Footer from "@/components/Footer.vue";
import RightBar from "@/components/RightBar.vue";
import elementsPeriodicTable from "@/components/elementsPeriodicTable";
// import { localStorage } from "@/tool/storage";
import { mapState, mapActions, mapMutations } from "vuex";
import { getAllCategory, searchKeyword } from "@/api/goods";
import MessageBox from "@/components/messageBox.vue";

export default {
  name: "HomeView",
  components: {
    Topbar,
    Footer,
    RightBar,
    MessageBox,
    elementsPeriodicTable,
  },
  data() {
    return {
      searchText: "",
      activeView: "homeView",
      showDrawer: false,
      viewList: [
        {
          name: "首页",
          compsName: "homeView",
          path: "/home",
          showDrawer: false,
        },
        {
          name: "测试分类",
          compsName: "category",
          path: "/category",
          showDrawer: true,
        },
        {
          name: "同步辐射",
          compsName: "syncRdiation",
          path: "/syncRdiation",
          showDrawer: false,
        },
        {
          name: "高端测试",
          compsName: "acTem",
          path: "/acTem",
          showDrawer: false,
        },
        {
          name: "原位测试",
          compsName: "situTest",
          path: "/situTest",
          showDrawer: false,
        },
        {
          name: "纳米材料",
          compsName: "nmMaterial",
          path: "/nmMaterial",
          showDrawer: false,
        },
        {
          name: "计算模拟",
          compsName: "cptSimulation",
          path2: "/cptGoodsView",
          path: "/cptSimulation",
          showDrawer: false,
        },
        {
          name: "数据解析",
          compsName: "dataAnalysis",
          path: "/dataAnalysis",
          showDrawer: false,
        },
        {
          name: "学研云课堂",
          compsName: "classroomView",
          path: "/classroom",
          showDrawer: false,
        },
      ],
      drawerData: [],
      drawerActive: 1,
      bannerList: [],
    };
  },
  created() {
    this.activeView = this.$route.path;
    this.getAllCategory();
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    activeDrawerData() {
      return this.drawerData[this.drawerActive - 1];
    },
  },
  methods: {
    ...mapActions("user", ["logout"]),
    ...mapMutations("user", ["clearUserInfo"]),
    // 跳转搜索结果页面
    toSearchResult() {
      if (!this.searchText) {
        // this.searchText = "";
        return 
      }
      let path = `/searchResult?keyword=${this.searchText}`;
      this.$router.push(path);
      this.$EventBus.$emit("search", this.searchText);
    },
    // 获取搜索列表
    getSearchList(searchStr, callBack) {
      if (!searchStr) {
        callBack([]);
      } else {
        searchKeyword({ keyword: searchStr }).then((res) => {
          callBack(res);
        });
      }
    },
    clickSearchItem(item) {
      if (item.id) {
        this.$router.push(`/details/${item.id}`);
      }
    },

    // 获取分类的数据
    getAllCategory() {
      getAllCategory().then((res) => {
        this.drawerData = res;
      });
    },

    // 切换tba
    switchTab(item) {
      this.activeView = item.path;
      this.showDrawer = false;
      this.$router.push(item.path);
    },
    // 点击抽屉的子项 跳转页面
    toPath(item) {
      if (item.path) {
        let path = `${item.path}?id=${item.id}`;
        this.$router.push(path);
      } else {
        this.$router.push(`/category?id=${item.id}`);
      }
      // let path;
      // if (isParent) {
      //   path = item.path + `?parentId=${item.id}&childrenId=${item.id}`;
      // } else {
      //   path = item.path + `?parentId=${item.parentId}&childrenId=${item.id}`;
      // }
      // this.$router.push(path);
    },
    // 切换抽屉左侧list
    changeActive(value) {
      // this.parentNode = item;
      this.drawerActive = value;
    },
    // 抽屉移入事件
    drawerMouseover() {
      this.showDrawer = true;
    },
    // tab-item移入事件
    tabMouseover(item) {
      if (!this.$route.path.includes("category")) {
        this.showDrawer = item.showDrawer;
      }
    },
    // tab-item移出事件
    mouseout() {
      this.showDrawer = false;
    },
    // 元素周期表选择事件
    choiceEle(value) {
      console.log(value);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 300px;
    left: 0;
    right: 0;
    background: rgba(95, 95, 95, 0.38);
    z-index: 9;
  }
  .navbar {
    width: 100%;
    display: flex;
    margin: 0 auto;
    height: 110px;
    align-items: center;
    z-index: 999;
    background-color: #fff;
    .c {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      align-items: end;
    }
    .logo {
      cursor: pointer;
      img {
        max-width: 200px;
      }
    }
    .search_box {
      width: 380px;
      height: 40px;
      margin: 0 0px 0 395px;
      img {
        width: 20px;
        height: 20px;
      }
      .search_btn {
        border-radius: 0px 4px 4px 0px;
        font-size: 16px;
        color: white;
        height: 40px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 0;
        background-color: @main-color;
        font-weight: 700;
      }
      /deep/.el-input {
        width: 300px;
        font-size: 16px;
        height: 40px;
        .el-input__inner {
          width: 300px;

          border: 2px solid @main-color;
          height: 40px;
          vertical-align: middle;
        }
        .el-input__prefix {
          display: flex;
          align-items: center;
          left: 10px;
          height: 40px;
        }
      }
    }
    .demand_btn {
      cursor: pointer;
      img {
        vertical-align: middle;
      }
    }
  }
  .tabBar {
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    .tabBar_header {
      min-width: 1200px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: sticky;
      top: 0;
      z-index: 999;
      background-color: #fff;

      box-shadow: 0px 4px 7px 0px rgba(178, 178, 178, 0.25);
      .c {
        display: flex;
        align-items: center;
      }
      .drawer {
        width: 100%;
        position: absolute;
        top: 40px;
        background-color: #fff;
        font-size: 16px;

        .content {
          height: 0;
          transition: all 0.1s linear;
          display: flex;
          .list {
            display: none;
            transition: all 0.1s linear;
            .list_item {
              display: flex;
              background-color: #fff;
              width: 100%;
              height: auto;
              align-items: stretch;
              .left {
                width: 150px;

                padding: 0 10px;
                .parent_node {
                  text-align: center;
                  min-height: 60px;
                  border-bottom: 2px solid @main-color;
                  height: 100%;
                  display: flex;
                  padding-left: 18px;
                  align-items: center;
                  .iconfont {
                    color: @main-color;
                    font-size: 22px;
                    margin-right: 5px;
                  }
                  &:hover {
                    color: @main-color;
                  }
                }
              }
              .right {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                padding-left: 40px;
                min-height: 60px;
                align-items: center;
                background-color: #f0f7ff;
                border-bottom: 4px solid #fff;
                .tag_item {
                  margin: 9px 60px 9px 0;
                  min-width: 100px;
                  float: left;
                  text-align: left;
                  cursor: pointer;
                }
                .tag_item:hover {
                  color: @main-color;
                }
              }
            }
            .list_item:last-child {
              .left {
                .parent_node {
                  border-bottom: 0;
                }
              }
            }
          }
        }
      }
      .show_drawer {
        .content {
          // min-height: 282px;
          height: auto;
          .list,
          .tag_content {
            display: block;
          }
        }
      }
    }
    .tabBar_item {
      flex: 1;
      cursor: pointer;
      height: 100%;
      line-height: 40px;
      font-size: 20px;
      color: #2f2f2f;
      font-weight: 700;
      transition: all 0.5s;
      background-color: #fff;
      &:hover {
        color: @main-color;
      }
    }
    .activeItem {
      background-color: @main-color;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
}
</style>