import request from '@/api/request'
let oauthURL = process.env.VUE_APP_OAUTH_URL

// 用户登录
export function login(params) {
    return request({
        url: '/oauth/token',
        method: 'post',
        headers: {
            Authorization: "Basic bWVtYmVyOjJiOGZlNGVlMDhiZGEwM2ZkMTA3MjhkNGVhYjE3OTg0"
        },
        baseURL: oauthURL,
        params: { ...params, origin: 'pc' }
    });
}
// 微信三方登录
export function reqWxLogin() {
    return request({
        url: `/oauth/wechat/state`,
        method: 'get',
        baseURL: oauthURL
    })
}
// 微信公众号登录
export function reqWxMqLogin() {
    return request({
        url: `/oauth/wechat/mq/login`,
        method: 'get',
        baseURL: oauthURL
    })
}
// 用户注册
export function register(data, smsToken) {
    return request({
        url: '/member/register',
        method: 'post',
        params: { smsToken },
        data: { ...data, origin: 'pc' }
    });
}
// 用户修改密码
export function editPassword(params) {
    return request({
        url: '/member/updatePassword',
        method: 'post',
        params
    });
}
//手机号是否重复
export function mobileExits(mobile) {
    return request({
        url: '/member/mobileExits',
        method: 'get',
        params: { mobile }
    });
}
//昵称是否重复
export function nicknameExits(nickname) {
    return request({
        url: '/member/nicknameExits',
        method: 'get',
        params: { nickname }
    });
}
// 用户注销
export function logout() {
    return request({
        baseURL: oauthURL,
        url: '/oauth/logout',
        method: 'delete',
    });
}
// 刷新token
export function refresh(refreshToken) {
    return request({
        baseURL: oauthURL,
        url: '/oauth/token',
        method: 'post',
        headers: { "Authorization": "Basic bWVtYmVyOjJiOGZlNGVlMDhiZGEwM2ZkMTA3MjhkNGVhYjE3OTg0" },
        params: { "refresh_token": refreshToken, "grant_type": "refresh_token" }
    });
}
// 用户提交发布需求
export function requisitionForm(data) {
    return request({
        url: '/member/requisition-form',
        method: 'post',
        data
    });
}
// 获取用户的抢购列表
export function getMemberFlashSale(params) {
    return request({
        url: `trade/flash-sale-order/queryPage`,
        params
    })
}
// 获取用户的优惠券列表
export function getMemberCoupon(type, params) {
    return request({
        url: `/member/member-coupon/${type}`,
        params
    })
}
// 用户领取优惠券
export function drawCoupon(couponId) {
    return request({
        url: '/member/member-coupon/receive',
        method: "post",
        params: { couponId }
    })
}
// 当前会员等级的优惠券
export function memberCoupon() {
    return request({
        url: '/member/member-coupon/getVipConfigCoupon',
        method: 'get',

    })
}

// 修改/保存用户信息
export function saveUserInfo(data, params) {
    return request({
        url: "/member/update",
        method: "post",
        data,
        params
    })
}
// 获取用户信息
export function getUserInfo() {
    return request({
        url: "/member/info",
        method: "post"
    })
}
// 获取用户的实名信息
export function getRealNameInfo() {
    return request({
        url: '/member/real',
        method: "get"
    })
}
// 获取用户的发票信息
export function getUserInvoice() {
    return request({
        url: '/member/member-receipt/queryList',
        method: "get"
    })
}
// 用户添加发票信息
export function postInvoiceInfo(data) {
    return request({
        url: "/member/member-receipt",
        method: "post",
        data
    })
}
// 用户修改发票信息
export function editInvoiceInfo(data) {
    return request({
        url: "/member/member-receipt",
        method: "put",
        data
    })
}
// 用户删除发票信息
export function delInvoiceInfo(id) {
    return request({
        url: `/member/member-receipt/${id}`,
        method: "delete",
    })
}
// 保存用户实名信息
export function saveRealInfo(data) {
    return request({
        url: `member/real`,
        method: "post",
        data
    })
}
//修改用户实名信息
export function editRealInfo(data) {
    return request({
        url: `member/real`,
        method: "put",
        data
    })
}
// 获取用户的实名信息
export function getRealInfo() {
    return request({
        url: `/member/real`,
        method: "get",
    })
}
// 用户收藏商品
export function collectGoods(data, type) {
    return request({
        url: `/member/member-collect`,
        method: type,
        data
    })
}
// 获取用户收藏的列表
export function getCollectList(params) {
    return request({
        url: `/member/member-collect/queryPage`,
        method: 'get',
        params
    })
}
// 用户删除收藏
export function deleteCollect(ids) {
    return request({
        url: `/member/member-collect/${ids}`,
        method: 'delete',
    })
}
// 用户提交反馈和投诉
export function postComplain(data) {
    return request({
        url: `/member/complain-project`,
        method: 'post',
        data
    })
}
// 获取用户的统计  余额，优惠券的数额
export function getMemberCount() {
    return request({
        url: `/member/count`,
        method: 'get',
    })
}
// 获取用户是否开通支付协议
export function getUserIsOpenPay() {
    return request({
        url: "/member/wallet/isOpenPay",
        method: "get"
    })
}
// 获取用户评价
export function getUserComment(params) {
    return request({
        url: "/member/comment/detail",
        method: "get",
        params
    })
}
// 用户提交文献求助
export function postLiteratureHelp(data) {
    return request({
        url: '/member/literature-help',
        method: 'post',
        data
    })
}
// 获取用户的文献求助记录

export function getHelpRecords(params) {
    return request({
        url: '/member/literature-help/queryPage',

        params
    })
}
// 查看文献求助记录详情
export function getHelpRecordsById(id) {
    return request({
        url: `/member/literature-help/${id}`,
    })
}
// 用户下载技术服务合同
export function downloadContractTemplate() {
    return request({
        url: '/member/downloadContractTemplate',
        method: "post"
    })
}
// 用户修改支付密码
export function userEditPayPassword(params) {
    return request({
        url: '/member/wallet/updateTradePassword',
        method: 'post',
        params
    })
}
// 用户提交人脸认证
export function userPostQuickAuth(data) {
    return request({
        url: '/member/real/tripartite',
        method: 'post',
        data
    })

}
// 轮询用户是否进行了人脸识别
export function isAuthTripartite() {
    return request({
        url: '/member/real/isAuthTripartite',
        method: 'get',

    })

}
// 用户提交预存申请

export function applyPre(data) {
    return request({
        url: '/member/member-prepaid-apply-bill/apply',
        method: 'post',
        data
    })

}
// 根据id查询用户提交的预存申请
export function getPreStoreDetail(sn) {
    return request({
        url: `/member/member-prepaid-apply-bill/sn/${sn}`,
        method: 'get',
    })

}
// 获取用户的预存申请列表
export function getPreStoreList(params) {
    return request({
        url: `/member/member-prepaid-apply-bill/queryPage`,
        params
    })
}
// 用户取消预存申请
export function cancelPreStore(sn) {

    return request({
        url: `/member/member-prepaid-apply-bill/cancel`,
        params: { sn },
        method: 'delete'
    })
}
// 重新提交预存申请
export function resubmitPreStorage(data) {
    return request({
        url: `/member/member-prepaid-apply-bill/reapply`,
        data,
        method: 'post'
    })
}
// 用户确认已转账
export function userEnterTransferAccounts(data) {
    return request({
        url: `/member/member-prepaid-apply-bill/uploadVoucher`,
        data,
        method: 'post'
    })
}
// 催一催预存申请
export function remindPre(sn) {
    return request({
        url: `/member/member-prepaid-apply-bill/remind/${sn}`,
        method: 'post'
    })
}
// 用户上传预存单转账凭证
export function uploadVoucher(data) {
    return request({
        url: `/member/member-prepaid-apply-bill/uploadVoucher`,
        method: 'post',
        data
    })
}
// 获取用户使用的额度统计
export function getMemberQuotaCount() {
    return request({
        url: `/member/wallet/quota/count`,
        method: 'get'
    })
}
// 获取用户使用的预存统计

export function getMemberPreStorageCount() {
    return request({
        url: `/member/wallet/memberPrepaid/count`,
        method: 'get'
    })
}
// 获取用户的奖励统计

export function getMemberAwardCount() {
    return request({
        url: `/member/wallet/award/count`,
        method: 'get'
    })
}
// 获取用户第三方授权信息
export function getUserBindInfo() {
    return request({
        url: `/other/oauth/bindInfo`,
        method: 'get'
    })
}
// 用户解绑第三方微信登录
export function userUnbindWeChat(smsToken) {
    return request({
        url: `/other/oauth/wechat/unbind?smsToken=${smsToken}`,
        method: 'delete'
    })
}
// 用户绑定第三方微信登录
export function userBindWeChat(params) {
    return request({
        url: `/other/oauth/wechat/bind`,
        method: 'post',
        params
    })
}
// 获取用户的站内信消息
export function getUserMessage(params) {
    return request({
        url: `/member/message/queryPage`,
        method: 'get',
        params
    })
}
// 用户已读消息
export function readMessage(id) {
    return request({
        url: `/member/message/read/${id}`,
        method: 'put',
    })
}
// 用户消息全部已读
export function readAllMessage() {
    return request({
        url: `/member/message/read/all`,
        method: 'put',
    })
}
// 用户提交论文致谢申请


export function postThesisThanks(data) {
    return request({
        url: `/trade/thesis-thanks`,
        method: 'post',
        data
    })
}
// 获取用户提交的论文致谢记录

export function getThesisThanksPage(params) {
    return request({
        url: `/trade/thesis-thanks/queryPage`,
        method: 'get',
        params
    })
}
