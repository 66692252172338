<template>
  <div class="footer">
    <div class="footer_content c">
      <div class="left">
        <div class="logo_box">
          <div class="logo">
            <img :src="`${baseUrl}/static/defaults/logo.png`" alt="" />
          </div>
          <!-- <div class="text">
            学研汇专注于科学仪器共享预约服务，同时也提供计算模拟技术服务与纳米材料商城服务
          </div> -->
        </div>
        <div class="contact">
          <!-- <p class="title">联系我们</p>
          <p>
            <span>李老师：18202787636</span>
            <span>朱老师：15827592922</span>
            <span>钱老师：18837127271</span>
          </p>
          <p>
            <span>邮箱：{{ "ixueyanhui@163.com" }}</span>
            <span>学习交流qq群①：{{ "831767101" }}</span>
            <span>学习交流qq群②：{{ "943494206" }}</span>
          </p> -->
          <div class="item">
            <div class="item_title">联系我们</div>
            <div class="item_content">
              <p>
                <span class="iconfont icon-a-Group6768795"></span> 电话：<span
                  class="bold"
                  >188-3712-7271</span
                ><span>（钱经理）</span>
              </p>
              <p>
                <span class="iconfont icon-a-Group6768796"></span>邮箱：
                <span class="bold">ixueyanhui@163.com</span>
              </p>
            </div>
          </div>
          <div class="item">
            <div class="item_title">技术社群</div>
            <div class="item_content">
              <p>
                <span class="iconfont icon-a-Group6768798"></span
                >文献求助QQ群：<span class="bold">943494206</span>
              </p>
              <p>
                <span class="iconfont icon-a-Group6768798"></span
                >测试交流QQ群：<span class="bold">831767101</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- 公众号 -->
        <div class="official_account">
          <div class="img">
            <img :src="`${baseUrl}/static/defaults/gongzhonghao.png`" alt="" />
          </div>
          <div class="text">学研汇公众号</div>
        </div>
        <!-- 小程序 -->
        <!-- <div class="applets">
          <div class="img"></div>
          <div class="text">学研汇小程序</div>
        </div> -->
        <!-- 视频号 -->
        <div class="video_account">
          <div class="img">
            <img :src="`${baseUrl}/static/defaults/shipinghao.png`" alt="" />
          </div>
          <div class="text">学研汇视频号</div>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <div
        class="bottom_item"
        v-for="item in footerList"
        :key="item.name"
        @click="item.fn"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="put_record">
      @2021 学研汇All Rights Reserved 郑州米测信息科技有限公司
      豫ICP备20006655号-1
    </div>
    <complainDialog
      :visible="showComplainDialog"
      @closeDialog="closeDialog"
    ></complainDialog>
  </div>
</template>

<script>
import complainDialog from "@/components/dialogComponents/complainDialog.vue";
export default {
  components: { complainDialog },
  data() {
    return {
      showComplainDialog: false,

      footerList: [
        {
          name: "关于我们",
          fn: () => {
            this.$router.push("/aboutUs");
          },
        },
        // {
        //   name: "新手引导",
        //   fn: () => {
        //     this.$router.push("/guideView");
        //   },
        // },
        {
          name: "用户协议",
          fn: () => {
            this.$router.push("/serviceClause?id=10");
          },
        },
        {
          name: "隐私条款",
          fn: () => {
            this.$router.push("/serviceClause?id=2");
          },
        },
        {
          name: "投诉建议",
          fn: () => {
            this.showComplainDialog = true;
          },
        },
        {
          name: "资质证照",
          fn: () => {
            window.open('https://static.xueyanhui.com/static/config/platform_license.pdf');
          },
        },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.showComplainDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  min-width: 1200px;
  height: 440px;
  background-color: #444444;
  position: relative;
  color: #fff;
  // padding: 40px 360px 78px 360px;
  // padding: 0 360px;
  .footer_content {
    padding: 40px 0 78px 0;
    display: flex;
    justify-content: space-between;

    .left {
      text-align: left;
      min-width: 439px;
      flex-shrink: 0;
      p {
        margin-bottom: 6px;
      }
      .logo_box {
        .text {
          width: 600px;
          font-size: 14px;
          margin-top: 5px;
        }
        img {
          max-width: 200px;
        }
        margin-bottom: 40px;
      }

      .contact {
        display: flex;

        .item {
          // margin-bottom: 24px;
          // margin-right: 85px;
          .item_title {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 12px;
          }
          .item_content {
            p {
              font-size: 14px;
              margin-right: 64px;
              display: flex;
              align-items: center;
              span {
                font-size: 14px;
              }
              .bold {
                font-weight: 700;
                font-size: 20px;
                margin-right: 4px;
              }
              .iconfont {
                font-size: 26px;
                margin-right: 4px;
              }
            }
          }
        }
      }
      .title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
        margin-left: 0;
      }
    }
    .right {
      display: flex;
      padding-top: 72px;
      .official_account,
      .applets,
      .video_account {
        margin-right: 80px;
        .img {
          width: 140px;
          height: 140px;
          background-color: #fff;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          font-size: 20px;
          margin-top: 8px;
        }
      }
      .video_account {
        margin-right: 0;
      }
    }
  }
  .footer_bottom {
    width: 100%;
    border-bottom: 1px solid #ffffff;
    position: absolute;
    bottom: 78px;
    left: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
    .bottom_item {
      font-size: 16px;
      margin-right: 40px;
      cursor: pointer;
    }
  }
  .put_record {
    // height: 72px;
    // line-height: 78px;
    margin-top: 24px;
    font-size: 14px;
  }
}
</style>