import request from "@/api/request";

/**
 * 获取浏览记录
 * @param id
 * @returns {*}
 */
export function getRecords() {
    return request({
        url: `/member/member-records/queryPage?currentPage=1&pageSize=4`,
        method: 'get',
    });
}
/**
 * 添加浏览记录
 * @param id
 * @returns {*}
 */
export function addRecords(data) {
    return request({
        url: `/member/member-records`,
        method: 'post',
        data
    });
}
// 用户申请还款
export function memberRepay() {
    return request({
        url: `/member/member-repayment-bill/repayment`,
        method: 'post',
    });
}
// 获取还款单详情
export function getMemberRepayDetail(sn) {
    return request({
        url: `/member/member-repayment-bill/detail/${sn}`,
        method: 'get',
    });
}