<template>
  <!-- 密码强弱判断条 -->
  <div class="password_stength">
    <div :class="{ weak: classLevel >= 1 }"></div>
    <div :class="{ medium: classLevel >= 2 }"></div>
    <div :class="{ strong: classLevel >= 3 }"></div>
  </div>
</template>

<script>
export default {
  props: ["val"],
  data() {
    return {
      classLevel: 0,
    };
  },
  methods: {},
  watch: {
    val(newVal) {
      if (newVal) {
        if (newVal.length < 8) {
          return (this.classLevel = 0);
        }
        this.classLevel = 0;
        const weakReg = /[0-9]+/;
        const mediumReg = /[a-zA-Z]+/;
        const strongReg =
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+/;

        // 校验是数字
        if (weakReg.test(newVal)) {
          this.classLevel += 1;
        }
        if (mediumReg.test(newVal)) {
          this.classLevel += 1;
        }
        if (strongReg.test(newVal)) {
          this.classLevel += 1;
        }
      }
    },
    // val(newVal) {
    //   var reg1 = /(^\d{6,}$)|(^[a-zA-Z]{6,}$)|(^[^a-zA-Z0-9]{6,}$)/; //数字，字母或符号其中的一种
    //   var reg7 =
    //     /\d*\D*((\d+[a-zA-Z]+[^0-9a-zA-Z]+)|(\d+[^0-9a-zA-Z]+[a-zA-Z]+)|([a-zA-Z]+\d+[^0-9a-zA-Z]+)|([a-zA-Z]+[^0-9a-zA-Z]+\d+)|([^0-9a-zA-Z]+[a-zA-Z]+\d+)|([^0-9a-zA-Z]+\d+[a-zA-Z]+))\d*\D*/; //数字字母字符任意组合
    //   if (reg1.test(newVal)) {
    //     this.classLevel = 1;
    //   } else if (!reg7.test(newVal)) {
    //     this.classLevel = 2;
    //   } else {
    //     this.classLevel = 3;
    //   }
    // },
    immediate: true,
  },
};
</script>

<style lang="less" scoped>
.password_stength {
  width: 100%;
  height: 9px;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 14px;
  div {
    width: 40px;
    height: 100%;
    background-color: #d9d9d9;
    border-radius: 22px;
    margin-left: 6px;
  }
  .weak {
    background-color: #eb5e12;
  }
  .medium {
    background-color: #faad14;
  }
  .strong {
    background-color: #38b865;
  }
}
</style>