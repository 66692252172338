import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/base.css"
import ElementUI from 'element-ui';
import VueSeamlessScroll from "vue-seamless-scroll"
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/fonts/iconfont.css"
import XlButton from "@/components/xyhComps/XlButton.vue"
// 设置图片服务器基准路径
Vue.prototype.baseUrl = "https://static.xueyanhui.com"
Vue.prototype.$mainColor = true
Vue.prototype.$loading = ElementUI.Loading.service;

// 过滤器
import * as filters from '@/tool/filter'
import { teamAuth } from './tool'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.directive('scroll', {
  inserted(el, binding) {
    const scrollHandler = () => {
      const scrollTop = el.scrollTop || document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = el.scrollHeight || document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = el.clientHeight || document.documentElement.clientHeight || document.body.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        binding.value();
      }
    };
    el._vueScrollHandler = scrollHandler;
    el.addEventListener('scroll', scrollHandler);
  },
  unbind(el) {
    el.removeEventListener('scroll', el._vueScrollHandler);
    delete el._vueScrollHandler;
  }
});
Vue.directive('teamAuth', {
  bind: function (el, binding) {
    // 获取传入的字段
    let flag = teamAuth(binding.value)
    if (flag) {
      el.style.display = 'none'
    }
    // const str = binding.value
    // const obj = {
    //   ADMIN: ['TEAM_ACCOUNT_MANAGE', 'TEAM_QUOTA_MANAGE', 'TEAM_MEMBER_PREPAID_REPAYMENT', 'TEAM_QUOTA_MANAGE', 'TEAM_ACCOUNT_MANAGE', 'TEAM_ORDER_NO_CHECK', 'TEAM_ACCOUNT_MONEY_SHOW', 'TEAM_ORDER_SHARE', 'TEAM_PREPAID_RECHARGE'],
    //   MEMBER: ['TEAM_MEMBER_PREPAID_REPAYMENT', 'TEAM_QUOTA_MANAGE', 'TEAM_ACCOUNT_MANAGE', 'TEAM_ORDER_NO_CHECK', 'TEAM_ACCOUNT_MONEY_SHOW', 'TEAM_ORDER_SHARE', 'TEAM_PREPAID_RECHARGE'],
    // }
    // let flag = false
    // let arr = []
    // const { authorizations, myInTeamInfo } = store.state.user.teamInfo
    // const { role } = myInTeamInfo
    // if (authorizations) {
    //   arr = JSON.parse(authorizations)
    // }
    // if (role == 'MASTER') {
    //   return
    // }
    // if (obj[role]) {
    //   if (obj[role].includes(str) && arr.includes(str)) {
    //     flag = true
    //   }
    // }
    // if (!flag) {
    //   el.style.display = 'none'

    // }
  }
})

Vue.config.productionTip = false
Vue.component("XlButton", XlButton)
Vue.use(ElementUI)
Vue.use(VueSeamlessScroll)

export default new Vue({
  router,
  store,
  beforeCreate() {
    //安装事件总线
    Vue.prototype.$EventBus = this
  },
  render: h => h(App)
}).$mount('#app');
