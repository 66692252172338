import { cookieStorage } from "./storage";
import { CookieEnum } from "./enum";
import router from '@/router';
import store from "@/store"
import {getTime, randomString} from "@/tool";
import md5 from "js-md5"

let refreshTokenTime ;

export default function auth(config) {
  return new Promise((resolve) => {
    // 访问Token
    let user = store.state.user.userInfo
    let accessToken = cookieStorage.get(CookieEnum.accessToken)
    if (!config.headers) {
      throw new Error(
          `Expected 'config' and 'config.headers' not to be undefined`
      );
    }
    if (process.env.NODE_ENV === 'production') {
      const uid = user.uid
      const client_id = "member"
      const nonce = randomString(6)
      const timestamp = getTime()
      const sign = md5(`${uid}${nonce}${timestamp}${accessToken}App2022`)
      const _params = { uid,client_id, nonce, timestamp, sign }
      let params = config.params || {}
      params = { ...params, ..._params }
      config.params = params
    } else {
      if (accessToken) {
        config.headers.Authorization = accessToken;
      }
    }
    resolve(config)
  })
}
//更换token加锁阻塞请求
export function convertToken() {
  return new Promise((resolve) => {
    const _window = (window);
    checkRefresh().then(()=>{
      //开启刷新锁,用于阻塞后面的请求
      _window.__refreshTokenLock__ = true
      if (refreshTokenTime&&refreshTokenTime+5000>Date.now()){
        _window.__refreshTokenLock__ = null
        return resolve();
      }
      store.dispatch("user/refreshAccessToken").then((accessToken) => {
        _window.__refreshTokenLock__ = null
        refreshTokenTime = Date.now()
        return resolve(accessToken)
      }).catch((error) => {
        console.error(error, '更换token失败')
        _window.__refreshTokenLock__ = undefined
        let str = `${location.href.split('#')[1]}`
        if (!str.includes('Login')) {
          sessionStorage.setItem("path", str)
        }
        router.push({ name: "Login" })
        store.dispatch("user/resetToken")
      })
    })
  })
}

export function checkRefresh() {
  return new Promise(resolve => {
    const _window = (window);
    /**
     * 如果当前请求存在刷新锁。则阻塞
     */
    if (_window.__refreshTokenLock__){
      // 循环检测刷新token锁，当刷新锁变为null时，说明新的token已经取回。
      const checkLock = () => {
        _window.__refreshTokenTimeOut__ = setTimeout(() => {
          const __RTK__ = _window.__refreshTokenLock__
          if (__RTK__ === undefined) {
            // console.log('登录已失效了，不用再等待了...')
            store.dispatch("user/resetToken"); // 清除浏览器全部缓存
            window.location.replace(location.href.split('#')[0] + `#/login?forward=${location.href.split('#')[1]}`)
            //说明已经取失败了，销毁定时器
            clearTimeout(_window.__refreshTokenTimeOut__)
            return
          }
          if (__RTK__ === null) {
            //说明已经取回，可以销毁定时器
            return resolve()

          } else {
            return checkLock()
          }

        }, 500)
      }
      return checkLock()
    }
    //释放
    return resolve();
  })
}


