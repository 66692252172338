import request from '@/api/request'
// 搜索商品
export function searchVideoList(params) {
    return request({ url: '/member/cloud-classroom/queryPage', method: "get", params })
}
// 根据id获取视频详情
export function getVideoDetailById(id) {
    return request({ url: `/member/cloud-classroom/${id}`, method: "get" })

}
// 获取用户的云课堂vip信息
export function getClassroomVipInfo() {
    return request({ url: `/member/cloud-classroom/getUserVip`, method: "get" })
}
// 获取云卡的配置信息
export function getClassroomCardConfig() {
    return request({ url: `/member/cloud-classroom/vipConfig`, method: "get" })
}
// 购买云卡
export function buyClassroomCard(vipConfigId) {
    return request({ url: `/member/cloud-classroom/submit`, method: "post", params: { vipConfigId } })
}
// 获取云卡订单信息
export function getClassroomOrderInfo(orderSn) {
    return request({ url: `/member/cloud-classroom/order/${orderSn}`, method: "get" })

}
// 获取所有视频分类
export function getCategoryQueryList(params) {
    return request({ url: '/member/cloud-classroom/category/queryList', method: "get", params })
}