<template>
  <div class="wx_login" v-loading="loading">
    <div class="title">微信第三方账号登录</div>
    <div class="line"></div>
    <!-- 扫码 -->
    <div class="wx_code" id="wxLoginCode">
      <div class="title_text">
        <p>使用微信扫一扫</p>
        <p>关注“学研汇”公众号即可登录</p>
      </div>
      <div class="img">
        <img v-if="codeUrl" :src="codeUrl" alt="" />
      </div>
    </div>
    <div class="footer">
      <span class="item" @click="changeType('code')">验证码登录</span>
      <div class="vertical">|</div>
      <span class="item" @click="changeType('account')">密码登录</span>
    </div>
    <!-- 协议 -->
    <div class="agreement">
      <el-checkbox v-model="isAgree" @change="checkboxChange"></el-checkbox>
      <span @click="isAgree = !isAgree"
        >&nbsp;我已阅读并接受以下条款 :<span
          class="clause"
          @click.stop="showAgreement"
          >《学研汇服务条款》</span
        ></span
      >
      <div class="error_tips" v-if="showErrorTip">接受服务协议才能登录</div>
    </div>
  </div>
</template>

<script>
import { reqWxMqLogin } from "@/api/user";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      showErrorTip: false,
      isAgree: true,
      loading: true,
      timer: null,
      timerSecond: 60,
      showSendText: false,
      codeUrl: "",
      ticket: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions("user", ["login"]),
    checkboxChange(value) {
      this.showErrorTip = !value;
    },
    changeType(value) {
      this.$emit("changeType", value);
    },

    init() {
      // 获取微信登录状态码
      // reqWxLogin().then((res) => {
      //   // eslint-disable-next-line no-undef
      //   new WxLogin({
      //     self_redirect: false,
      //     id: "wxLoginCode",
      //     appid: res.appid,
      //     scope: "snsapi_login",
      //     redirect_uri: "http%3A%2F%2Fwww.xueyanhui.com%2F%23%2FLogin",
      //     state: res.state,
      //     style: "",
      //     href: `${this.baseUrl}/css/wxLogin.css`,
      //   });
      //   this.loading = false;
      // });

      reqWxMqLogin().then((res) => {
        this.loading = false;
        const { url, ticket } = res;
        this.codeUrl = url;
        this.ticket = ticket;
        this.timer = setInterval(() => {
          let params = {
            ticket,
            grant_type: "wechat_mq",
          };
          this.login(params)
            .then(() => {
              //   console.log(res, "成功");
              // 查看session中有无记录
              let path = sessionStorage.getItem("path") || "/";
              this.$router.push(path);
              this.$message({
                type: "success",
                message: "登录成功！",
              });
              this.$EventBus.$emit("getUserInfo");
              setTimeout(() => {
                sessionStorage.removeItem("path");
              }, 1000);
              clearInterval(this.timer);
            })
            .catch((error) => {
              if (error.code === 413) {
                // 绑定手机号
                // this.$router.push(
                //   `/bindPhone?type=weChat&wechatState=${state}&code=${code}`
                // );
                clearInterval(this.timer);
                this.$router.push(
                  `/bindPhone?type=weChat&wechatMqTicket=${ticket}`
                );
              }
            });
        }, 500);
      }).catch(()=>{
        this.$message({type:"error",message:"获取参数失败，请使用其他登录方式~"})
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    // "$route.query": {
    //   handler: function (newRoute) {
    //     const { code, state } = newRoute;
    //     if (code && state) {
    //       // 调用登录接口
    //       let params = {
    //         code,
    //         state,
    //         grant_type: "wechat",
    //       };
    //       console.log(params, "sss");
    //       this.login(params)
    //         .then(() => {
    //           //   console.log(res, "成功");
    //           // 查看session中有无记录
    //           let path = sessionStorage.getItem("path") || "/";
    //           this.$router.push(path);
    //           this.$message({
    //             type: "success",
    //             message: "登录成功！",
    //           });
    //           // this.$EventBus.$emit("showInfoDialog");
    //           setTimeout(() => {
    //             sessionStorage.removeItem("path");
    //           }, 1000);
    //         })
    //         .catch((error) => {
    //           if (error.code === 413) {
    //             // 绑定手机号
    //             this.$router.push(
    //               `/bindPhone?type=weChat&wechatState=${state}&code=${code}`
    //             );
    //           }
    //         });
    //     }
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style lang="less" scoped>
.wx_login {
  width: 450px;

  background-color: #fff;
  position: absolute;
  right: 200px;
  top: 90px;
  padding: 0 34px 13px 34px;
  .title {
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 34px;
  }
  .wx_code {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 360px;
    .title_text {
      width: 260px;
      font-size: 16px;
      font-weight: 400;
      color: #171717;
      margin: 40px 0px 12px 0;
      font-size: 16px;
      p {
        margin-bottom: 4px;
      }
    }

    .img {
      width: 180px;
      height: 180px;
      img {
        width: 180px;
        height: 180px;
      }
    }
  }
  .line {
    background-color: #d9d9d9;
    margin: 0;
  }
  .bind_phone {
    padding-top: 40px;
  }
  .footer {
    display: flex;
    justify-content: center;
    color: #696969;
    .item {
      cursor: pointer;
    }
    .vertical {
      margin: 0 8px;
    }
  }
  .bind_phone {
    .code_input {
      width: 160px;
      margin-right: 12px;
    }
    .login_btn {
      width: 100%;
      font-size: 16px;
      height: 58px;
    }
  }
  .agreement {
    width: 100%;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #acacac;
    cursor: pointer;
    text-align: center;
    margin: 24px auto 27px auto;
    position: relative;
    .error_tips {
      position: absolute;
      left: 1px;
      bottom: -18px;
      cursor: default;
    }
    .clause {
      color: #696969;
    }
  }
  /deep/.el-button {
    color: #fff;
    background-color: @main-color;
    height: 58px;
  }
  /deep/.el-form-item__content {
    text-align-last: left;
  }
  /deep/.el-input,
  /deep/.el-input__inner {
    height: 58px;
  }
  /deep/.el-form-item {
    margin-bottom: 18px;
  }
}
</style>