import store from "@/store"
// import { MessageBox } from "element-ui"
import { addRecords, getRecords } from "@/api/member/member"
// import vue from '@/main'
import { postUserCount, rechristenFile } from "@/api/sys"
import { Message } from "element-ui"
let baseURL = process.env.VUE_APP_BASE_URL
import JSZip from 'jszip';
import FileSaver from 'file-saver';
// import vue from '@/main'
import Vue from "vue";
// 转化数字
export const conversionNum = (index) => {
    let arr = [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十"
    ]
    return arr[index]
}
// 校验手机号
export const validatePhoneNumber = (rule, value, callback) => {
    // const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    const reg = /^[1][3-9][0-9]{9}$/;
    if (!reg.test(value)) {
        return callback(new Error("请输入格式正确的11位手机号"));
    }
    return callback();
};

// 校验密码复杂度
export const checkComplexity = (rule, value, callback) => {
    if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\W]{8,20}$/.test(value)) {
        callback();
    } else {
        callback(new Error("密码必须包含字母/数字/符号至少2种"));
    }
};
// 生成随机字符串
export function randomString(length = 32) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const maxPos = chars.length
    let _string = ''
    for (let i = 0; i < length; i++) {
        _string += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return _string
}
//   校验用户是否登录
export function checkLogin() {
    let userInfo = store.state.user.userInfo
    if (userInfo && userInfo.refresh_token) {
        return true
    }
    return false
}
// 校验用户vip
export function isVip() {
    let role = store.state.user.userInfo.role
    return role !== 'BUYER'
}
// 校验用户是否开通云课堂卡
export function isClassroomVip() {
    let classroomVip = store.state.user.userInfo.classroomVip
    return classroomVip
}
// 秒数转为时分秒
export function getVideoTime(seconds) {
    seconds = Number(seconds)
    let hours = Math.floor(seconds / 3600);  // 计算小时数
    let minutes = Math.floor((seconds - hours * 3600) / 60);  // 计算分钟数
    let remainingSeconds = seconds - hours * 3600 - minutes * 60;  // 计算剩余秒数
    if (hours > 0) {
        return `${hours >= 10 ? hours : '0' + hours} : ${minutes >= 10 ? minutes : '0' + minutes} : ${remainingSeconds >= 10 ? remainingSeconds : '0' + remainingSeconds}`
    } else {
        return `${minutes >= 10 ? minutes : '0' + minutes} : ${remainingSeconds >= 10 ? remainingSeconds : '0' + remainingSeconds}`

    }
    // 输出转换后的结果
}
// 字典值转换
export function toValue({ type, value, dictData, unit }) {
    // 为多选
    if (type == "checkbox") {
        let arr = []
        dictData.forEach(i => {
            value.forEach(it => {
                if (i.value == it) {
                    arr.push(i.label)
                }
            })
        })

        return arr.join();
    } else if (type == "select" || type == "radio") {
        let find = dictData.find((i) => i.value == value);

        if (find) {
            return find.label;
        }
    }
    if (unit) {
        return `${value}${unit}`
    }
    return value;
}
export function findDicData(value, dictData, key = 'label') {

    if (!value) {
        return ""
    }
    let find = dictData.find(i => {

        return value == i.value
    })
    if (find) {
        return find[key]
    }
}
// 获取当前时间
export function getTime() {
    let time = sessionStorage.getItem('time')
    if (time) {
        return parseInt((Date.now() / 1000) + "") + Number(time)
    } else {

        // let res = asyncFn(getSysTime);
        // let num = Number(parseInt((res / 1000) + "")) - parseInt((Date.now() / 1000) + "")
        // sessionStorage.setItem('time', num)
        // return parseInt((Date.now() / 1000) + "") + Number(num)
        // 发送请求获取服务器时间
        let xhr = new XMLHttpRequest()
        xhr.open("GET", `${baseURL}/system/time`)
        xhr.send()
        xhr.onreadystatechange = function () {
            if (xhr.responseText) {
                let data = JSON.parse(xhr.responseText)
                let num = Number(parseInt((data.data / 1000) + "")) - parseInt((Date.now() / 1000) + "")
                sessionStorage.setItem('time', num)
                console.log()
                return parseInt((Date.now() / 1000) + "") + Number(num)
            }

        }
    }

}
// function asyncFn(per,oldAsyncData) {
//     let timer
//     let asyncData = null;
//     if(oldAsyncData){
//         return oldAsyncData
//     }
//     if(per){
//         per().then(res => {
//             asyncData = res
//         }).catch(err => {
//             asyncData = err
//         })
//     }
//     timer = setInterval(() => {
//         if (asyncData) {
//             asyncFn(null,asyncData)

//             timer && clearInterval(timer)
//         }
//     }, 300)
// }


// 计算结束时间
export function formatEndTime(endTime) {
    let now = new Date(getTime())
    let end = new Date(endTime)
    let time = end - now
    let day = time / 1000 / 60 / 60 / 24
    if (day >= 1) {
        return Math.floor(day) + '天'
    } else {
        let h = time / 1000 / 60 / 60
        if (h >= 1) {
            return Math.floor(h) + '小时'
        } else {
            let m = time / 1000 / 60
            if (m >= 1) {
                return Math.floor(m) + '分钟'
            } else {
                return "已失效"
            }

        }
    }
}
// 递归寻找子级并清空值
export function recurrenceFn(ids, formListData) {
    formListData.forEach(item => {
        if (item.condition !== 'true') {
            let id = item.condition.split(':')[0]

            if (ids === id) {
                console.log(item.label)
                item.show = false;
                recurrenceFn(item.id, formListData)
            }
        }
    })
}
export function downFile(url, fileName) {

    const file = url.split('/')
    if (!fileName) {
        fileName = file[file.length - 1]

    } else {
        const extension = file[file.length - 1].split('.')[1]
        if (!fileName.includes(`.`)) {
            fileName = `${fileName}.${extension}`
        }
    }
    // 地址不存在时，禁止操作
    if (!url) return;
    // let loadingInstance = vue.$loading({ text: '正在下载文件，请稍等' })
    // 下载文件并保存到本地
    rechristenFile({ fileName, url }).then(res => {
        const link = document.createElement('a');
        link.href = res;
        link.style.display = 'none';
        link.target = '_blank'
        document.body.appendChild(link);
        link.click();
        // loadingInstance.close()
    })

}
// 记录用户浏览
export function addRecord(data) {
    addRecords(data).then(() => {
        getRecords().then(res => {
            store.commit('user/setRecordsInfo', res.records)
        })
    })
}
// 格式化日期
export function dateTimeToString(date) {
    var y = date.getFullYear();
    var M = date.getMonth() + 1;
    var d = date.getDate();
    var H = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    return y + '-' + (M < 10 ? ('0' + M) : M) + '-' + (d < 10 ? ('0' + d) : d) + " " + (H < 10 ? ('0' + H) : H) + ":" + (m < 10 ? ('0' + m) : m) + ":" + (s < 10 ? ('0' + s) : s);
}
// 用户访问统计
export function userCount(module) {
    postUserCount(module)
}
// 根据path找对应的分类id
export function findCategoryId(path) {
    let data = store.state.home.categoryData

    let find = data.find(item => item.path == path)

    if (find) {
        return find.id
    }
}
// 复制文本
export function copyText(text) {
    // 创建一个临时的textarea元素
    const textarea = document.createElement('textarea');
    textarea.value = text;

    // 将textarea元素添加到文档中
    document.body.appendChild(textarea);

    // 选中textarea中的文本内容
    textarea.select();

    // 复制选中的文本到剪贴板
    document.execCommand('copy');
    try {
        Message.success('复制成功！')
    } catch (error) {
        Message.success('复制失败！')
    }
    // 移除临时的textarea元素
    document.body.removeChild(textarea);
}
// 金额保留N位小数
export function priceToFixed(price, n = 2) {
    if (price) {
        return price.toFixed(n)
    } else {
        return 0
    }
}
// 判断用户权限
export function teamAuth(str) {
    // 获取传入的字段
    // const str = binding.value
    const obj = {
        ADMIN: ['TEAM_ACCOUNT_MANAGE', 'TEAM_QUOTA_MANAGE', 'TEAM_MEMBER_PREPAID_REPAYMENT', 'TEAM_QUOTA_MANAGE', 'TEAM_ACCOUNT_MANAGE', 'TEAM_ORDER_NO_CHECK', 'TEAM_ACCOUNT_MONEY_SHOW', 'TEAM_ORDER_SHARE', 'TEAM_PREPAID_RECHARGE'],
        MEMBER: ['TEAM_MEMBER_PREPAID_REPAYMENT', 'TEAM_QUOTA_MANAGE', 'TEAM_ACCOUNT_MANAGE', 'TEAM_ORDER_NO_CHECK', 'TEAM_ACCOUNT_MONEY_SHOW', 'TEAM_ORDER_SHARE', 'TEAM_PREPAID_RECHARGE'],
    }
    let flag = true
    let arr = []
    const { authorizations, myInTeamInfo } = store.state.user.teamInfo
    const { role } = myInTeamInfo
    if (authorizations) {
        arr = JSON.parse(authorizations)
    }
    if (role == 'MASTER') {
        return
    }
    if (obj[role]) {
        if (obj[role].includes(str) && arr.includes(str)) {
            flag = false
        }
    }
    // if (!flag) {
    //     el.style.display = 'none'
    // }
    return flag
}
//回到顶部方法 点击按钮调用
export function backTop(num) {
    // let top = document.documentElement.scrollTop || document.body.scrollTop;

    let dom = document.getElementById("app");
    let top = dom.scrollTop;
    // 实现滚动效果
    const timeTop = setInterval(() => {
        dom.scrollTop = top -= 50;
        if (num) {
            if (top <= num) {
                clearInterval(timeTop); //清除定时器
            }
        }
        if (top <= 0) {
            clearInterval(timeTop); //清除定时器
        }
    }, 10);
}
// 克隆obj,JSON转换字符串
export function parseObjVal(obj, columnList) {
    columnList.forEach(item => {
        let value = obj[item]
        if (value) {
            obj[item] = JSON.parse(value)
        }
    })
    return obj;
}

//克隆obj， 对象转json
export function stringifyObjVal(obj, columnList) {
    let cloneObj = JSON.parse(JSON.stringify(obj));
    columnList.forEach(item => {
        let value = cloneObj[item]
        if (value && typeof value != 'string') {
            cloneObj[item] = JSON.stringify(value)
        }
    })
    return cloneObj;
}
// 金额是否展示待报价
// export function moneyText(price, isWaitOffer, isActivity) {
//     if (isWaitOffer) {
//         return '待报价'
//     } else if (isActivity) {
//         return '待报价'
//     } else if (price && price !== 0) {
//         return `￥${priceToFixed(price)}`
//     } else {
//         return "待报价"
//     }
// }
export function getHashSearchParam(key) {
    const url = location.href
    // 获取 hash 值，不包含 '#' 号
    const hash = url.substring(url.indexOf("#") + 1)
    // 查找 '?' 号所在的索引
    const searchIndex = hash.indexOf("?")
    // '?' 号后面接的是索引参数，如果找到则+1，去除'?' 号
    const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : ""
    // 把搜索参数字符串提过URLSearchParams转成对象形式
    const usp = new URLSearchParams(search)
    // 通过URLSearchParams自带的get方法，查询键所对应的值
    return usp.get(key)
}
export function htmlIsNotNull(html) {
    if (!html) {
        return false;
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    let img = doc.getElementsByTagName("img");
    for (let index = 0; index < img.length; index++) {
        if (img[index].src) {
            return true;
        }
    }
    let p = doc.getElementsByTagName("p");
    for (let index = 0; index < p.length; index++) {
        const element = p[index];
        if (element.textContent) {
            return true;
        }
    }
    return false;

}
// 是否为计算模拟订单
export function isCptOrder(id) {
    return id == '10129' || id == '10121'
}
export function sortBySortField(arr) {
    arr.sort((a, b) => a.sort - b.sort);
    return arr;
}
export async function createZip(fileList, key = 'url', nameKey = 'name', fileName = 'download') {
    let loading = Vue.prototype.$loading({
        lock: true,
        text: '正在打包中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    const zip = new JSZip();


    for (let i = 0; i < fileList.length; i++) {
        const response = await fetch(fileList[i][key]);
        const blob = await response.blob();
        zip.file(`${fileList[i][nameKey] || 'file'}${i + 1}.pdf`, blob);
    }

    // 生成压缩包并提供下载
    zip.generateAsync({ type: 'blob' }).then((content) => {

        FileSaver.saveAs(content, `${fileName}.zip`);
        setTimeout(() => {
            loading.close();
        }, 500);
    });
}