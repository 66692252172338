<template>
  <el-form :model="form" :rules="rules" ref="form" v-bind="option">
    <el-form-item
      v-for="(item, index) in option.column"
      :key="index"
      :prop="item.prop"
      :label="item.label"
    >
      <div v-if="!option.show">
        <!-- 输入框 -->
        <el-col :span="item.span">
          <el-input
            v-if="item.type === 'input'"
            v-bind="item"
            v-model="form[item.prop]"
          />
        </el-col>
        <!-- 文本框 -->
        <el-col :span="item.span">
          <el-input
            v-if="item.type === 'textarea'"
            v-bind="item"
            v-model="form[item.prop]"
          />
        </el-col>
        <!-- 邮箱填写框 -->
        <el-col :span="item.span">
          <el-autocomplete
            v-model="form[item.prop]"
            v-if="item.type == 'email'"
            :trigger-on-focus="false"
            @select="selectEmail($event, item.prop)"
            :fetch-suggestions="querySearch"
            placeholder="请输入邮箱地址"
          >
            <template slot-scope="{ item }">
              {{ item }}
            </template>
          </el-autocomplete>
        </el-col>

        <!-- 单选框 -->
        <el-col :span="item.span">
          <el-radio-group
            v-bind="item"
            v-if="item.type === 'radio'"
            v-model="form[item.prop]"
          >
            <el-radio
              v-for="(itemDic, index) in item.dicData"
              :key="index"
              :label="itemDic.value"
            >
              {{ itemDic.label }}
            </el-radio>
          </el-radio-group>
        </el-col>
        <!-- 多选框 -->
        <el-col :span="item.span">
          <el-checkbox-group
            v-bind="item"
            v-if="item.type === 'checkbox'"
            v-model="form[item.prop]"
          >
            <el-checkbox
              v-for="(itemDic, index) in item.dicData"
              :key="index"
              :label="itemDic.value"
            >
              {{ itemDic.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-col>
        <!-- select选择器 -->
        <el-col :span="item.span">
          <el-select
            v-bind="item"
            v-if="item.type === 'select'"
            v-model="form[item.prop]"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="(itemDic, index) in item.dicData"
              :key="index"
              :label="itemDic.label"
              :value="itemDic.value"
            />
          </el-select>

          <div class="hint" v-if="item.hint">{{ item.hint }}</div>
        </el-col>
      </div>
      <div v-else>
        {{ getDicLabel(item.dicData, form[item.prop]) }}
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    option: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.form = this.value;
    this.option.column.forEach((item) => {
      if (!item.type) {
        item.type = "input";
      }
      //赋值表单
      if (item.value) {
        //值不存在
        if (!this.form[item.prop]) {
          this.$set(this.form, item.prop, item.value);
        }
      } else {
        //值不存在
        if (!this.form[item.prop]) {
          this.$set(this.form, item.prop, "");
        }
      }

      this.rules[item.prop] = [];
      if (item.rules) {
        this.rules[item.prop] = this.rules[item.prop].concat(item.rules);
      }
      if (item.required) {
        if (item.type === "radio" || item.type === "select") {
          this.rules[item.prop].push({
            required: true,
            message: `请选择${item.label}`,
            trigger: "blur",
          });
        } else if (item.type === "upload") {
          this.rules[item.prop].push({
            required: true,
            message: `请上传${item.label}`,
            trigger: "blur",
          });
        } else {
          this.rules[item.prop].push({
            required: true,
            message: `请输入${item.label}`,
            trigger: "blur",
          });
        }
        // console.log(this.rules);
      }
    });
  },
  data() {
    return {
      form: {},
      rules: {},
      emailSuffix: [
        "@qq.com",
        "@163.com",
        "@gmail.com",
        "@hotmail.com",
        "@sina.com",
      ],
    };
  },

  methods: {
    querySearch(queryString, cb) {
      const results = this.emailSuffix.map((i) => {
        if (queryString.includes("@")) {
          return `${queryString.split("@")[0]}${i}`;
        } else {
          return `${queryString}${i}`;
        }
      });

      cb(results);
    },
    selectEmail(value, prop) {
      this.form[prop] = value;
    },
    checkForm() {
      return this.$refs.form.validate();
    },
    getDicLabel(dicData, value) {
      if (dicData) {
        if (Array.isArray(value)) {
          let arrayValue = [];
          value.forEach((item) => {
            arrayValue.push(this.findLabel(dicData, item));
          });
          return arrayValue.join(",");
        } else {
          return this.findLabel(dicData, value);
        }
      } else {
        if (Array.isArray(value)) {
          return value.join(",");
        } else {
          return value;
        }
      }
    },
    findLabel(dicData, value) {
      let find = dicData.find((item) => {
        return item.value === value;
      });
      if (find) {
        return find.label;
      } else {
        return value;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/el-input.less");
.hint {
  color: @main-color;
  font-size: 12px;
  line-height: 12px;
  margin-top: 4px;
}
/deep/.el-form-item__content {
  position: relative;
}
</style>