import axios from 'axios';
import { Message } from 'element-ui'
import auth, { convertToken } from '@/tool/security'
import { Promise } from 'core-js';
import { Loading } from 'element-ui'
import { localStorage } from '@/tool/storage'
import store from '@/store';
import router from '@/router';
// import router from '@/router';

let baseURL = process.env.VUE_APP_BASE_URL
// 创建 axios 实例
const service = axios.create({
    baseURL,
    timeout: 50000,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
});
let whiteList = ['/oauth/token', '/system/sms/code', '/system/time']

// 请求拦截器
service.interceptors.request.use(
    async (config) => {

        /** 配置全屏加载 */
        if (config.loading) {
            config.loading = Loading.service({
                lock: true,
                background: 'rgba(0, 0, 0, 0.7)',
                spinner: 'el-icon-loading',
                text: '请稍候...'
            })
        }
        config.headers['uuid'] = localStorage.getUuid()
        //不在白名单的接口需要鉴权
        if (!whiteList.includes(config.url)) {
            //返回鉴权信息
            const authConfig = auth(config)
            return authConfig;
        } else {
            return config
        }
    },
    error => {
        return Promise.reject(error);
    }
);
// 响应拦截器
service.interceptors.response.use(
    async (response) => {

        await closeLoading(response)
        const { message, data, success, code } = response.data;
        // const { user } = useStore();
        if (success) {
            return data
        } else {
            if (code === 403) {
                //尝试更换token
                return new Promise((resolve) => {
                    return convertToken().then(() => {
                        return resolve(service(response.config))
                    })
                })
            }
            if (code === 505) {
                const { meta: { isCheck } } = router.currentRoute
                if (isCheck) {
                    store.commit('user/clearTeamInfo')
                    location.replace(location.href.split('#')[0] + `#/myCenter/centerHome`)
                }
            }
            
            if (code === 500 || code === 401 || code === 431) {
                Message({
                    message: message,
                    type: 'error'
                });
            }
            return Promise.reject(response.data)
        }
    },
    async error => {
        await closeLoading(error)
        const { retMessage } = error.response.data;
        let showMessage = retMessage
        switch (error.response.status) {
            //404 为地址不存在
            // case 404:
            // showMessage = '请求地址不存在'
            // break
            // 401 状态为域名白名单校验
            case 401:
                showMessage = '请求域名未授权,请联系管理员进行授权'
                break
            case 429:
                showMessage = '频繁请求'
                break
            //可能存在意外错误，如服务端出现未知错误
            default:
                showMessage = retMessage || '请求错误'
                break
        }
        Message({
            message: showMessage,
            type: 'error'
        });
        return Promise.reject(error.response);
    }
);
/**
 * 关闭全局加载
 * 延迟200毫秒关闭，以免晃眼睛
 * @param target
 */
const closeLoading = (target) => {
    if (!target.config.loading) return true
    return new Promise((resolve) => {
        setTimeout(() => {
            target.config.loading.close()
            resolve()
        }, 200)
    })
}
// 导出 axios 实例
export default service
