import request from "@/api/request";
// 用户创建团队
export function userApplyCreateTeam(data) {
    return request({
        url: `/member/team?regionId=${data.regionId}`,
        method: 'post',
        data
    });
}
// 搜索团队
export function searchTeam(keyword) {
    return request({
        url: `/member/team/queryList?keyword=${keyword}`,
        method: 'get',
    });
}
// 获取邀请码
export function getInvitationCode(teamId) {
    return request({
        url: `/member/team-member/invitationCode`,
        method: 'get',
        params: { teamId }
    });
}
// 加入团队
export function joinInvitationCode(invitationCode) {
    return request({
        url: `/member/team-member/invitationCode`,
        method: 'post',
        params: { invitationCode }
    });
}

// 用户申请加入团队
export function userApplyJoinTeam(teamId, applyRemark) {
    return request({
        headers: { 'Content-Type': 'text/plain' },
        url: `/member/team-member-apply/apply`,
        method: 'post',
        params: { teamId },
        data: applyRemark
    });
}
// 获取我的团队
export function getMyTeam() {
    return request({
        url: `/member/team`,
        method: 'get',
    });
}
// 获取团队成员列表
export function getTeamMember(params) {
    return request({
        url: `/member/team-member/queryPage`,
        method: 'get',
        params
    });
}
// 获取团队成员申请列表
export function getTeamMemberApply(params) {
    return request({
        url: `/member/team-member-apply/queryPage`,
        method: 'get',
        params
    });
}

// 获取团队成员申请列表
export function remind(teamId) {
    return request({
        url: `/member/team/remind`,
        method: 'get',
        params: { teamId }
    });
}


// 获取自己在团队中的角色
export function getTeamRole(teamId) {
    return request({
        url: `/member/team-member/get`,
        method: 'get',
        params: { teamId }
    });
}
// 团长修改团队信息
export function putTeamInfo(data) {
    return request({
        url: `/member/team?regionId=${data.regionId}`,
        method: 'put',
        data
    });
}
// 团队禁用和解禁成员
export function disableMember(params) {
    return request({
        url: `/member/team-member/disable`,
        method: 'put',
        params
    })
}
// 团队设置和取消管理员
export function setAdmin(params) {
    return request({
        url: `/member/team-member/setAdmin`,
        method: 'put',
        params
    })
}
// 团队移除成员
export function removeMember(params) {
    return request({
        url: `/member/team-member/removeMember`,
        method: 'delete',
        params
    })
}
// 团队审核成员
export function applyMember(params) {
    return request({
        url: `/member/team-member/applyCheck`,
        method: 'put',
        params
    })
}

// 成员修改自己的电话是否公布
export function editShowMobile(params) {
    return request({
        url: `member/team-member/showMobile`,
        method: 'post',
        params
    })
}

export function getTeamMemberLike(keyword) {
    return request({
        url: `/member/team-member/keyword/like`,
        method: 'get',
        params: {
            keyword
        }
    })
}



// 成员离开团队
export function memberLeaveTeam(teamId) {

    return request({
        url: `/member/team-member/quit`,
        method: 'delete',
        params: { teamId }
    })
}
// 获取团队订单
export function getTeamOrderList(params) {
    return request({
        url: `/trade/order-test/team/queryPage`,
        method: 'get',
        params
    })
}
// 团长或者管理员审核团队订单
export function checkTeamOrder(data) {
    return request({
        url: `/member/team/orderCheck`,
        method: 'post',
        data
    })
}
// 团长或者管理员开启组员免申请
export function putOrderCheck(params) {
    return request({
        url: `/member/team/orderCheck`,
        method: 'put',
        params
    })
}
// 团长或者管理员开启账户禁用
export function putAccountDisable(params) {
    return request({
        url: `/member/team/disable`,
        method: 'put',
        params
    })
}
// 团队开通研值
export function openTeamQuota(params) {
    return request({
        url: `/member/team/openQuota`,
        method: 'post',
        params
    })
}
// 团队关闭研值
export function closeTeamQuota(params) {
    return request({
        url: `/member/team/closeQuota`,
        method: 'post',
        params
    })
}

// 团队的订单统计
export function getTeamCount(teamId) {
    return request({
        url: `/member/team/countOrder`,
        method: 'get',
        params: { teamId }
    })
}
// 设置团队权限
export function setTeamAuthorizations(data, teamId) {
    return request({
        url: `/member/team/authorizationList`,
        method: 'post',
        data,
        params: { teamId }
    })
}
// 获取个人待还的订单（团队还款的个人订单列表）
export function getMemberWaitRepaymentBill(params) {
    return request({
        url: `/member/team-order/memberWaitRepaymentBill/item`,
        method: 'get',
        params
    })
}
// 获取团队待还的订单（团队还款的团队订单列表）
export function getTeamWaitRepaymentBill(params) {
    return request({
        url: `/member/team-order/teamWaitRepaymentBill/item`,
        method: 'get',
        params
    })
}
// 团队预存还款

export function teamPreRepayment(smsToken, ids) {
    return request({
        url: `/member/team-order/team/prepaid/repayment`,
        method: 'post',
        data: ids,
        params: { smsToken }
    })
}
// 个人预存转团队

export function prepaidPersonToTeam(params) {
    return request({
        url: `/member/team/prepaidPersonToTeam`,
        method: 'post',
        params
    })
}
// 团队更换负责人
export function changeMaster(params) {
    return request({
        url: `/member/team/changeMaster`,
        method: 'post',
        params
    })
}