
import request from '@/api/request'
// 获取首页banner
export function getBanner() {
    return request({
        url: '/home/banner',
        method: 'get',
    });
}
// 获取测试精选
export function getTestSelect() {
    return request({
        url: '/test-instrument/featured',
        method: 'get',
        loading: true
    });
}
// 获取名家讲坛
export function getVideoList(params) {
    return request({
        url: '/home/banner/common',
        method: 'get',
        params
    });
}
// 获取有无广告弹窗
export function getPopup() {
    return request({
        url: '/popup',
        method: 'get',
    })
}
// 获取IF查询数据
export function getIfQueryData(params) {
    return request({
        url: '/home/tool/if',
        // loading:true,
        params: {
            ...params, sorter: 'asc-sort'
        }

    })
}
// 获取分区查询的分类
export function getPartitionCategory() {
    return request({
        url: '/home/tool/group/category',
    })
}
// 根据分区查询的分类name查数据
export function getPartitionData(params) {
    return request({
        url: '/home/tool/group',
        params,
    })
}