import jwt_decode from 'jwt-decode'
import { localStorage, cookieStorage } from '@/tool/storage';
import { login, logout, refresh } from '@/api/user';
import { getUserInfo, getUserIsOpenPay } from '@/api/user';
import { CookieEnum } from '@/tool/enum';
import { getMyTeam, getTeamRole, getTeamCount } from '@/api/member/memberTeam';
import { getClassroomVipInfo } from '@/api/classroom';
let userInfo = localStorage.get("userInfo")
let teamInfo = localStorage.get("teamInfo")
export default {
    state: {
        userInfo: userInfo || {},
        // 用户浏览记录
        recordsInfo: {},
        teamInfo: teamInfo || {}
    },
    mutations: {
        setUserInfo(state, data) {
            state.userInfo = Object.assign({}, state.userInfo, data)
            localStorage.set("userInfo", state.userInfo)
        },
        setTeamInfo(state, data) {
            state.teamInfo = Object.assign({}, state.teamInfo, data)
            localStorage.set("teamInfo", state.teamInfo)
        },
        clearTeamInfo(state) {
            localStorage.remove('teamInfo')
            state.teamInfo = {}
        },
        clearUserInfo(state) {
            localStorage.remove('userInfo')
            state.userInfo = {}
        },
        setRecordsInfo(state, data) {
            state.recordsInfo = data
        },
    },
    actions: {
        async SET_TOKEN({ commit }, { access_token, refresh_token, uid, uuid }) {
            const accessTokenExpires = new Date((jwt_decode(access_token).exp - 60) * 1000)
            const refreshTokenExpires = new Date((jwt_decode(refresh_token).exp - 60) * 1000)
            cookieStorage.set(CookieEnum.accessToken, access_token, { "expires": accessTokenExpires });
            cookieStorage.set(CookieEnum.refreshToken, refresh_token, { "expires": refreshTokenExpires });
            cookieStorage.set(CookieEnum.uid, uid, { "expires": refreshTokenExpires });
            localStorage.set('uuid', uuid)
            // state.userInfo.uid = uid
            // state.userInfo.accessToken = access_token;
            // state.userInfo.refreshToken = refresh_token;
            commit("setUserInfo", { accessToken: access_token, refreshToken: refresh_token, uid, uuid })
        },
        storeGetUserInfo({ commit }) {
            // 获取用户基本信息
            // 获取是否开通平台支付
            // 获取用户团队信息
            // Promise.all([getUserInfo(), getMyTeam(), getUserIsOpenPay()]).then(res => {
            //     const [userInfo, teamInfo, isOpenPay] = res
            //     if (teamInfo.face) {
            //         teamInfo.face = JSON.parse(teamInfo.face)[0]
            //     }
            //     if (teamInfo.id) {
            //         getTeamRole(teamInfo.id).then(info => {
            //             const { role } = info
            //             commit('setUserInfo', { teamRole: role })
            //             commit('setUserInfo', { myInTeamInfo: info })
            //         })
            //     }
            //     commit('setUserInfo', { ...userInfo, teamInfo, isOpenPay })
            //     // console.log(state.userInfo, 'sssss')
            // })
            getUserInfo().then(userInfo => {
                userInfo.regionId = userInfo.cityId
                getUserIsOpenPay().then(isOpenPay => {
                    commit('setUserInfo', { ...userInfo, isOpenPay })
                })
                getClassroomVipInfo().then(({ endTime }) => {
                    let obj = {
                        classroomVipEndTime: endTime,
                        classroomVip: false,
                        isPermanentClassroomVip: false
                    }
                    // 云卡vip是否有效
                    if (Date.now() > new Date(endTime).getTime()) {
                        obj.classroomVip = false
                    } else {
                        obj.classroomVip = true
                    }
                    // 是否未永久云卡
                    if (new Date(endTime).getTime() - Date.now() > 4070908800000) {
                        obj.isPermanentClassroomVip = true
                    }
                    commit('setUserInfo', { ...userInfo, ...obj })

                })
                getMyTeam().then(teamInfo => {
                    if (!teamInfo) {
                        return commit('clearTeamInfo')
                    }
                    // if (teamInfo.face) {
                    //     teamInfo.face = JSON.parse(teamInfo.face)
                    // }
                    commit('setTeamInfo', teamInfo)
                    if (teamInfo.id) {
                        getTeamRole(teamInfo.id).then(info => {
                            commit('setTeamInfo', { myInTeamInfo: info })
                        })

                        getTeamCount(teamInfo.id).then(info => {
                            commit('setTeamInfo', { teamCount: info })
                        })
                    }
                })
            })

        },
        refreshAccessToken({ commit }) {
            return new Promise((resolve, reject) => {
                const refreshToken = cookieStorage.get(CookieEnum.refreshToken);

                if (!refreshToken) {
                    return reject()
                }
                refresh(refreshToken)
                    .then((response) => {
                        const { access_token, refresh_token, uid } = response;
                        const accessTokenExpires = new Date((jwt_decode(access_token).exp - 60) * 1000)
                        const refreshTokenExpires = new Date((jwt_decode(refresh_token).exp - 60) * 1000)
                        cookieStorage.set(CookieEnum.accessToken, access_token, { "expires": accessTokenExpires });
                        cookieStorage.set(CookieEnum.refreshToken, refresh_token, { "expires": refreshTokenExpires });
                        cookieStorage.set(CookieEnum.uid, uid, { "expires": refreshTokenExpires });
                        // state.userInfo.uid = uid
                        // state.userInfo.accessToken = access_token;
                        // state.userInfo.refreshToken = refresh_token;
                        commit("setUserInfo", response)
                        return resolve(access_token);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        /**
         * 登录
         */
        login({ state, commit, dispatch }, loginData) {
            // const { username, password, code, uuid, mobile, grant_type, } = loginData;
            dispatch("resetToken")
            return new Promise((resolve, reject) => {
                login(loginData)
                    .then((response) => {
                        const { access_token, refresh_token, uid } = response;
                        const accessTokenExpires = new Date((jwt_decode(access_token).exp - 60) * 1000)
                        const refreshTokenExpires = new Date((jwt_decode(refresh_token).exp - 60) * 1000)
                        cookieStorage.set(CookieEnum.accessToken, access_token, { "expires": accessTokenExpires });
                        cookieStorage.set(CookieEnum.refreshToken, refresh_token, { "expires": refreshTokenExpires });
                        cookieStorage.set(CookieEnum.uid, uid, { "expires": refreshTokenExpires });

                        commit("setUserInfo", response)
                        console.log(state.userInfo)
                        resolve(access_token);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        /**
         *  获取用户信息（昵称、头像、角色集合、权限集合）
         */
        // getUserInfo() {
        //     return new Promise((resolve, reject) => {

        //         getUserInfo()
        //             .then(({ data }) => {
        //                 if (!data) {
        //                     return reject('Verification failed, please Login again.');
        //                 }
        //                 const { username, face, roles, authorities } = data;
        //                 this.roles = roles;
        //                 this.nickname = username;
        //                 this.avatar = face;
        //                 this.authorities = authorities;
        //                 resolve(data);
        //             })
        //             .catch((error) => {
        //                 reject(error);
        //             });
        //     });
        // },

        /**
         *  注销
         */
        logout({ dispatch }) {
            return new Promise((resolve, reject) => {
                logout()
                    .then(() => {
                        localStorage.clear();
                        dispatch("resetToken")
                        resolve(null);
                        sessionStorage.removeItem('path')
                    })
                    .catch((error) => {

                        localStorage.clear();
                        dispatch("resetToken")
                        sessionStorage.removeItem('path')
                        reject(error);
                    });
            });
        },

        /**
         * 清除 Token
         */
        resetToken({ commit }) {
            return new Promise((resolve) => {
                commit("clearUserInfo")
                commit('clearTeamInfo')
                const uuid = localStorage.getUuid();
                localStorage.clear();
                localStorage.set("uuid", uuid)
                cookieStorage.clear();
                sessionStorage.removeItem('checkSuccess')
                resolve(null);
            });
        },
    },
    namespaced: true
}