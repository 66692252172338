<template>
  <div class="xl-img">
    <img :src="imgUrl" alt="" />
  </div>
</template>

<script>
export default {
  props: ["src", "type"],
  computed: {
    imgUrl() {
      let url = "";
      let srcObj;
      if (Array.isArray(this.src)) {
        srcObj = this.src;
      } else {
        if (isJsonStr(this.src)) {
          srcObj = JSON.parse(this.src);
        } else {
          srcObj = this.src;
        }
      }
      if (Array.isArray(srcObj) && srcObj.length) {
        srcObj.forEach((item) => {
          if (typeof item == "string") {
            url = item;
          } else {
            url = item.url;
          }
        });
      } else if (typeof srcObj == "string") {
        url = srcObj;
      }
      if (!url) {
        if (this.type == "team_avatar") {
          url = "https://static.xueyanhui.com/static/defaults/team_avatar.png";
        } else if (this.type == "user_avatar") {
          url = "https://static.xueyanhui.com/static/defaults/avatar.png";
        }
      }

      return url;
    },
  },
};
function isJsonStr(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
</script>

<style lang="less" scoped>
.xl-img {
  width: 100%;

  img {
    max-width: 100%;
  }
}
</style>