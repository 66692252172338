var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('Topbar'),_c('div',{staticClass:"navbar"},[_c('div',{staticClass:"c"},[_c('div',{staticClass:"logo",on:{"click":function($event){return _vm.$router.push('/')}}},[_c('img',{attrs:{"src":`${_vm.baseUrl}/static/defaults/logo.png`,"alt":""}})]),_c('div',{staticClass:"search_box"},[_c('el-autocomplete',{attrs:{"popper-append-to-body":false,"fetch-suggestions":_vm.getSearchList,"placeholder":"请输入仪器名称或型号","value-key":"name","value":"id","trigger-on-focus":false},on:{"select":_vm.clickSearchItem},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}},[_c('img',{attrs:{"slot":"prefix","src":require("../assets/imgs/search.png"),"alt":""},slot:"prefix"}),_c('el-button',{staticClass:"search_btn",attrs:{"slot":"append"},on:{"click":_vm.toSearchResult},slot:"append"},[_vm._v("搜索")])],1)],1),_c('div',{staticClass:"demand_btn",on:{"click":function($event){return _vm.$router.push('/preStorageStatic')}}},[_c('img',{attrs:{"src":require("@/assets/imgs/homeIcon/yucun.png"),"alt":""}})]),_c('message-box')],1)]),_c('div',{staticClass:"tabBar"},[_c('div',{staticClass:"tabBar_header"},[_c('div',{staticClass:"c"},_vm._l((_vm.viewList),function(item,index){return _c('div',{key:index,staticClass:"tabBar_item",class:{
            activeItem:
              _vm.$route.path.includes(item.path) ||
              _vm.$route.path.includes(item.path2),
            show_drawer: item.compsName == 'category',
          },on:{"click":function($event){return _vm.switchTab(item)},"mouseover":function($event){return _vm.tabMouseover(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"drawer",class:{ show_drawer: _vm.showDrawer },on:{"mouseover":_vm.drawerMouseover,"mouseout":_vm.mouseout}},[_c('div',{staticClass:"content c"},[_c('div',{staticClass:"list"},_vm._l((_vm.drawerData),function(item){return _c('div',{key:item.id,staticClass:"list_item"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"parent_node"},[_c('i',{class:[
                      'iconfont',
                      item.icon && item.icon.replace('#', ''),
                    ]}),_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"right"},_vm._l((item.children),function(i,ix){return _c('div',{key:ix,staticClass:"tag_item",on:{"click":function($event){return _vm.toPath(i)}}},[_vm._v(" "+_vm._s(i.name)+" ")])}),0)])}),0)])])]),_c('router-view')],1),_c('Footer'),_c('RightBar'),(_vm.showDrawer)?_c('div',{staticClass:"mask",on:{"click":function($event){_vm.showDrawer = false}}}):_vm._e(),(false)?_c('elementsPeriodicTable',{attrs:{"multiple":true},on:{"choiceEle":_vm.choiceEle}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }