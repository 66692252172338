//手机号过滤器
const phoneFilter = (val) => {
    if (!val) return
    let reg = /^(.{3}).*(.{4})$/;
    return val.replace(reg, "$1****$2");
}
//邮箱过滤器
const mailboxFilter = (val) => {
    if (!val) return
    let reg = /^(.{3}).*(.{9})$/;
    return val.replace(reg, "$1****$2");
}
// 姓名保留最后一个字
const nameFilter = (val) => {
    if (!val) return
    let len = val.length
    let str = val[len-1].padStart(len,"*")
    return str
}
// 身份证号只保留头尾
const idNumberFilter = (val) => {
    if (!val) return
    let len = val.length
    let start = val[0]
    let end = val[len - 1]
    let str = start.padEnd(len - 1,"*") + end
    return str
}

export { phoneFilter, mailboxFilter, nameFilter, idNumberFilter }