
import { v4 as uuidv4 } from 'uuid';
import { getClient } from "@/plugin/ali/";
import { getFileStorageConfig } from '@/plugin/ali'

export default {
    actions: {
        upload(state, { uploadFile, scene }) {
            return getFileStorageConfig().then(data => {

                const { accessKeyId, accessKeySecret, bucket, endpoint, regionId, securityToken } = data
                const client = getClient({
                    region: regionId,
                    endpoint: endpoint,
                    stsToken: securityToken,
                    accessKeyId: accessKeyId,
                    accessKeySecret: accessKeySecret,
                    bucket: bucket,
                    cname: true
                })
                return client.put("static/" + scene + "/" + uuidv4().replaceAll("-", "") + "." + uploadFile.name.substring(uploadFile.name.lastIndexOf(".") + 1), uploadFile).then(({ url }) => {
                    return { url, name: uploadFile.name }
                })
            })
        }
    },
    namespaced: true
};
