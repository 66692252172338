<!-- 投诉弹窗 -->
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="closeDialog"
    class="complain_dialog"
    width="620px"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <div class="body">
      <div class="header" slot="title">
        <span class="blue_text">投诉与建议</span>
      </div>
      <div class="content_box">
        <div class="text">
          感谢您对学研汇的支持!我们将认真对待每一条投诉、建议与意见反馈，以为您提供更好的技术服务。
        </div>
        <div class="item">
          <div class="label">1.内容描述<span>*</span></div>
          <div class="content">
            <el-input
              type="textarea"
              resize="none"
              v-model="formData.complainMessage"
            ></el-input>
          </div>
        </div>
        <div class="item upload_item">
          <div class="label">2.上传附件</div>
          <div class="content">
            <div class="upload">
              <el-upload
                action=""
                :http-request="fileUpload"
                :file-list="formData.accessory"
                :on-remove="removeFile"
                accept="image/jpg,image/jpeg,image/png"
              >
                <div class="upload_box">
                  <img src="@/assets/imgs/upload.png" alt="" />
                </div>
                <!-- <el-button size="small" type="primary">上传图片</el-button> -->
              </el-upload>
            </div>
            <span class="tips"
              >选填。 <br />
              文件格式：图片（JPG、JPEG、PNG）、<br />
              Office（word、PPT、EXCEL）、PDF或压缩包；<br />
              文件大小：不超过20M</span
            >
          </div>
        </div>
        <div class="item">
          <div class="label">3.联系方式</div>
          <div class="content">
            <el-input v-model="formData.mobile"></el-input>
            <div class="tips">
              选填。请确保电话或邮箱信息准确，以便第一时间为您提供反馈！
            </div>
          </div>
        </div>

        <div class="btn_box">
          <el-button class="submit_btn" :loading="loading" @click="submit"
            >提交</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>
    
    <script>
import { mapActions } from "vuex";
import { postComplain } from "@/api/user";
export default {
  props: ["visible"],
  data() {
    return {
      dialogVisible: false,
      loading: false,
      formData: {
        complainMessage: "",
        mobile: "",
        accessory: [],
      },
    };
  },
  methods: {
    ...mapActions("upload", ["upload"]),
    removeFile(file) {
      // console.log(file, fileList);
      let index = this.formData.accessory.findIndex((i) => i.url == file.url);
      if (index != -1) {
        this.formData.accessory.splice(index, 1);
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    fileUpload(files) {
      const { size } = files.file;
      if (size > 20 * 1048576) {
        return this.$message({
          type: "error",
          message: "请勿上传超过20MB的文件！",
        });
      }
      this.upload({ uploadFile: files.file, scene: "complain" }).then((res) => {
        const { url, name } = res;
        this.formData.accessory.push({ url, name });
      });
    },
    submit() {
      if (this.formData.complainMessage) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.formData));
        data.accessory = JSON.stringify(data.accessory);
        postComplain(data)
          .then(() => {
            this.$message({ type: "success", message: "反馈成功！" });
            (this.formData = {
              complainMessage: "",
              mobile: "",
              accessory: [],
            }),
              this.closeDialog();
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$message({ type: "error", message: "请填写反馈/建议留言！" });
      }
    },
  },
  beforeDestroy() {
    this.formData = {
      complainMessage: "",
      mobile: "",
      accessory: [],
    };
  },
  watch: {
    visible(newValue) {
      this.dialogVisible = newValue;
    },
    immediate: true,
  },
};
</script>
    
<style lang="less" scoped>
.body {
  z-index: 99999;
  .header {
    width: 100%;
    text-align: center;
    padding: 20px 40px;
    color: @main-color;
    font-weight: 700;
    font-size: 22px;
  }

  .content_box {
    padding: 0 40px;
    color: #545454;
    text-align: left;

    .text {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
      text-align: left;
      text-indent: 1em;
    }
    .item {
      margin-bottom: 24px;
      display: flex;
      .label {
        margin-bottom: 10px;
        color: #545454;
        flex-shrink: 0;
        min-width: 106px;
        font-size: 20px;
        span {
          color: red;
        }
      }
      .content {
        flex: 1;
        margin-left: 24px;
        .upload {
          max-width: 150px;
        }
        /deep/.el-upload {
          margin: 0 auto;
        }
        span {
          font-size: 22px;
        }
        .tips {
          font-size: 12px;
          color: #4a4a4a;
          margin-top: 12px;
          flex-shrink: 0;
        }
      }
    }
    .upload_item {
      .content {
        display: flex;
        .upload_box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 100px;
          border-radius: 4px;
          border: 1px solid #adadad;
        }
        .tips {
          margin-left: 12px;
          line-height: 1.5;

          margin-top: 0;
        }
      }
    }
  }
  .btn_box {
    text-align: center;
    .submit_btn {
      font-size: 20px;
    }
  }
}

/deep/.el-dialog__header {
  padding: 0;
}
/deep/.el-dialog__body {
  padding: 0;
  padding-bottom: 40px;
}
/deep/.el-textarea {
  .el-textarea__inner {
    height: 100px;
    border: 1px solid #adadad;
  }
}
/deep/.el-input {
  width: 400px;
  .el-input__inner {
    border: 1px solid #adadad;
  }
}
</style>