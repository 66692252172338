
import request from '@/api/request'
import { getTime, randomString } from "@/tool";
import md5 from "js-md5"
import { localStorage } from "@/tool/storage";

const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIxMDAwMCIsInJlYWxOYW1lIjoiYWRtaW4iLCJ1c2VyX25hbWUiOiJhZG1pbiIsInNjb3BlIjpbImFsbCJdLCJleHAiOjE2NzcxMjExMDQsInN1cGVyQWRtaW4iOnRydWUsImF1dGhvcml0aWVzIjpbIlJPT1QiXSwianRpIjoiOHhQMWhmdkpBcE9XZ19pUGRWUXFaTUMtVEgwIiwiY2xpZW50X2lkIjoibWFuYWdlciIsInVzZXJuYW1lIjoiYWRtaW4ifQ.PTlpvmd-x7QJy4Bq2nFmeJKJCZm2Tz0TePq62cHh_FfdxbBCW2gQnDLT3hkju6kQgf3FPC1PKTnIlyjNAYFoCH-QBJ-dB-9zKiNdasXs2BfDI6AT6CpQ8yZPQQz86PlGpgRBTOzbCvZ8GSYOWNglYO79jCHMEZZ';

// 获取阿里云oss令牌
export function getStsToken() {
    return request({
        url: '/system/sts/oss/sts',
        method: 'post',

    });
}
//获取快递列表
export function getKuaidiList(name){
    if(name){
        return request({
            url:'/system/sys-kuaidi/queryPage',
            params:{
                operators: 'name-like',
                name,
                currentPage:1,
                pageSize:40,
                sorter:'asc-id'
            }
        })
    }else {
        return request({
            url:'/system/sys-kuaidi/queryPage',
            params:{
                name,
                currentPage:1,
                pageSize:40,
                sorter:'asc-id'
            }
        })
    }
}
// 获取单个字典值
export function getDict(type) {
    return request({
        url: `/system/dict/type`,
        method: 'get',
        skipCheck: true,
        params: { type }
    });
}
//获取服务器当前时间
export function getServiceDate() {
    return request({
        url: `/system/time`,
        method: 'get',
    });
}
// 获取所有字典值
export function getAllDict() {
    return request({
        url: `/system/dict/all`,
        method: 'get',
        skipCheck: true,

    });
}
// 发送验证码
export async function sendCode(params) {
    //添加签名
    const nonce = randomString(6)
    const timestamp = await getTime()
    let uuid = localStorage.getUuid()
    const sign = md5(`${uuid}${params.mobile}${nonce}${timestamp}${md5(token)}App2022`)
    // console.log(`${uuid}${params.mobile}${nonce}${timestamp}${md5(token)}App2022`)
    params.nonce = nonce;
    params.timestamp = timestamp;
    params.sign = sign;
    return request({
        url: '/system/sms/code',
        method: "get",
        skipCheck: true,
        params
    })
}

// 校验验证码
export function verificationCode(params) {
    return request({
        url: '/system/sms/verificationCode',
        method: "post",
        params
    })
}
// 获取地区信息
export function getAreaOption(parentId) {
    return request({
        url: `/system/regions/${parentId}`,
        method: "get",
    })
}
// 获取学校信息
export function getSchoolOption(cityId) {

    return request({
        url: `/system/school/${cityId}`,
        method: "get",
    })
}
// 获取会员的额度配置
export function getMemberQuota() {
    return request({ url: "/system/config/quota" })
}
// 用户访问统计
export function postUserCount(module) {
    return request({ url: '/system/sss', params: { module } })
}
// 获取系统配置
export function getSysSet(key) {
    return request({ url: `/system/setting/${key}` })
}
// 获取系统时间
export function getSysTime() {
    return request({ url: `/system/time` })

}
// 重命名下载文件
export function rechristenFile(data) {
    return request({ url: `/system/download/rechristen`, data, method: 'post' })

}
// 获取视频播放权限
export function getVideoPlayAuth(id) {
    return request({ url: `/system/getVideoPlayAuth`, params: { id }, method: 'post' })
}
