import { getStsToken } from "@/api/sys";
const OSS = require('ali-oss')
export const getClient = (params) => {
  //oss 基本配置
  var client = new OSS(params)
  return client;
}
export const getFileStorageConfig = () => {
  const config = localStorage.getItem('fileStorage');
  const fileStorageConfig = JSON.parse(config);
  if (fileStorageConfig && new Date().getTime() / 1000 < Number(fileStorageConfig.expiration)) {
    return new Promise((resolve) => {
      return resolve(fileStorageConfig)
    });
  } else {
    return getStsToken().then(data => {
      localStorage.setItem('fileStorage', JSON.stringify(data))
      return data
    })
  }
}