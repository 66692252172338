<!-- 注册页 -->
<template>
  <div class="register">
    <div class="logo_box">
      <div class="logo" @click="$router.push('/')">
        <img :src="`${baseUrl}/static/defaults/logo.png`" alt="" />
      </div>
      <span class="vertical">|</span>
      <span>欢迎{{ logoText }}</span>
    </div>
    <div class="banner">
      <div class="img">
        <img :src="`${baseUrl}/static/defaults/register_banner.png`" alt="" />
      </div>
      <div class="form_box">
        <div class="title_text">{{ titleText }}</div>
        <!-- 第一步表单 -->
        <div class="step_one" v-if="step == 1">
          <CodeForm
            ref="codeForm"
            class="codeForm"
            @fromCheckSuccess="fromCheckSuccess"
            @changeValue="changeValue"
            :scene="scene"
            :type="type"
          ></CodeForm>
          <!-- 下一步按钮 -->
          <div
            class="next_btn"
            @click="nextStep"
            :class="disabled ? 'btn_disable' : ''"
          >
            下一步
          </div>
          <!-- 已有账号，点击登录 -->
          <div class="existing_account" v-if="type === 0">
            已有账号，<span @click="$router.push('/Login')">马上登录</span>
          </div>
          <div class="agreement" v-if="type === 0">
            <el-checkbox
              v-model="isAgree"
              @change="checkboxChange"
            ></el-checkbox>
            <span @click="isAgree = !isAgree"
              >&nbsp;我已阅读并接受以下条款 :<span
                class="clause"
                @click.stop="showAgreement"
                >《学研汇服务条款》</span
              ></span
            >
            <div class="error_tips" v-if="showErrorTip">
              接受服务协议才能登录
            </div>
          </div>
        </div>
        <!-- 注册第二步 -->
        <div class="register_step_two" v-if="type === 0 && step == 2">
          <el-form
            :model="userInfoData"
            :rules="userInfoRules"
            ref="userInfoForm"
            label-width="125px"
          >
            <!-- 填写姓名 -->
            <!-- <el-form-item prop="nickname" label="姓名">
              <el-input
                v-model="userInfoData.nickname"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item> -->
            <!-- 选择身份 -->
            <!-- <el-form-item prop="identity" label="身份">
              <el-select
                v-model="userInfoData.identity"
                placeholder="请选择身份"
              >
                <el-option value="学生" label="学生"></el-option>
                <el-option value="企业" label="企业"></el-option>
                <el-option value="医院" label="医院"></el-option>
                <el-option value="教职工" label="教职工"></el-option>
              </el-select>
            </el-form-item> -->
            <!-- 根据身份展示不同表单 -->
            <!-- <el-form-item
              prop="unit"
              label="学校/学院"
              v-if="
                userInfoData.identity == '学生' ||
                userInfoData.identity == '教职工'
              "
            >
              <el-input
                v-model="userInfoData.unit"
                placeholder="请输入学校名称"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="unit"
              label="企业名称"
              v-else-if="userInfoData.identity == '企业'"
            >
              <el-input
                v-model="userInfoData.unit"
                placeholder="请输入企业名称"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="unit"
              label="医院名称"
              v-else-if="userInfoData.identity == '医院'"
            >
              <el-input
                v-model="userInfoData.unit"
                placeholder="请输入医院名称"
              ></el-input>
            </el-form-item> -->
            <!-- 填写邮箱 -->
            <!-- <el-form-item prop="email" label="邮箱">
              <el-autocomplete
                v-model="userInfoData.email"
                :trigger-on-focus="false"
                @select="selectEmail"
                :fetch-suggestions="querySearch"
                placeholder="请输入邮箱地址"
              >
                <template slot-scope="{ item }">
                  {{ item }}
                </template>
              </el-autocomplete>
            </el-form-item> -->

            <el-form-item prop="password" label="设置密码">
              <el-input
                v-model="userInfoData.password"
                type="password"
                autocomplete="off"
                show-password
                placeholder="8-20位密码，字母/数字/符号至少2种"
              ></el-input>
            </el-form-item>
            <!-- 密码强度显示条 -->
            <PwdStrength
              :val="userInfoData.password"
              v-show="userInfoData.password"
            ></PwdStrength>
            <el-form-item prop="enterPassword" label="确认密码">
              <el-input
                v-model="userInfoData.enterPassword"
                type="password"
                autocomplete="off"
                show-password
                placeholder="请再次输入密码，确认密码"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="step_btn_box">
            <div class="prev_step_btn" @click="prevStep">上一步</div>

            <div
              class="next_step_btn"
              @click="nextStep"
              :class="stepTwoDisabled ? 'btn_disable' : ''"
            >
              注册
            </div>
          </div>
        </div>
        <!-- 注册第三步 -->
        <div class="register_step_three" v-if="type === 0 && step == 3">
          <div class="success_title">
            <img src="@/assets/imgs/success.png" alt="" />
            <div class="text">恭喜您！注册成功</div>
          </div>
          <div class="success_content">
            <div class="left">
              <img
                :src="`${baseUrl}/static/defaults/gongzhonghao.png`"
                alt=""
              />
              <p>关注公众号 了解学研汇最新动态</p>
            </div>
            <div class="right">
              <img src="@/assets/imgs/pop.png" alt="" />
            </div>
          </div>
          <div class="four_btn_box">
            <!-- <el-button class="submit_btn" @click="step = 4">我知道了</el-button> -->
            <el-button class="submit_btn" @click="toView('/applyVip')"
              >0元下单，先测后付（加入会员）</el-button
            >
            <el-button class="submit_btn2" @click="toView('/')">暂不</el-button>
          </div>
        </div>
        <!-- 注册第四步 -->
        <div class="register_step_three" v-if="false">
          <div class="success_title">
            <img src="@/assets/imgs/success.png" alt="" />
            <div class="text">恭喜您！注册成功</div>
          </div>
          <div class="success_content">
            <img src="@/assets/imgs/joinVip.png" alt="" />
          </div>
          <div class="four_btn_box">
            <el-button class="submit_btn" @click="toView('/applyVip')"
              >0元下单，先测后付（加入会员）</el-button
            >
            <el-button class="submit_btn2" @click="toView('/')">暂不</el-button>
          </div>
        </div>
        <!-- 重置密码第二步 -->
        <div class="reset_step_two" v-if="type === 1 && step == 2">
          <el-form
            :model="resetFormData"
            :rules="resetRules"
            ref="resetForm"
            label-width="100px"
          >
            <el-form-item prop="password">
              <span class="label" slot="label">新密码</span>

              <el-input
                v-model="resetFormData.password"
                show-password
                autocomplete="off"
                placeholder="请输入新密码"
              ></el-input>
            </el-form-item>
            <!-- 密码强度显示条 -->
            <PwdStrength
              :val="resetFormData.password"
              v-show="resetFormData.password"
            ></PwdStrength>
            <el-form-item prop="enterPassword">
              <span class="label" slot="label">确认密码</span>

              <el-input
                v-model="resetFormData.enterPassword"
                autocomplete="off"
                show-password
                placeholder="请再次输入密码，确认密码"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="step_btn_box">
            <div class="prev_step_btn" @click="prevStep">上一步</div>

            <div
              class="submit_btn"
              @click="nextStep"
              :class="stepTwoDisabled ? 'btn_disable' : ''"
            >
              提交
            </div>
          </div>
        </div>
        <div class="reset_step_three" v-if="type === 1 && step == 3">
          <div class="success_title">
            <img src="@/assets/imgs/success.png" alt="" />
            <div class="text">重置密码成功</div>
          </div>
          <div class="btn_box">
            <el-button class="submit_btn" @click="$router.push('/Login')"
              >返回登录</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import CodeForm from "@/components/CodeForm.vue";
import PwdStrength from "@/components/PwdStrength.vue";
import { checkComplexity } from "@/tool";
import { mapActions } from "vuex";
import { verificationCode } from "@/api/sys";
import { register, editPassword } from "@/api/user";
export default {
  name: "Register",
  components: {
    CodeForm,
    PwdStrength,
  },

  data() {
    // 校验注册填写密码
    const checkPassword = (rule, value, callback) => {
      if (value !== this.userInfoData.password) {
        return callback(new Error("请与新密码保持一致"));
      }
      callback();
    };
    // 校验重置密码
    const checkPassword2 = (rule, value, callback) => {
      if (value !== this.resetFormData.password) {
        return callback(new Error("请与新密码保持一致"));
      }
      callback();
    };
    return {
      formData: {},
      type: "",
      step: 1,
      smsToken: "",
      showErrorTip: false,
      disabled: false,
      isAgree: false,
      emailSuffix: [
        "@qq.com",
        "@163.com",
        "@gmail.com",
        "@hotmail.com",
        "@sina.com",
      ],
      userInfoData: {
        // realName: "",
        // nickname: "",
        // identity: "",
        // unit: "",
        // email: "",
        password: "",
        enterPassword: "",
      },
      userInfoRules: {
        // realName: [
        //   { required: true, message: "姓名不能为空", trigger: "blur" },
        // ],
        // nickname: [
        //   { required: true, message: "姓名不能为空", trigger: "blur" },
        // ],
        // identity: [{ required: true, message: "请选择身份", trigger: "blur" }],
        // unit: [{ required: true, message: "请输入内容", trigger: "blur" }],
        // email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 8,
            max: 20,
            message:
              "建议至少使用字母、数字、符号两种类型组合的密码，长度至少为8位。",
            trigger: "blur",
          },
          { validator: checkComplexity, trigger: "blur" },
        ],
        enterPassword: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { validator: checkPassword, trigger: "blur" },
        ],
        // mail: [{ required: true, message: "邮箱不能为空", trigger: "blur" }],
      },
      resetFormData: {
        password: "",
        enterPassword: "",
      },
      resetRules: {
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 8,
            max: 20,
            message:
              "建议至少使用字母、数字、符号两种类型组合的密码，长度至少为8位。",
            trigger: "blur",
          },
        ],
        enterPassword: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { validator: checkPassword2, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    logoText() {
      return this.type === 0 ? "注册" : "登录";
    },
    titleText() {
      return this.type === 0 ? "注册账号" : "重置密码";
    },
    scene() {
      return this.type === 0 ? "REGISTER" : "UPDATE_PASSWORD";
    },
    stepTwoDisabled() {
      let arr =
        this.type === 0
          ? Object.values(this.userInfoData)
          : Object.values(this.resetFormData);
      // console.log(arr);
      return arr.filter((item) => item).length == arr.length;
    },
  },
  created() {
    this.type = Number(this.$route.query.type);
  },

  methods: {
    ...mapActions("user", ["login"]),
    toView(path) {
      this.$router.push(path);
      // this.$router.go(0);
    },
    querySearch(queryString, cb) {
      const results = this.emailSuffix.map((i) => {
        if (queryString.includes("@")) {
          return `${queryString.split("@")[0]}${i}`;
        } else {
          return `${queryString}${i}`;
        }
      });

      cb(results);
    },
    nextStep() {
      if (this.step == 1) {
        this.$refs.codeForm.checkForm();
        return;
      }
      if (this.step == 2 && this.type === 0) {
        // 为注册第二步
        this.$refs.userInfoForm.validate((check) => {
          if (check) {
            //提交注册接口
            register(
              Object.assign(this.userInfoData, this.formData),
              this.smsToken
            ).then(() => {
              // 跳转第三步
              this.step++;
              this.login({
                username: this.formData.mobile,
                password: this.userInfoData.password,
                grant_type: "password",
              }).then(() => {
                this.$message({
                  type: "success",
                  message: "登录成功！",
                });
                this.$EventBus.$emit("getUserInfo");
              });
            });
          }
        });
      }
      if (this.step == 2 && this.type === 1) {
        // 为重置密码第二步
        this.$refs.resetForm.validate((check) => {
          if (check) {
            // 发送修改密码请求
            editPassword({
              ...this.formData,
              ...this.resetFormData,
              smsToken: this.smsToken,
            }).then(() => {
              this.$message({ type: "success", message: "密码重置成功！" });
              this.step++;
            });
          }
        });
      }
    },
    prevStep() {
      this.userInfoData.password = "";
      this.userInfoData.enterPassword = "";
      this.step -= 1;
      this.isAgree = false;
    },
    fromCheckSuccess(formdata) {
      // 组件校验成功     // 在这里发送注册请求
      console.log(formdata);
      if (this.type === 0) {
        // 注册需勾选协议
        if (!this.isAgree) {
          return (this.showErrorTip = true);
        }
      }
      this.formData.scene = this.scene;
      verificationCode(this.formData).then((token) => {
        this.step += 1;
        this.smsToken = token;
      });
    },
    // codeform组件中的值改变事件
    changeValue(value, form) {
      // 两个表单都有值就解开按钮限制
      this.formData = form;
      console.log(form);
      this.disabled = value;
    },
    checkboxChange(value) {
      this.showErrorTip = !value;
    },
    showAgreement() {
      this.$router.push("/serviceClause?id=1");
    },
    selectEmail(value) {
      this.userInfoData.email = value;
    },
  },
};
</script>
  
  <style lang="less" scoped>
.register {
  /deep/.el-input,
  /deep/.el-input__inner {
    height: 58px;
  }
  /deep/.el-select,
  .el-autocomplete {
    width: 100%;
  }
  /deep/.el-form-item__label {
    height: 58px;
    line-height: 58px;
    font-size: 18px;
    color: #696969;
  }
  .btn_disable {
    color: #fff !important;
    background-color: @main-color !important;
  }
  .logo_box {
    display: flex;
    margin-bottom: 40px;
    .logo {
      cursor: pointer;
      img {
        max-width: 200px;
      }
    }
    margin-left: 200px;
    padding-top: 40px;
    font-size: 36px;
    color: #171717;
    font-weight: 700;

    .vertical {
      margin: 0 20px;
    }
  }
  .banner {
    width: 100%;
    height: 800px;
    position: relative;
    .img {
      width: 100%;
      height: 100%;
      z-index: 1;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .form_box {
      z-index: 2;
      width: 900px;
      background-color: #fff;
      border-radius: 4px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .label {
        display: inline-block;
        height: 100%;
        font-size: 18px;
        color: #696969;
      }
      .title_text {
        height: 75px;
        line-height: 75px;
        background-color: @main-color;
        color: #fff;
        font-size: 24px;
      }

      .step_one {
        padding: 40px 227px;
        .next_btn {
          width: 382px;
          height: 58px;
          line-height: 58px;
          font-style: 20px;
          background-color: #f4f4f4;
          color: #acacac;
          margin-left: 80px;
          border-radius: 4px;
          cursor: pointer;
          margin-bottom: 18px;
        }

        .existing_account {
          color: #acacac;
          font-size: 12px;
          text-align: right;
          margin-bottom: 36px;
          span {
            color: #000;
            cursor: pointer;
          }
        }
        .agreement {
          width: 100%;
          height: 18px;
          font-size: 12px;
          color: #acacac;
          text-align: left;
          margin-left: 80px;
          cursor: pointer;
          .error_tips {
            cursor: default;
          }
          .clause {
            color: #696969;
          }
        }
      }
      .step_btn_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 85px;
        .prev_step_btn,
        .next_step_btn {
          width: 175px;
          border-radius: 4px;
          height: 58px;
          line-height: 58px;
          color: #8c8c8c;
          cursor: pointer;
        }
        .submit_btn {
          width: 175px;
          height: 58px;
          margin-left: 0;
          font-size: 20px;
        }
        .next_step_btn {
          color: #acacac;
          background-color: #f4f4f4;
        }
        .prev_step_btn {
          border: 1px solid #8c8c8c;
        }
      }
      .register_step_two {
        padding: 40px 218px;
      }
      .register_step_three,
      .reset_step_three {
        background-color: #fff;
        .success_title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 32px;
          color: #171717;
          margin-top: 40px;
          margin-bottom: 52px;
          img {
            margin-right: 20px;
          }
        }
        .success_content {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 52px;
          .left {
            margin-right: 34px;
            p {
              color: @main-color;
              font-weight: 700;
              font-size: 18px;
            }
            img {
              width: 180px;
              height: 180px;
              margin-bottom: 20px;
            }
          }
        }
        .submit_btn {
          margin-bottom: 61px;
        }
      }
      .reset_step_two {
        padding: 40px 218px;
      }
      .reset_step_three {
        .success_title {
          height: 192px;
        }
        .btn_box {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .four_btn_box {
    display: flex;
    padding: 0 189px;
    justify-content: center;
    .submit_btn {
      width: auto;
      padding: 0 45px;
      font-size: 20px;
      font-weight: 700;
      margin-right: 20px;
      height: 58px;
    }
    .submit_btn2 {
      height: 58px;
      font-size: 20px;
      border: 1px solid @main-color;
      color: @main-color;
    }
  }
}
</style>