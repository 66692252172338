<!-- 右边悬浮栏 -->
<template>
  <div class="rightbar">
    <!-- 在线客服 -->
    <div class="customer">
      <div
        class="hoverItem"
        @mouseleave="showPopover"
        @mouseenter="showPopover"
      >
        <img src="@/assets/imgs/customer2.png" alt="" />
        <div class="text">在线客服</div>
      </div>
      <!-- 在线客服的popover -->
      <div class="server_content" v-show="showCustomer">
        <div class="title">
          <span class="iconfont icon-dianhua"></span>客服热线
        </div>
        <div class="item">
          <div class="top">
            <div class="dot"></div>
            <div class="text"><span class="bold">测试分析</span>咨询</div>
          </div>
          <div class="bottom">
            
            <div class="text">任老师 182 3716 8930</div>
          </div>
        </div>
        <div class="item">
          <div class="top">
            <div class="dot"></div>
            <div class="text"><span class="bold">理论计算</span>咨询</div>
          </div>
          <div class="bottom">
            <div class="text">黄老师 183 1696 2472</div>
          </div>
        </div>
        <div class="item">
          <div class="top">
            <div class="dot"></div>
            <div class="text"><span class="bold">纳米材料</span>咨询</div>
          </div>
          <div class="bottom">
            <div class="text">张老师 188 7077 4687</div>
          </div>
        </div>
        <div class="item">
          <div class="top">
            <div class="dot"></div>
            <div class="text"><span class="bold">综合事务</span>咨询</div>
          </div>
          <div class="bottom">
            <div class="text">蔡经理 132 9075 5089</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <template v-for="(item, index) in iconList">
        <div
          class="content_item"
          v-if="item.isShow()"
          :key="index"
          @click.stop="item.eventFn"
          @mouseleave="itemHover(item.name)"
          @mouseenter="itemHover(item.name)"
        >
          <span :class="['iconfont', item.icon]"></span>
          <div class="text">{{ item.name }}</div>
        </div>
      </template>
    </div>
    <!-- 回到顶部按钮 -->
    <div class="back_top" v-if="isShowTop" @click="backTop">
      <img src="@/assets/imgs/top.png" alt="" />
      <div class="text">回到顶部</div>
    </div>
  </div>
</template>

<script>
import { userCount, backTop ,checkLogin} from "@/tool";
export default {
  data() {
    return {
      showGZH: false,
      showCustomer: false,
      iconList: [
        {
          name: "个人中心",
          eventFn: () => {
            this.$router.push("/myCenter/centerHome");
          },
          icon: "icon-a-Group5422",
          isShow: () => {
            return true;
          },
        },

        {
          name: "购物车",
          eventFn: () => {
            this.$router.push("/shopCar");
          },
          icon: "icon-caogaoxiang",
          isShow: () => {
            // return this.$route.path == "/nmMaterial";
            return false;
          },
        },
        // {
        //   name: "草稿箱",
        //   eventFn: () => {
        //     this.$router.push("/drafts");
        //   },
        //   icon: "icon-caogaoxiang",
        //   isShow: () => {
        //     // return this.$route.path != "/nmMaterial";
        //     return true;
        //   },
        // },
        {
          name: "通用项目",
          eventFn: () => {
            this.$router.push("/details/1");
            userCount("通用项目");
          },
          icon: "icon-a-Group6132",
          isShow: () => {
            return true;
          },
        },
        {
          name: "IF查询",
          eventFn: () => {
            this.$router.push("/IFqueryView");
            userCount("IF查询");
          },
          icon: "icon-fabuxuqiu",
          isShow: () => {
            return true;
          },
        },
        {
          name: "分区查询",
          eventFn: () => {
            this.$router.push("/partitionQueryView");
            userCount("分区查询");
          },
          icon: "icon-a-Group6768807",
          isShow: () => {
            return true;
          },
        },
        {
          name: "文献求助",
          eventFn: () => {
            this.$router.push("/literatureHelp");
            userCount("文献求助");
          },
          icon: "icon-a-Group6768809",
          isShow: () => {
            return true;
          },
        },
        {
          name: "AI助手",
          eventFn: () => {
            if (!checkLogin()) {
                this.$confirm("登录之后才可以使用AI助手，现在去登录吗？", "提示")
              .then(() => {
                this.$router.push("/Login");
              })
              .catch(() => {
               
              });
          } else{
            window.open('https://ai.xueyanhui.com');
            userCount("AI助手");
          }      
          },
          icon: "icon-a-Group5771",
          isShow: () => {
            return true;
          },
        },
        // {
        //   name: "投诉建议",
        //   eventFn: () => {
        //     // 显示投诉建议的弹窗
        //     this.showComplainDialog = true;
        //     this.showCustomer = false;
        //     this.showGZH = false;
        //     userCount("投诉建议");
        //   },
        //   icon: "icon-tousuyijian",
        //   isShow: () => {
        //     return true;
        //   },
        // },
        // {
        //   name: "公众号",
        //   eventFn: () => {
        //     // this.$router.push("/");
        //     this.showGZH = !this.showGZH;
        //     this.showCustomer = false;
        //   },
        //   icon: "icon-a-Group5771",
        //   isShow: () => {
        //     return true;
        //   },
        // },
      ],
      isShowTop: false,
    };
  },
  mounted() {
    let that = this;
    window.addEventListener(
      "scroll",
      function () {
        let app = this.document.getElementById("app");
        if (app) {
          let top = Math.floor(app.scrollTop);
          top > 980 ? (that.isShowTop = true) : (that.isShowTop = false);
        }
      },
      true
    );
    this.$EventBus.$on("hidePopover", () => {
      this.hideAll();
    });
  },
  computed: {},
  methods: {
    itemHover(name) {
      if (name == "公众号") {
        this.showGZH = !this.showGZH;
      }
    },
    hideAll() {
      this.showComplainDialog = false;
      this.showGZH = false;
      this.showCustomer = false;
    },
    showPopover() {
      this.showCustomer = !this.showCustomer;
      this.showGZH = false;
    },
    backTop,
    // backTop() {
    //   //回到顶部方法 点击按钮调用
    //   // let top = document.documentElement.scrollTop || document.body.scrollTop;

    //   let dom = document.getElementById("app");
    //   let top = dom.scrollTop;
    //   // 实现滚动效果
    //   const timeTop = setInterval(() => {
    //     dom.scrollTop = top -= 50;
    //     if (top <= 0) {
    //       clearInterval(timeTop); //清除定时器
    //     }
    //   }, 10);
    // },
  },
};
</script>

<style lang="less" scoped>
.rightbar {
  width: 80px;
  position: fixed;
  top: 225px;
  right: 40px;
  font-size: 12px;
  color: #000;
  z-index: 999;
  // 隐藏
  .hide {
    display: none;
  }
  .iconfont {
    font-size: 26px;
  }
  .text {
    font-size: 14px;
  }
  .customer,
  .back_top {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 18px;

    box-shadow: -3px 4px 7px 1px rgba(0, 0, 0, 0.08);
    cursor: pointer;
  }
  .customer {
    color: #fff;
    position: relative;
    box-shadow: -3px 4px 7px 1px rgba(0, 0, 0, 0.08);
    background-color: @main-color;
    .server_content {
      width: 260px;
      background-color: #fff;
      position: absolute;
      left: -270px;
      padding: 20px 0 0 20px;

      box-shadow: -3px 4px 7px 1px rgba(0, 0, 0, 0.08);
      .title {
        color: #171717;
        margin-bottom: 8px;
        .iconfont {
          color: @main-color;
          font-size: 26px;
          margin-right: 7px;
          font-weight: 400;
        }
      }
      .text {
        color: #777777;
        font-size: 16px;
        font-weight: 700;
      }
      .item {
        margin-bottom: 12px;

        .top {
          display: flex;
          align-items: center;
          .dot {
            margin-right: 13px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: @main-color;
          }
          .text {
            color: @main-color;
            font-weight: 400;
          }
        }
        .bottom {
          color: @main-color;

          font-weight: 700;
          font-size: 16px;
          text-align: left;
          margin-bottom: 10px;
          margin-left: 22px;
        }
      }
    }
  }
  // .customer:hover {
  //   .server_content {
  //     display: block;
  //     opacity: 1;
  //   }
  // }
  .content {
    width: 100%;
    background-color: #fff;

    padding: 15px 10px;
    border-radius: 40px;

    box-shadow: -3px 4px 7px 1px rgba(0, 0, 0, 0.08);
    margin-bottom: 18px;
    .content_item {
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #cdcdcd;
      padding: 7px 0 9px 0;
      cursor: pointer;
      color: #8c8c8c;
      position: relative;
      &:hover {
        color: @main-color;
      }
      .popover {
        width: 180px;
        padding: 12px 0;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        position: absolute;
        left: -200px;
        top: -20px;
        img {
          width: 112px;
        }
      }
    }
    .content_item:last-child {
      border: 0;
    }
  }
}
</style>