 
<template>
  <div class="elements-container">
    <div class="elements-main">
      <div class="elements">
        <div
          v-for="(item, index) in elementsTab"
          :key="`${item.sort}${index}`"
          :style="{ backgroundColor: item.color }"
          class="elements-item"
          @click="onSelectElements(item)"
        >
          <div class="elements-item-text">
            {{ item.name }} {{ item.chName }}
          </div>
          <div class="elements-item-sort">
            {{ item.sort }}
          </div>
        </div>
      </div>

      <div class="la-lu" style="margin-top: 20px">
        <div
          v-for="(item, index) in La_Lu"
          :key="`${item.sort}${index}`"
          :style="{ backgroundColor: item.color }"
          class="elements-item"
          @click="onSelectElements(item)"
        >
          <div class="elements-item-text">
            {{ item.name }} {{ item.chName }}
          </div>
          <div class="elements-item-sort">
            {{ item.sort }}
          </div>
        </div>
      </div>

      <div class="ac_lr">
        <div
          v-for="(item, index) in Ac_Lr"
          :key="`${item.sort}${index}`"
          :style="{ backgroundColor: item.color }"
          class="elements-item"
          @click="onSelectElements(item)"
        >
          <div class="elements-item-text">
            {{ item.name }} {{ item.chName }}
          </div>
          <div class="elements-item-sort">
            {{ item.sort }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { elementsTab, La_Lu, Ac_Lr } from "@/assets/js/elementData";
export default {
  props: {
    multiple: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    elementsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    elementsList: function (newVal,) {
      this.selectList = [...newVal];
    },
  },
  data() {
    return {
      elementsTab,
      La_Lu,
      Ac_Lr,
      selectList: [],
    };
  },
  created() {},

  methods: {
    /**点击某一项元素*/
    onSelectElements(item) {
      // 混合元素查询
      let state = true;
      for (let option of this.selectList) {
        if (item.name === option) {
          state = false;
        }
      }
      if (
        state &&
        item.name !== "Ac-Lr" &&
        item.name !== "La-Lu" &&
        item.name
      ) {
        if (this.multiple) {
          this.selectList.push(item.name);
          this.$emit("choiceEle", this.selectList);
        } else {
          this.$emit("choiceEle", item.name);
        }
      }
    },
  },
};
</script>
<style lang='less' scoped>
.elements-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.elements-main {
  margin: 10px auto 0;
  width: 1260px;
  padding: 20px;
  background-color: #fff;

  .elements {
    display: flex;
    flex-wrap: wrap;
  }

  .elements-item {
    width: 64px;
    height: 64px;
    border-radius: 4px;
    margin: 0 6px 6px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease-out;
    cursor: pointer;
  }

  .elements-item:hover {
    transform: scale(1.1);
  }

  .elements-item-text {
    color: #fff;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
  }

  .elements-item-sort {
    color: #fff;
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
  }

  .color {
    color: skyblue;
  }

  .la-lu,
  .ac_lr {
    display: flex;
    align-items: center;
    width: 1050px;
    margin-left: 140px;
  }
}
</style>