import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'
import Test from '../views/test.vue'
import Login from "../views/Login.vue"
// import store from '@/store'
import Register from "../views/Register.vue"
// 引入子页面
import HomeView from "../views/childrenView/Home";
import { userCount } from '@/tool'
import store from '@/store'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: "/home",
    children: [
      // 首页
      {
        path: '/home',
        name: 'homeView',
        component: HomeView,
        meta: { title: '首页', isCount: true },

      },
      // 首页
      {
        path: '/category',
        name: 'categoryView',
        component: () => import(/* webpackChunkName: "about" */ '../views/categoryView/index'),
        meta: { title: '分类', isCount: true },

      },
      // //测试分类
      // {
      //   path: 'testClass',
      //   name: 'testClass',
      //   redirect: "/testClass/allTestClass",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/testClassView'),
      //   children: [
      //     // 全部测试
      //     {
      //       path: 'allTestClass',
      //       name: 'allTestClass',
      //       meta: { title: '全部测试', isCount: true },
      //       component: () => import(/* webpackChunkName: "about" */ '../views/testClassView/allTestClass')
      //     },
      //     // 高端测试
      //     {
      //       path: 'highEndTest',
      //       name: 'highEndTest',
      //       meta: { title: '高端测试', isCount: true },
      //       component: () => import(/* webpackChunkName: "about" */ '../views/testClassView/highEndTest1')
      //     },
      //     // 常规测试
      //     {
      //       path: 'routineTest',
      //       name: 'routineTest',
      //       meta: { title: '常规测试', isCount: true },
      //       component: () => import(/* webpackChunkName: "about" */ '../views/testClassView/routineTest')
      //     },
      //     // 云现场
      //     {
      //       path: 'cloudSite',
      //       name: 'cloudSite',
      //       meta: { title: '云现场', isCount: true },

      //       component: () => import(/* webpackChunkName: "about" */ '../views/testClassView/cloudSite')
      //     },
      //     // 其他测试
      //     {
      //       path: 'otherTest',
      //       name: 'otherTest',
      //       meta: { title: '其他测试', isCount: true },

      //       component: () => import(/* webpackChunkName: "about" */ '../views/testClassView/otherTest')
      //     },
      //     // 数据解析

      //     // {
      //     //   path: 'dataAnalysis',
      //     //   name: 'dataAnalysis',
      //     //   component: () => import(/* webpackChunkName: "about" */ '../views/testClassView/dataAnalysis')
      //     // },
      //   ]
      // },
      // 同步辐射
      {
        path: 'syncRdiation',
        name: 'syncRdiation',
        meta: { title: '同步辐射', isCount: true },

        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/syncRdiationView')
      },
      // 球差电镜
      {
        path: 'acTem',
        name: 'acTem',
        meta: { title: '球差电镜', isCount: true },

        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/acTemView')
      },
      // 原位测试
      {
        path: 'situTest',
        name: 'situTest',
        meta: { title: '原位测试', isCount: true },

        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/situTestView')
      },
      // 纳米材料
      {
        path: 'nmMaterial',
        name: 'nmMaterial',
        meta: { title: '纳米材料', isCount: true },

        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/nmMaterialView')
      },

      // 计算模拟
      {
        path: 'cptSimulation',
        name: 'cptSimulation',
        meta: { title: '计算模拟', isCount: true },

        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/cptSimulationView')
      },
      // 计算模拟的商品页面
      {
        path: 'cptGoodsView',
        name: 'cptGoodsView',
        // meta: { title: '计算模拟', isCount: true },

        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/cptGoodsView')
      },

      //学研课堂
      {
        path: 'classroom',
        name: 'classroom',
        component: () => import(/* webpackChunkName: "about" */ '../views/classroomView/index')
      },
      //学研课堂搜索
      {
        path: 'classroomSearch',
        name: 'classroomSearch',
        component: () => import(/* webpackChunkName: "about" */ '../views/classroomView/searchResult')
      },
      //学研课堂详情
      {
        path: 'classroomVideoDetail',
        name: 'classroomVideoDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/classroomView/videoDetail')
      },
      // 购买云卡
      {
        path: 'classroomPay',
        name: 'classroomPay',
        component: () => import(/* webpackChunkName: "about" */ '../views/classroomView/classroomPay')
      },
      // 数据解析
      {
        path: 'dataAnalysis',
        name: 'dataAnalysis',
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/dataAnalysis')
      },
      // 安全评估
      {
        path: 'risk',
        name: 'risk',
        component: () => import(/* webpackChunkName: "about" */ '../views/categoryView/riskView')
      },
      // 搜索结果页面
      {
        path: 'searchResult',
        name: 'searchResult',
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/searchResult')
      },
      // 更多套餐
      {
        path: 'morePackages',
        name: 'morePackages',
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/morePackages')
      },
      // 商品详情
      {
        path: 'details/:id',
        name: 'details',
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/detailsView')
      },
      // 套餐详情
      {
        path: 'groupDetail/:id',
        name: 'groupDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/groupDetail')
      },
      // 纳米材料详情
      {
        path: 'nmMaterialDetail/:id',
        name: 'nmMaterialDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/nmMaterialDetail')
      },
      // 拼团详情
      {
        path: 'packageDetail/:id',
        name: 'packageDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/packageDetail')
      },
      // 预约下单
      {
        path: 'placeOrderView/:id',
        name: 'placeOrderView',
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/placeOrderView'),

      },
      // 订单确认
      {
        path: 'orderConfirm/:id',
        name: "orderConfirm",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/orderConfirm'),

      },
      // 支付页面
      {
        path: "pay",
        name: "pay",

        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/payView'),
      },
      // 补差价支付
      
      {
        path: "spreadMoneyPay",
        name: "spreadMoneyPay",

        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/spreadMoneyPay'),
      },
         // 还款支付页面
         {
          path: "repay",
          name: "repay",
  
          component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/repayView'),
        },
      // 支付结果
      {
        path: "payResult",
        name: "payResult",
        component: () => import(/* webpackChunkName: "about" */ '../views/resultView/payResult')
      },
      // 实名认证结果
      {
        path: "authResult",
        name: "authResult",
        component: () => import(/* webpackChunkName: "about" */ '../views/resultView/authResult')
      },
      // 购物车
      {
        path: "shopCar",
        name: "shopCar",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/shopCar'),

      },
      // 草稿箱
      {
        path: "drafts",
        name: "drafts",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/drafts'),
      },
      {
        path: "/test",
        name: "test",
        component: Test
      },
      // 发布需求
      {
        path: "releaseDemand",
        name: "releaseDemand",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/releaseDemand')
      },

      // 预存页面
      {
        path: "preStorage",
        name: "preStorage",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/preStorage')

      },
      //预存静态页面
      {
        path: "preStorageStatic",
        name: "preStorageStatic",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/preStorageStatic')

      },
      // 提交审核成功页面
      {
        path: "submitInfo",
        name: "submitInfo",
        component: () => import(/* webpackChunkName: "about" */ '../views/resultView/submitInfo')

      },
      // 对公转账预存信息页面
      {
        path: "preStoExamine",
        name: "preStoExamine",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/preStoExamine')

      },
      // 测试订单详情页
      {
        path: "testOrderDetail",
        name: "testOrderDetail",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/testOrderDetail')
      },
      // 商城订单详情页
      {
        path: "mallOrderDetail/:id",
        name: "mallOrderDetail/:id",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/mallOrderDetail')
      },
      // 套餐订单详情页
      {
        path: "packageOrderDetail/:id",
        name: "packageOrderDetail/:id",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/packageOrderDetail')
      },
      //申请开票页面
      {
        path: "invoiceDetail",
        name: "invoiceDetail",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/invoiceDetail')
      },
      // 售后页面
      {
        path: "afterSalesView",
        name: "afterSalesView",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/afterSalesView'),
      },
      // 售后退款页面
      {
        path: "afterSalesDrawback",
        name: "afterSalesDrawback",
        component: () => import(/* webpackChunkName: "about" */ '../views/afterSalesView/drawback'),
      },
      // 售后复测页面
      {
        path: "afterSalesRetest",
        name: "afterSalesRetest",
        component: () => import(/* webpackChunkName: "about" */ '../views/afterSalesView/retest'),
      },
      // 查看评论页面
      {
        path: "viewComment",
        name: "viewComment",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/viewComment'),
      },
      // 关于我们
      {
        path: "aboutUs",
        name: "aboutUs",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/aboutUs'),
      },
      // 申请会员页面
      {
        path: "applyVip",
        name: "applyVip",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/applyVipView'),
      },
      // 了解会员
      {
        path: "understandMember",
        name: "understandMember",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/understandMember'),
      },
      // 会员中心
      {
        path: "memberCenter",
        name: "memberCenter",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/memberCenter'),
      },
      // 开通平台支付
      {
        path: "openPayment",
        name: "openPayment",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/openPayment'),
      },
      // 服务条款
      {
        path: "serviceClause",
        name: "serviceClause",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/serviceClause'),
      },
      // 文献求助
      {
        path: 'literatureHelp',
        name: 'literatureHelp',
        meta: { title: '文献求助' },
        component: () => import('../views/literatureHelpView/literatureHelp')
      },
      // 求助详情
      {
        path: 'helpDetail',
        name: 'helpDetail',
        component: () => import('../views/literatureHelpView/helpDetail')
      },
      // 求助记录
      {
        path: 'helpRecords',
        name: 'helpRecords',
        component: () => import('../views/literatureHelpView/helpRecords')
      },
      // 优惠券商品页
      {
        path: 'testCoupon',
        name: 'testCoupon',
        component: () => import('../views/activityView/testCoupon')
      },
      // 新手引导页面
      {
        path: 'guideView',
        name: 'guideView',
        component: () => import('../views/childrenView/guideView')
      },
      // IF查询
      {
        path: 'IFQueryView',
        name: 'IFQueryView',
        component: () => import('../views/childrenView/IFQueryView')
      },
      // 分区查询
      {
        path: 'partitionQueryView',
        name: 'partitionQueryView',
        component: () => import('../views/childrenView/partitionQueryView')
      },
      // 消息历史记录
      {

        path: 'messageHistory',
        name: 'messageHistory',
        component: () => import('../views/childrenView/messageHistory')
      },
      // 更换负责人
      {

        path: 'replaceHeadman',
        name: 'replaceHeadman',
        component: () => import('../views/myCenterView/teamView/replaceHeadman')
      },
      //论文致谢
      {

        path: 'paperThank',
        name: 'paperThank',
        component: () => import('../views/childrenView/paperThank')
      },
      // 下单有礼活动
      {
        path: "placeOrderActivity",
        name: "placeOrderActivity",
        component: () => import('@/views/activityView/placeOrderActivity')

      },
      // 个人中心
      {
        path: "myCenter",
        name: "myCenter",
        component: () => import(/* webpackChunkName: "about" */ '../views/childrenView/myCenter'),
        meta: { loginRequest: true },
        children: [
          // 我的首页
          {
            name: "centerHome",
            path: "centerHome",
            meta: { title: '个人中心首页', isCount: true },
            component: () => import("../views/myCenterView/centerHome"),

          },
          // 个人资料
          {
            name: "personalData",
            path: "personalData",
            meta: { title: '个人资料', isCount: true },


            component: () => import("../views/myCenterView/personalData"),
          },
          // 收件地址
          {
            name: "address",
            path: "address",


            component: () => import("../views/myCenterView/address")
          },
          // 绑定&安全
          {
            name: "security",
            path: "security",
            meta: { title: '绑定&安全', isCount: true },

            component: () => import("../views/myCenterView/security"),
          },
          // 我的钱包
          {
            name: "wallet",
            path: "wallet",
            meta: { title: '我的钱包', isCount: true },

            component: () => import("../views/myCenterView/wallet")
          },
          {
            name: "myFlashSale",
            path: "myFlashSale",
            component: () => import("../views/myCenterView/myFlashSale")
          },
          // 优惠券
          {
            name: "coupon",
            path: "coupon",
            meta: { title: '优惠券', isCount: true },

            component: () => import("../views/myCenterView/coupon")
          },
          // 积分
          {
            name: "integral",
            path: "integral",


            component: () => import("../views/myCenterView/integral")
          },
          // 红包
          {
            name: "redBag",
            path: "redBag",
            component: () => import("../views/myCenterView/redBag")
          },
          // 我的订单
          {
            name: "myOrders",
            path: "myOrders",
            meta: { title: '我的订单', isCount: true },

            component: () => import("../views/myCenterView/myOrders")
          },
          // 售后列表
          {
            name: "afterSaleList",
            path: "afterSaleList",
            meta: { title: '售后列表', isCount: true },

            component: () => import("../views/myCenterView/afterSaleList")
          },
          // 评论晒单
          {
            name: "comment",
            path: "comment",
            meta: { title: '评论晒单', isCount: true },

            component: () => import("../views/myCenterView/comment")
          },
          // 我的收藏
          {
            name: "myCollection",
            path: "myCollection",
            meta: { title: '我的收藏', isCount: true },

            component: () => import("../views/myCenterView/myCollection")
          },
          // 常购项目
          {
            name: "oftenBy",
            path: "oftenBy",

            component: () => import("../views/myCenterView/oftenBy")
          },
          // 申请开票
          {
            name: "applyInvoice",
            path: "applyInvoice",
            meta: { title: '申请开票', isCount: true },

            component: () => import("../views/myCenterView/applyInvoice")
          },
          // 发票信息管理
          {
            name: "invoiceInfoAdmin",
            path: "invoiceInfoAdmin",
            meta: { title: '发票信息管理', isCount: true },

            component: () => import("../views/myCenterView/invoiceInfoAdmin")
          },
          // 发票信息列表
          {
            name: "invoiceList",
            path: "invoiceList",
            meta: { title: '发票信息列表', isCount: true },

            component: () => import("../views/myCenterView/invoiceList")
          },
          // 已开发票列表
          {
            name: "invoicedList",
            path: "invoicedList",
            meta: { title: '发票信息列表', isCount: true },

            component: () => import("../views/myCenterView/invoicedList")
          },
          // 预存申请单列表
          {
            name: "preDepositApply",
            path: "preDepositApply",
            // meta: { title: '预存申请单', isCount: true },
            component: () => import("../views/myCenterView/preDepositApply")
          },
          // 创建团队
          {
            name: "addTeam",
            path: "addTeam",
            component: () => import("../views/myCenterView/teamView/addTeamView")
          },
          // 加入团队
          {
            name: "joinTeam",
            path: "joinTeam",
            component: () => import("../views/myCenterView/teamView/joinTeamView")
          },
          // 我的团队
          {
            name: "myTeam",
            path: "myTeam",
            component: () => import("../views/myCenterView/teamView/myTeam"),
            meta: { isCheck: true }

          },
          // 团队信息
          {
            name: "teamInfo",
            path: "teamInfo",
            component: () => import("../views/myCenterView/teamView/teamInfo"),
            meta: { isCheck: true }


          },
          // 团队成员管理

          {
            name: "teamMemberAdmin",
            path: "teamMemberAdmin",
            component: () => import("../views/myCenterView/teamView/teamMemberAdmin"),
            meta: { isCheck: true }


          },
          // 团队成员
          {
            name: "teamMemberInfo",
            path: "teamMemberInfo",
            component: () => import("../views/myCenterView/teamView/teamMemberInfo"),
            meta: { isCheck: true }


          },
          // 团队订单管理
          {
            name: "teamOrderAdmin",
            path: "teamOrderAdmin",
            component: () => import("../views/myCenterView/teamView/teamOrderAdmin"),
            meta: { isCheck: true }


          },
          // 成员看的团队订单
          {
            name: "teamOrderList",
            path: "teamOrderList",
            component: () => import("../views/myCenterView/teamView/teamOrderList"),
            meta: { isCheck: true }


          },
          // 团队开票
          {
            name: "teamInvoice",
            path: "teamInvoice",
            component: () => import("../views/myCenterView/teamView/teamInvoice"),
            meta: { isCheck: true }

          },
          // 团队预存还款
          {
            name: "teamRepayment",
            path: "teamRepayment",
            component: () => import("../views/myCenterView/teamView/teamRepayment"),
            meta: { isCheck: true }

          },
        ]

      },

    ]
  },
  {
    path: "/Login",
    name: "Login",
    component: Login
  },
  // 绑定手机号页面
  {
    path: '/bindPhone',
    name: 'bindPhone',
    component: () => import('../views/bindPhone')
  },
  {
    path: "/Register",
    name: "Register",
    component: Register
  }
]

// 滚动条置顶
const scroll = () => {
  let app = document.getElementById('app')
  if (app) {
    app.scrollTop = 0
  }
}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
})
// 路由前置守卫
router.beforeEach((to, form, next) => {
  // 判断是否需要登录
  // if (to.meta.loginRequest) {
  //   // 判断是否有用户信息
  //   let user = store.state.user.userInfo
  //   if (user && JSON.stringify(user) != "{}") {
  //     next()
  //     scroll()
  //   } else {
  //     // 无用户信息
  //     // 将要去的页面存储起来
  //     sessionStorage.setItem("path", to.path)
  //     // 放行至登录
  //     next("/Login")
  //     scroll()
  //   }
  // }
  const { categoryData } = store.state.home
  if (!categoryData.length) {
    store.dispatch('home/getCategory')
  }
  next()
  scroll()

})
router.afterEach((to) => {
  const { isCount, title } = to.meta
  if (isCount) {
    userCount(title)
  }
})
export default router
