<template>
  <div>
    <el-form
      :model="codeForm"
      :rules="codeRule"
      ref="codeForm"
      label-width="80px"
    >
      <el-form-item prop="mobile" :error="errorMessage" label="手机号">
        <!-- 手机号 -->
        <el-input
          v-model="codeForm.mobile"
          placeholder="请输入手机号"
          @input="changeValue"
        >
          <template slot="prepend"
            >+86
            <!--  <i class="el-icon-caret-bottom"></i> -->
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="code" style="margin-top: '16px'" label="验证码">
        <el-input
          v-model="codeForm.code"
          class="code_input"
          @input="changeValue"
        ></el-input>
        <el-button v-if="!timer" @click="sendCode"
          >发送验证码</el-button
        >
        <el-button disabled v-else>{{ `${timerSecond}s后重新发送` }}</el-button>
        <div class="send_text" v-if="showSendText">
          验证码已发送，请查收短信
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validatePhoneNumber } from "@/tool";
import { sendCode } from "@/api/sys";
import { mobileExits } from "@/api/user";
export default {
  props: {
    scene: {
      type: String,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      errorMessage: null,
      codeForm: { mobile: "", code: "" },
      codeRule: {
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: validatePhoneNumber, trigger: "blur" },
        ],
        code: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 6,
            message: "验证码为6位数字",
            trigger: "blur",
          },
        ],
      },
      showSendText: false,
      sliderSuccess: false,
      timer: null,
      timerSecond: 60,
    };
  },
  computed: {},
  methods: {
    // 输入框改变
    changeValue() {
      this.errorMessage = null;
      let value;
      if (this.codeForm.mobile && this.codeForm.code) {
        value = true;
      }

      if (value) {
        this.$emit("changeValue", value, this.codeForm);
      }
    },
    checkForm() {
      this.$refs.codeForm.validate((check) => {
        if (check) {
          // 校验通过
          return this.$emit("fromCheckSuccess", this.codeForm);
        }
      });
    },
    sendCode() {
      this.errorMessage = null;
      this.$refs.codeForm.validateField("mobile", (check) => {
        if (!check) {
          // type==0  为注册   type==1为重置密码 
          mobileExits(this.codeForm.mobile).then((data) => {
            if (this.type == 0) {
              if (!data) {
                this.codeForm.scene = this.scene;
                sendCode(this.codeForm).then(() => {
                  this.$message({
                    type: "success",
                    message: "验证码发送成功，请查收短信！",
                  });
                  this.timer = setInterval(() => {
                    this.timerSecond--;
                    if (this.timerSecond <= 0) {
                      this.timerSecond = 60;
                      window.clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                });
              } else {
                //手机号码存在
                this.errorMessage = "手机号码已注册";
              }
            } else {
              if (data) {
                this.codeForm.scene = this.scene;
                sendCode(this.codeForm).then(() => {
                  this.$message({
                    type: "success",
                    message: "验证码发送成功，请查收短信！",
                  });
                  this.timer = setInterval(() => {
                    this.timerSecond--;
                    if (this.timerSecond <= 0) {
                      this.timerSecond = 60;
                      window.clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                });
              } else {
                //手机号码存在
                this.errorMessage = "该手机号码未注册";
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.code_input {
  width: 160px;
  margin-right: 12px;
}
.slider {
  display: flex;
  .label {
    width: 80px;
  }
  .drag {
    flex: 1;
    margin-bottom: 18px;
  }
}

/deep/.el-form-item__content {
  text-align-last: left;
}
/deep/.el-input,
/deep/.el-input__inner {
  height: 58px;
}
/deep/.el-form-item {
  margin-bottom: 18px;
}
/deep/.el-input-group__prepend {
  height: 58px;
  border: 1px solid #dcdfe6;
}
/deep/.el-button {
  background-color: @main-color;
  color: #fff;
  height: 58px;
}
</style>