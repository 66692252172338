/**
 * cookie
 */
export const CookieEnum = {
    uid: 'uid',
    refreshToken: 'refreshToken',
    accessToken: 'accessToken'
}
/**
 * localStorage
 */
export const localEnum = {
    dict: 'sysDictList',
    fileStorage: 'fileStorage',
    uuid: 'uuid',
}