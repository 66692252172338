import request from '@/api/request'

// 获取测试商品详情
export function getTestGoodsDetail(id) {
    return request({
        url: `/test-instrument/${id}`,
        method: 'get',
        loading: true
    });
}
// 获取测试商品的动态需求表单

export function getGenerateForm(id) {
    return request({
        url: `/test-instrument/generateForm/${id}`,
        method: 'get',
    });
}
// 保存测试单草稿
export function saveTestDraft(data) {
    return request({
        url: `/trade/order-draft/orderTest`,
        method: 'post',
        data
    });
}
// 根据草稿id获取测试单信息
export function getTestDraft(id) {
    return request({
        url: `/trade/order-draft/${id}`,
        method: 'get',
    });
}
// 提交测试需求单
export function submitTestOrder(data) {
    return request({
        url: `/trade/order-test`,
        method: 'post',
        data
    });
}
// 重新提交测试需求单
export function reSubmitTestOrder(data) {
    return request({
        url: `/trade/order-test`,
        method: 'put',
        data
    });
}
// 获取分类下的商品列表
export function getGoodsByCategoryId(categoryId) {
    return request({
        url: `/test-instrument/category/${categoryId}`,
        method: "get",
        loading: true
    })
}
// 获取全部分类
export function getAllCategory(params) {
    return request({ url: "/home/category/all/node", params },)
}
// 获取扁平分类
export function getCategory() {
    return request({ url: "/home/category/all" },)
}
// 分页获取测试商品
export function getTestGoodsPage(params) {
    return request({ url: 'test-instrument/queryPage', method: "get", params })
}
// 搜索商品
export function searchKeyword(params) {
    return request({ url: '/home/search/goods', method: "get", params })
}
// 获取测试商品
export function getTestGoodsList(params) {
    return request({ url: 'test-instrument/queryList', method: "get", params, loading: true })
}
// 获取商品的评论
export function getGoodsComment(params) {
    return request({
        url: '/member/comment/goods',
        params
    })
}
// 获取计算模拟商品节点
export function getCptGoodsNode() {
    return request({
        url: '/goods/calculate/node',
    })
}
