import Cookies from 'js-cookie'
import {CookieEnum, localEnum} from "./enum";
import { v4 } from "uuid"

/**
 * window.localStorage 浏览器永久缓存
 */
export const localStorage = {
    // 设置永久缓存
    set(key, val) {
        window.localStorage.setItem(key, JSON.stringify(val));
    },
    // 获取永久缓存
    get(key) {
        const json = window.localStorage.getItem(key);
        return JSON.parse(json);
    },
    // 移除永久缓存
    remove(key) {
        window.localStorage.removeItem(key);
    },
    // 移除全部永久缓存
    clear() {
        window.localStorage.clear();
    },

    getUuid(){
        let uuid = localStorage.get(localEnum.uuid)
        if (!uuid) {
            uuid = v4()
            localStorage.set(localEnum.uuid, uuid)
        }
        return uuid;
    }
};

/**
 * window.sessionStorage 浏览器临时缓存
 */
export const sessionStorage = {
    // 设置临时缓存
    set(key, val) {
        window.sessionStorage.setItem(key, JSON.stringify(val));
    },
    // 获取临时缓存
    get(key) {
        const json = window.sessionStorage.getItem(key);
        return JSON.parse(json);
    },
    // 移除临时缓存
    remove(key) {
        window.sessionStorage.removeItem(key);
    },
    // 移除全部临时缓存
    clear() {
        window.sessionStorage.clear();
    }
};




export const cookieStorage = {
    set: (key, value, options = {}) => {
        Cookies.set(key, value, { ...options, domain: process.env.VUE_APP_DOMAIN || '' })
    },
    get: (key) => {
        return Cookies.get(key)
    },
    remove: (key, options = {}) => {
        Cookies.remove(key, { ...options, domain: process.env.VUE_APP_DOMAIN || '' })
    },
    // 移除token
    clear() {
        this.remove(CookieEnum.accessToken)
        this.remove(CookieEnum.refreshToken)
        this.remove(CookieEnum.uid)
    }
}

