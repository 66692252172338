<template>
  <div>
    {{ form }}
    <xl-form v-model="form" :option="option"> </xl-form>
  </div>
</template>

<script>
import XlForm from "@/components/xyhComps/XlForm.vue";
export default {
  components: { XlForm },
  data() {
    return {
      form: {},
      option: {
        show: false,
        column: [
          {
            label: "DOI",
            prop: "doi",
            required: true,
          },
          {
            label: "链接",
            prop: "url",
          },
          {
            label: "文献名称",
            prop: "documentName",
          },
          {
            label: "作者",
            prop: "author",
          },
          {
            label: "其它描述",
            prop: "otherDescription",
          },
          {
            label: "联系人",
            prop: "concatName",
          },
          {
            label: "联系手机号",
            prop: "concatMobile",
          },
          {
            label: "联系邮箱",
            prop: "concatEmail",
          },
          {
            label: "状态",
            prop: "status",
          },
          {
            label: "结果",
            prop: "result",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>