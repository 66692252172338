<!-- 公用信息弹窗组件 -->
<template>
  <el-dialog :visible.sync="dialogVisible" @close="closeDialog" width="680px">
    <div class="header" slot="title">
      <div class="img">
        <img src="@/assets/imgs/homeIcon/jixiangwu.png" alt="" />
      </div>
      <span>{{ options.title }}</span>
    </div>
    <div class="content_box">
      <div class="text" v-if="options.content" v-html="options.content"></div>
      <slot name="content" v-else></slot>
    </div>
    <div class="btn_box" v-if="!options.footerSlot">
      <el-button class="cancel_btn" @click="closeDialog"> 取消</el-button>
      <el-button class="submit_btn" @click="toView" v-if="options.path">{{
        options.btnText
      }}</el-button>
    </div>
    <slot name="footer" v-else> </slot>
  </el-dialog>
</template>
          
    <script>
export default {
  props: ["visible", "options"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {},

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    toView() {
      this.$router.push(this.options.path);
      this.closeDialog();
    },
  },
  watch: {
    visible(newValue) {
      this.dialogVisible = newValue;
    },
    immediate: true,
  },
};
</script>
          
<style lang="less" scoped>
.header {
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  background-color: @main-color;
  border-radius: 4px 4px 0 0;
  line-height: 60px;
  height: 60px;
  position: relative;
  .img {
    position: absolute;
    top: -93px;
    left: 0;
    img {
      vertical-align: middle;
      width: 157px;
      height: 153px;
    }
  }
}

.content_box {
  width: 100%;
  padding: 0 40px;
  font-size: 20px;
  color: #545454;
  margin-bottom: 30px;
  text-align: center;
  .text {
    p {
      line-height: 23px;
    }
  }
}
.btn_box {
  display: flex;
  align-items: center;
  justify-content: center;
  .submit_btn,
  .cancel_btn {
    font-size: 20px;
  }
  .cancel_btn {
    color: @main-color;
    border: 1px solid @main-color;
    margin-right: 30px;
    background-color: #fff;
    width: 144px;
    height: 48px;
  }
}
/deep/.dialog_footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content_box::-webkit-scrollbar {
  width: 10px;
}
.content_box::-webkit-scrollbar-thumb {
  border-radius: 43px;
  background-color: #d1d1d1;
}
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    padding: 0;
  }
  .el-icon-close {
    font-size: 22px;
    color: #fff;
  }
}
</style>