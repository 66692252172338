import Vue from 'vue'
import Vuex from 'vuex'
import user from "./models/user"
import upload from './models/upload'
import home from './models/home'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  getters: {
    userInfo() {
      return user.state.userInfo
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user, upload,home
  }
})
