export const elementsTab = [
    { sort: 1, name: 'H', chName: '氢', color: '#4BC42F' },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    { sort: 2, name: 'He', chName: '氦', color: '#00CCFF' },
    { sort: 3, name: 'Li', chName: '锂', color: '#F76950' },
    { sort: 4, name: 'Be', chName: '钹', color: 'orange' },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    { sort: 5, name: 'B', chName: '硼', color: '#5DCA98' },
    { sort: 6, name: 'C', chName: '碳', color: '#4BC42F' },
    { sort: 7, name: 'N', chName: '氮', color: '#4BC42F' },
    { sort: 8, name: 'O', chName: '氧', color: '#4BC42F' },
    { sort: 9, name: 'F', chName: '氟', color: '#00CCFF' },
    { sort: 10, name: 'Ne', chName: '氖', color: '#00CCFF' },
    { sort: 11, name: 'Na', chName: '钠', color: '#F76950' },
    { sort: 12, name: 'Mg', chName: '镁', color: 'orange' },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    { sort: 13, name: 'AI', chName: '铝', color: '#A9C43C' },
    { sort: 14, name: 'Si', chName: '硅', color: '#5DCA98' },
    { sort: 15, name: 'P', chName: '磷', color: '#4BC42F' },
    { sort: 16, name: 'S', chName: '硫', color: '#4BC42F' },
    { sort: 17, name: 'CI', chName: '氯', color: '#00CCFF' },
    { sort: 18, name: 'Ar', chName: '氩', color: '#00CCFF' },
    { sort: 19, name: 'K', chName: '钾', color: '#F76950' },
    { sort: 20, name: 'Ca', chName: '钙', color: 'orange' },
    { sort: 21, name: 'Sc', chName: '钪', color: '#FDDE19' },
    { sort: 22, name: 'Ti', chName: '钛', color: '#FDDE19' },
    { sort: 23, name: 'V', chName: '钒', color: '#FDDE19' },
    { sort: 24, name: 'Cr', chName: '铬', color: '#FDDE19' },
    { sort: 25, name: 'Mn', chName: '锰', color: '#FDDE19' },
    { sort: 26, name: 'Fe', chName: '铁', color: '#FDDE19' },
    { sort: 27, name: 'Co', chName: '钴', color: '#FDDE19' },
    { sort: 28, name: 'ni', chName: '镍', color: '#FDDE19' },
    { sort: 29, name: 'Cu', chName: '铜', color: '#FDDE19' },
    { sort: 30, name: 'Zn', chName: '锌', color: '#FDDE19' },
    { sort: 31, name: 'Ga', chName: '镓', color: '#A9C43C' },
    { sort: 32, name: 'Ge', chName: '锗', color: '#5DCA98' },
    { sort: 33, name: 'As', chName: '砷', color: '#5DCA98' },
    { sort: 34, name: 'Se', chName: '硒', color: '#4BC42F' },
    { sort: 35, name: 'Br', chName: '溴', color: '#00CCFF' },
    { sort: 36, name: 'Kr', chName: '氪', color: '#00CCFF' },
    { sort: 37, name: 'Rb', chName: '铷', color: '#F76950' },
    { sort: 38, name: 'Sr', chName: '锶', color: 'orange' },
    { sort: 39, name: 'Y', chName: '钇', color: '#FDDE19' },
    { sort: 40, name: 'Zr', chName: '锆', color: '#FDDE19' },
    { sort: 41, name: 'Nb', chName: '铌', color: '#FDDE19' },
    { sort: 42, name: 'Mo', chName: '钼', color: '#FDDE19' },
    { sort: 43, name: 'Tc', chName: '锝', color: '#FDDE19' },
    { sort: 44, name: 'Ru', chName: '钌', color: '#FDDE19' },
    { sort: 45, name: 'Rh', chName: '铑', color: '#FDDE19' },
    { sort: 46, name: 'Pd', chName: '钯', color: '#FDDE19' },
    { sort: 47, name: 'Ag', chName: '银', color: '#FDDE19' },
    { sort: 48, name: 'Cd', chName: '镉', color: '#FDDE19' },
    { sort: 49, name: 'In', chName: '铟', color: '#A9C43C' },
    { sort: 50, name: 'Sn', chName: '锡', color: '#A9C43C' },
    { sort: 51, name: 'Sb', chName: '锑', color: '#5DCA98' },
    { sort: 52, name: 'Te', chName: '碲', color: '#5DCA98' },
    { sort: 53, name: 'I', chName: '碘', color: '#00CCFF' },
    { sort: 54, name: 'Xe', chName: '氙', color: '#00CCFF' },
    { sort: 55, name: 'Cs', chName: '铯', color: '#F76950' },
    { sort: 56, name: 'Ba', chName: '钡', color: 'orange' },
    { sort: '57-71', name: 'La-Lu',  color: '#FF00FF' },
 
    { sort: 72, name: 'Hf', chName: '铪', color: '#FDDE19' },
    { sort: 73, name: 'Ta', chName: '钽', color: '#FDDE19' },
    { sort: 74, name: 'W', chName: '钨', color: '#FDDE19' },
    { sort: 75, name: 'Re', chName: '铼', color: '#FDDE19' },
    { sort: 76, name: 'Os', chName: '锇', color: '#FDDE19' },
    { sort: 77, name: 'Ir', chName: '铱', color: '#FDDE19' },
    { sort: 78, name: 'Pt', chName: '铂', color: '#FDDE19' },
    { sort: 79, name: 'Au', chName: '金', color: '#FDDE19' },
    { sort: 80, name: 'Hg', chName: '汞', color: '#FDDE19' },
    { sort: 81, name: 'TI', chName: '铊', color: '#A9C43C' },
    { sort: 82, name: 'Pb', chName: '铅', color: '#A9C43C' },
    { sort: 83, name: 'Bi', chName: '铋', color: '#A9C43C' },
    { sort: 84, name: 'Po', chName: '钋', color: '#5DCA98' },
    { sort: 85, name: 'At', chName: '砹', color: '#00CCFF' },
    { sort: 86, name: 'Rn', chName: '氡', color: '#00CCFF' },
    { sort: 87, name: 'Fr', chName: '钫', color: '#F76950' },
    { sort: 88, name: 'Ra', chName: '镭', color: 'orange' },
    { sort: '89-103', name: 'Ac-Lr', color: '#CC33CC' },
 
 
    { sort: 104, name: 'Rf', color: '#FDDE19' },
    { sort: 105, name: 'Db', color: '#FDDE19' },
    { sort: 106, name: 'Sg', color: '#FDDE19' },
    { sort: 107, name: 'Bh', color: '#FDDE19' },
    { sort: 108, name: 'Hs', color: '#FDDE19' },
    { sort: 109, name: 'Mt', color: '#FDDE19' },
    { sort: 110, name: 'Ds', color: '#FDDE19' },
    { sort: 111, name: 'Rg', color: '#FDDE19' },
    { sort: 112, name: 'Cn', color: '#FDDE19' },
    { sort: 113, name: 'Uut', color: '#A9C43C' },
    { sort: 114, name: 'FI', color: '#A9C43C' },
    { sort: 115, name: 'Uup', color: '#A9C43C' },
    { sort: 116, name: 'Lv', color: '#A9C43C' },
    { sort: 117, name: 'Uus', color: '#00CCFF' },
    { sort: 118, name: 'Uuo', color: '#00CCFF' },
]
 
 
export const La_Lu = [
    { sort: 57, name: 'La', chName: '镧', color: '#FF00FF' },
    { sort: 58, name: 'Ce', chName: '铈', color: '#FF00FF' },
    { sort: 59, name: 'Pr', chName: '镨', color: '#FF00FF' },
    { sort: 60, name: 'Nd', chName: '钕', color: '#FF00FF' },
    { sort: 61, name: 'Pm', chName: '钷', color: '#FF00FF' },
    { sort: 62, name: 'Sm', chName: '钐', color: '#FF00FF' },
    { sort: 63, name: 'Eu', chName: '铕', color: '#FF00FF' },
    { sort: 64, name: 'Gd', chName: '钆', color: '#FF00FF' },
    { sort: 65, name: 'Tb', chName: '铽', color: '#FF00FF' },
    { sort: 66, name: 'Dy', chName: '镝', color: '#FF00FF' },
    { sort: 67, name: 'Ho', chName: '钬', color: '#FF00FF' },
    { sort: 68, name: 'Er', chName: '铒', color: '#FF00FF' },
    { sort: 69, name: 'Tm', chName: '铥', color: '#FF00FF' },
    { sort: 70, name: 'Yb', chName: '镱', color: '#FF00FF' },
    { sort: 71, name: 'Lu', chName: '镥', color: '#FF00FF' },
]
 
 
export const Ac_Lr = [
    { sort: 89, name: 'Ac', chName: '锕', color: '#CC33CC' },
    { sort: 90, name: 'Th', chName: '钍', color: '#CC33CC' },
    { sort: 91, name: 'Pa', chName: '镤', color: '#CC33CC' },
    { sort: 92, name: 'U', chName: '铀', color: '#CC33CC' },
    { sort: 93, name: 'Np', chName: '镎', color: '#CC33CC' },
    { sort: 94, name: 'Pu', chName: '钚', color: '#CC33CC' },
    { sort: 95, name: 'Am', chName: '镅', color: '#CC33CC' },
    { sort: 96, name: 'Cm', chName: '锔', color: '#CC33CC' },
    { sort: 97, name: 'Bk', chName: '锫', color: '#CC33CC' },
    { sort: 98, name: 'Cf', chName: '锎', color: '#CC33CC' },
    { sort: 99, name: 'Es', chName: '锿', color: '#CC33CC' },
    { sort: 100, name: 'Fm', chName: '镄', color: '#CC33CC' },
    { sort: 101, name: 'Md', chName: '钔', color: '#CC33CC' },
    { sort: 102, name: 'No', chName: '锘', color: '#CC33CC' },
    { sort: 103, name: 'Lr', chName: '铹', color: '#CC33CC' },
]