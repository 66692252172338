<!-- 登录页 -->
<template>
  <div class="login">
    <div class="logo_box">
      <div class="logo" @click="$router.push('/')">
        <img :src="`${baseUrl}/static/defaults/logo.png`" alt="" />
      </div>
      <span class="vertical">|</span>
      <span>欢迎登录</span>
    </div>
    <!-- banner图 -->
    <div class="banner">
      <div class="img">
        <img :src="`${baseUrl}/static/defaults/logo_banner_blue.png`" />
      </div>
      <!-- 账号和验证码登录表单 -->
      <div
        class="form_box form"
        v-if="activeName == 'code' || activeName == 'account'"
      >
        <el-tabs v-model="activeName">
          <el-tab-pane label="验证码登录" name="code">
            <!-- 验证码登录 -->
            <el-form :model="codeForm" :rules="codeRule" ref="codeForm">
              <el-form-item prop="mobile">
                <!-- 手机号 -->
                <el-input v-model="codeForm.mobile" placeholder="请输入手机号">
                  <template slot="prepend"
                    >+86
                    <!--  <i class="el-icon-caret-bottom"></i> -->
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="code" style="margin-top: '16px'">
                <el-input v-model="codeForm.code" class="code_input"></el-input>
                <el-button class="h44" v-if="!timer" @click="sendCode"
                  >发送验证码</el-button
                >
                <el-button class="h44" disabled v-else>{{
                  `${timerSecond}s后重新发送`
                }}</el-button>
                <div class="send_text" v-if="showSendText">
                  验证码已发送，请查收短信
                </div>
              </el-form-item>
            </el-form>
            <!-- 用户协议 -->
            <div class="agreement">
              <el-checkbox
                v-model="isAgree"
                @change="checkboxChange"
              ></el-checkbox>
              <span @click="isAgree = !isAgree"
                >&nbsp;我已阅读并接受以下条款 :<span
                  class="clause"
                  @click.stop="showAgreement"
                  >《学研汇服务条款》</span
                ></span
              >
              <div class="error_tips" v-if="showErrorTip">
                接受服务协议才能登录
              </div>
            </div>
            <xl-button
              class="login_btn h44"
              :auto-loading="true"
              :class="codeIsAllowLogin ? 'btn_disabled' : ''"
              @click="formSubmit('code', $event)"
              >登录</xl-button
            >
            <div class="code_safe">
              <span></span>
              <span class="blue" @click="toPage('/Register?type=0')"
                >免费注册新账号</span
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="密码登录" name="account">
            <!-- 账号登录 -->
            <el-form
              :model="accountForm"
              :rules="accountRule"
              ref="accountForm"
            >
              <el-form-item prop="username">
                <el-input
                  v-model="accountForm.username"
                  placeholder="请输入手机号"
                >
                  <template slot="prepend">
                    <i class="iconfont icon-ziyuanxhdpi"></i>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  v-model="accountForm.password"
                  show-password
                  autocomplete="off"
                  placeholder="请输入密码"
                >
                  <template slot="prepend">
                    <i class="iconfont icon-ziyuanxhdpi1"></i>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
            <!-- 用户协议 -->
            <div class="agreement">
              <el-checkbox
                v-model="isAgree"
                @change="checkboxChange"
              ></el-checkbox>
              <span @click="isAgree = !isAgree"
                >&nbsp;我已阅读并接受以下条款 :<span
                  class="clause"
                  @click.stop="showAgreement"
                  >《学研汇服务条款》</span
                ></span
              >
              <div class="error_tips" v-if="showErrorTip">
                接受服务协议才能登录
              </div>
            </div>
            <xl-button
              class="login_btn h44"
              :class="accountIsAllowLogin ? 'btn_disabled' : ''"
              @click="formSubmit('account', $event)"
              :auto-loading="true"
              >登录</xl-button
            >
            <div class="account_safe">
              <span class="black" @click="toPage('/Register?type=1')"
                >忘记密码?</span
              >
              <span class="blue" @click="toPage('/Register?type=0')"
                >免费注册新账号</span
              >
            </div>
          </el-tab-pane>
        </el-tabs>

        <!-- 第三方账号 -->
        <div class="threeAccount">
          <div class="title_text">第三方账号登录</div>
          <div class="icon_box">
            <div class="line"></div>
            <!-- QQ登录 -->
            <!-- <div class="icon"><img src="../assets/imgs/QQ.png" alt="" /></div> -->
            <!-- 微信登录 -->
            <div class="icon" @click="activeName = 'wxLogin'">
              <img src="../assets/imgs/wechat.png" alt="" />
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <!-- 第三方登录表单 -->
      <wx-login
        v-else-if="activeName == 'wxLogin'"
        @changeType="changeType"
      ></wx-login>
    </div>
  </div>
</template>

<script>
import { validatePhoneNumber } from "@/tool";
// import {  } from "@/tool/storage"
import { mapActions } from "vuex";
import { sendCode } from "@/api/sys";
import WxLogin from "@/components/wxLogin.vue";
import XlButton from "@/components/xyhComps/XlButton.vue";
// import { mobileExits } from "@/api/user";
export default {
  name: "Login",
  components: { WxLogin, XlButton },

  data() {
    return {
      timer: null,
      //登录类型
      showErrorTip: false,
      codeForm: { mobile: "", code: "" },
      isAgree: false,
      timerSecond: 60,
      showSendText: false,
      isRegister: false,
      accountForm: {
        username: "",
        password: "",
      },
      activeName: "code",
      codeRule: {
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: validatePhoneNumber, trigger: "blur" },
        ],
        code: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 6,
            message: "验证码为6位数字",
            trigger: "change",
          },
        ],
      },
      accountRule: {
        username: [
          { required: true, message: "账号不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    codeIsAllowLogin() {
      return !(this.codeForm.mobile && this.codeForm.code);
    },
    accountIsAllowLogin() {
      return !(this.accountForm.username && this.accountForm.password);
    },
  },

  created() {
    const { type } = this.$route.query;
    if (type) {
      this.activeName = type;
    }
  },
  methods: {
    ...mapActions("user", ["login"]),
    changeType(value) {
      this.activeName = value;
    },
    // 协议复选框改变
    checkboxChange(value) {
      this.showErrorTip = !value;
    },
    // 跳转其他页面
    toPage(path) {
      this.$router.push(path);
    },
    // 显示协议
    showAgreement() {
      this.$router.push("/serviceClause?id=10");
    },
    // 校验是否注册
    // checkRegister() {
    //   this.$refs.codeForm.validateField("mobile", (check) => {
    //     if (!check) {
    //       // 发送请求
    //       mobileExits(this.codeForm.mobile).then((res) => {
    //         if (!res) {
    //           // 手机号没注册
    //           this.$message({
    //             type: "error",
    //             message: "该手机号未注册，请先注册！",
    //           });
    //         }
    //       });
    //     }
    //   });
    // },
    // 发送验证码
    sendCode() {
      this.$refs.codeForm.validateField("mobile", async (check) => {
        if (!check) {
          sendCode({
            mobile: this.codeForm.mobile,
            scene: "LOGIN",
          }).then((res) => {
            console.log(res);
            this.$message({ type: "success", message: "验证码发送成功！" });
          });
          this.timer = setInterval(() => {
            this.timerSecond--;
            if (this.timerSecond <= 0) {
              this.timerSecond = 60;
              window.clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      });
    },
    // 登录表单提交
    async formSubmit(type, done) {
      if (!this.isAgree) {
        done();
        return (this.showErrorTip = true);
      }

      this.$refs[`${type}Form`].validate(async (check) => {
        if (check) {
          switch (type) {
            case "account":
              this.login({
                ...this.accountForm,
                grant_type: "password",
              })
                .then(() => {
                  // 查看session中有无记录
                  let path = sessionStorage.getItem("path") || "/";
                  this.$router.push(path);
                  this.$message({
                    type: "success",
                    message: "登录成功！",
                  });
                  done();
                  this.$EventBus.$emit("getUserInfo");
                  setTimeout(() => {
                    sessionStorage.removeItem("path");
                  }, 1000);
                })
                .catch(() => {
                  done();
                });
              break;
            case "code":
              // 校验通过
              this.login({
                ...this.codeForm,
                grant_type: "sms_code",
              })
                .then(() => {
                  // 查看session中有无记录
                  let path = sessionStorage.getItem("path") || "/";
                  this.$router.push(path);
                  this.$message({
                    type: "success",
                    message: "登录成功！",
                  });
                  done();
                  this.$EventBus.$emit("getUserInfo");
                })
                .catch(() => {
                  done();
                });
              break;
          }
        } else {
          done();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: hidden;
  .send_text {
    font-size: 14px;
    color: red;
    height: 21px;
  }
  .iconfont {
    font-size: 30px;
    color: #cacaca;
  }
  .logo_box {
    display: flex;
    margin-bottom: 10px;
    .logo {
      cursor: pointer;
      img {
        max-width: 200px;
      }
    }
    margin-left: 200px;
    padding-top: 30px;
    font-size: 36px;
    color: #171717;
    font-weight: 700;

    .vertical {
      margin: 0 20px;
    }
  }
  .banner {
    width: 100%;
    position: relative;
    .img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .form_box {
      width: 450px;
      min-height: 500px;
      position: absolute;
      right: 200px;
      top: 90px;
      background-color: #fff;
      padding: 0 32px;
      border-radius: 4px;
      .code_input {
        width: 160px;
        margin-right: 12px;
      }
      .drag {
        margin-bottom: 31px;
      }
      .login_btn {
        width: 100%;
        font-size: 16px;
        height: 58px;
      }
      .account_safe,
      .code_safe {
        width: 100%;
        font-size: 12px;
        // color: #acacac;
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        span {
          cursor: pointer;
        }
        .black {
          color: #171717;
        }
        .blue {
          color: @main-color;
        }
      }
      .account_safe {
        margin: 16px 0 36px 0;
      }
      .threeAccount {
        font-size: 14px;
        text-align: left;
        color: #696969;
        padding: 0 25px;
        bottom: 100px;
        text-align: center;
        width: 100%;
        .title_text {
          margin-bottom: 16px;
        }
        .icon_box {
          width: 100%;
          display: flex;
          margin-bottom: 16px;
          justify-content: center;
          align-items: center;

          .line {
            width: 115px;
            height: 2px;
            background-color: #d9d9d9;
          }
          .line:nth-child(1) {
            margin-right: 24px;
          }
          .line:nth-child(4) {
            margin-left: 24px;
          }
          .icon {
            cursor: pointer;
            margin-right: 20px;
            width: 42px;
            height: 42px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .icon:nth-child(3) {
            margin-right: 0;
          }
        }
      }
      .agreement {
        width: 100%;
        height: 18px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #acacac;
        cursor: pointer;
        text-align: center;
        margin-top: 24px;
        margin-bottom: 27px;
        position: relative;
        .error_tips {
          position: absolute;
          left: 1px;
          bottom: -18px;
          cursor: default;
        }
        .clause {
          color: #696969;
        }
      }
      /deep/.el-button {
        color: #fff;
        background-color: @main-color;
        height: 58px;
      }
      /deep/.el-button.is-disabled {
        background-color: #eeeeee;
        color: #696969;
      }
      /deep/.el-tab-pane {
        margin-top: 20px;
      }
      /deep/.el-form-item__content {
        text-align-last: left;
      }
      /deep/.el-input,
      /deep/.el-input__inner {
        height: 58px;
      }
      /deep/.el-form-item {
        margin-bottom: 31px;
      }
      /deep/.el-form-item:last-child {
        margin-bottom: 8px;
      }
      /deep/.el-tabs__nav-scroll {
        height: 60px;
        margin-top: 20px;
      }
      /deep/.el-tabs__item {
        height: 60px;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
</style>